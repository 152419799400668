import appsignal from '../../../appsignal';
import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  Input,
  VStack,
  Link,
  Circle,
  Text,
  Checkbox,
  Divider,
  Collapse,
  useColorModeValue,
  Heading,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { CustomFilterIcon } from '../../../theme/icons/CustomFilterIcon';
import { useForm } from 'react-hook-form';
import { useState, useContext, useEffect, useRef } from 'react';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
import PassesListContext from '../../../store/client/PassesListContext';
import {
  CustomReactSelect,
  formatOptionLabelFilterPassTemplate,
  formatOptionLabelSelectPassType,
  formatOptionLabelSelectPassStatus,
  formatOptionLabelSelectDeviceType,
  formatOptionLabelFilterGroupTag,
} from '../../common/CustomReactSelect';
import CustomToast from '../../../common/CustomToast';
import { getErrorResponsePayload } from '../../../utils/ajax';

function PassesListFilter() {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const passListCtx = useContext(PassesListContext);
  const [passTemplatesList, setPassTemplatesList] = useState([]);
  const [groupTagsList, setGroupTagsList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenId, onToggle: onToggleId } = useDisclosure();
  const { isOpen: isOpenExtId, onToggle: onToggleExtId } = useDisclosure();
  const { isOpen: isOpenPassTemplate, onToggle: onTogglePassTemplate } =
    useDisclosure();
  const { isOpen: isOpenGroupTag, onToggle: onToggleGroupTag } =
    useDisclosure();
  const { isOpen: isOpenPassType, onToggle: onTogglePassType } =
    useDisclosure();
  const { isOpen: isOpenDeviceType, onToggle: onToggleDeviceType } =
    useDisclosure();
  const { isOpen: isOpenStatus, onToggle: onToggleStatus } = useDisclosure();
  const [selectedUuid, setSelectedUuid] = useState('');
  const [selectedExternalId, setSelectedExternalId] = useState('');
  const [selectedPassTemplate, setSelectedPassTemplate] = useState('');
  const [selectedGroupTag, setSelectedGroupTag] = useState('');
  const [selectedPassType, setSelectedPassType] = useState('');
  const [selectedDeviceType, setSelectedDeviceType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeLabelColor = useColorModeValue('secondaryDark', 'white');
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  // Pass type options for Apple

  const passTypes = [
    {
      label: 'Generic pass',
      value: 'generic',
    },
    {
      label: 'Loyalty pass',
      value: 'storeCard',
    },
    {
      label: 'Event ticket',
      value: 'eventTicket',
    },
    {
      label: 'Loyalty stamp card',
      value: 'stampCard',
    },
    // {
    //   label: 'Coupon',
    //   value: 'coupon',
    // },
    // {
    //   label: 'Boarding Pass',
    //   value: 'boarding_pass',
    // },
  ];

  const deviceTypes = [
    {
      label: 'Apple',
      value: 'iphone',
    },
    {
      label: 'Android',
      value: 'android',
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        let response;
        if (filterEntity) {
          response = await authAxios.get(
            `entities/${filterEntity.uuid}/passes/templates?skipImages=true`
          );
        } else {
          response = await authAxios.get(`passes/templates?skipImages=true`);
        }
        const data = response?.data?.data;
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        let templates = [];
        if (data.length === 0) {
          return;
        }
        data.forEach((item) => {
          templates.push({
            label: item.name,
            value: item,
          });
        });
        setPassTemplatesList(templates);
        // if no filter is set always return to All pass templates default
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };

    getData();

    return () => {
      setPassTemplatesList([]);
    };
  }, [authAxios, filterEntity]);

  // fetch all group tags for select group tags dropdown
  useEffect(() => {
    const getGroupTags = async () => {
      try {
        let response;
        if (filterEntity) {
          response = await authAxios.get(
            `api/v1/entities/${filterEntity.uuid}/passes?fields=groupTag`
          );
        } else {
          response = await authAxios.get(`api/v1/passes?fields=groupTag`);
        }
        const data = response.data?.data;
        const groupTags = data.map((item) => {
          if (item.attributes.groupTag) {
            return {
              label: item.attributes.groupTag,
            };
          } else {
            return undefined;
          }
        });
        const filteredGroupTags = groupTags.filter(
          (item) => item !== undefined
        );
        const uniqueGroupTags = Array.from(
          new Set(filteredGroupTags.map((a) => a.label))
        ).map((label) => {
          return filteredGroupTags.find((a) => a.label === label);
        });
        setGroupTagsList(uniqueGroupTags);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
      }
    };
    getGroupTags();
  }, [authAxios, filterEntity]);

  const filterEntityRef = useRef(filterEntity);

  useEffect(() => {
    if (filterEntity !== filterEntityRef.current) {
      filterEntityRef.current = filterEntity;
      onResetAll();
    }
  }, [filterEntity]);

  const { register, handleSubmit, reset, setValue } = useForm();

  const onSubmit = async (values) => {
    // on submit alter values object and add status from useState
    values.id = selectedUuid ? selectedUuid : '';
    values.extId = selectedExternalId ? selectedExternalId : '';
    values.passTemplate = selectedPassTemplate
      ? selectedPassTemplate.label
      : '';
    values.groupTag = selectedGroupTag ? selectedGroupTag.label : '';
    values.passType = selectedPassType ? selectedPassType.value : '';
    values.deviceType = selectedDeviceType ? selectedDeviceType.value : '';
    values.status = selectedStatus ? selectedStatus.status : '';
    passListCtx.updateFilterValues(values);

    let countableFilterValues = [];
    Object.entries(values).forEach(([key, value]) => {
      if (!key.startsWith('by') && value !== false) {
        countableFilterValues = countableFilterValues.concat(value);
      }
    });
    passListCtx.updateTotalActiveFilters(
      countableFilterValues.filter((val) => val.length !== 0).length
    );
    passListCtx.updateNewFiltersSet(true);
    onClose();
  };

  useEffect(() => {
    setValue('id', selectedUuid.id);
    setValue('extId', selectedExternalId.extId);
    setValue('passTemplate', selectedPassTemplate.passTemplate);
    setValue('groupTag', selectedGroupTag.groupTag);
    setValue('passType', selectedPassType.passType);
    setValue('deviceType', selectedDeviceType.deviceType);
    setValue('status', selectedStatus.status);
  }, [
    selectedUuid,
    selectedExternalId,
    selectedPassTemplate,
    selectedGroupTag,
    selectedPassType,
    selectedStatus,
    selectedDeviceType,
    setValue,
  ]);

  const onResetAll = async () => {
    reset();
    passListCtx.updateFilterValues({
      id: '',
      extId: '',
      passTemplate: '',
      groupTag: '',
      passType: '',
      deviceType: '',
      status: '',
    });
    passListCtx.updateTotalActiveFilters(0);
    onClose();
    isOpenId && onToggleId();
    isOpenExtId && onToggleExtId();
    isOpenPassTemplate && onTogglePassTemplate();
    isOpenGroupTag && onToggleGroupTag();
    isOpenPassType && onTogglePassType();
    isOpenDeviceType && onToggleDeviceType();
    isOpenStatus && onToggleStatus();
    setSelectedUuid('');
    setSelectedExternalId('');
    setSelectedPassTemplate('');
    setSelectedGroupTag('');
    setSelectedPassType('');
    setSelectedDeviceType('');
    setSelectedStatus('');
    sessionStorage.setItem('navigatedFromPassPage', 'false');
  };

  const handleCheckboxChange = (toggleFunction, callbackFunction) => {
    toggleFunction();
    callbackFunction();
  };

  const statusList = [
    {
      status: 'expired',
      label: 'Expired',
    },
    {
      status: 'issued',
      label: 'Issued',
    },
    {
      status: 'active',
      label: 'Active',
    },
    // {
    //   status: 'pending',
    //   label: 'Pending',
    // },
    {
      status: 'removed',
      label: 'Removed',
    },
    {
      status: 'deactivated',
      label: 'Deactivated',
    },
  ];

  useEffect(() => {
    const navigatedFromPassPage =
      sessionStorage.getItem('navigatedFromPassPage') === 'true';
    if (!navigatedFromPassPage) return;
    const storedFilters = sessionStorage.getItem('filterValues');
    if (!storedFilters) return;
    const filters = JSON.parse(storedFilters);
    const filterConfig = {
      id: {
        toggleFunction: onToggleId,
        setValueKey: 'id',
        setFlagKey: 'byId',
        setSelectedFunction: setSelectedUuid,
      },
      extId: {
        toggleFunction: onToggleExtId,
        setValueKey: 'extId',
        setFlagKey: 'byExternalId',
        setSelectedFunction: setSelectedExternalId,
      },
      passTemplate: {
        toggleFunction: onTogglePassTemplate,
        setValueKey: 'passTemplate',
        setFlagKey: 'byPassTemplate',
        setSelectedFunction: setSelectedPassTemplate,
        adaptValue: (value) => ({ name: value }),
      },
      groupTag: {
        toggleFunction: onToggleGroupTag,
        setValueKey: 'groupTag',
        setFlagKey: 'byGroupTag',
        setSelectedFunction: setSelectedGroupTag,
        adaptValue: (value) => ({ label: value }),
      },
      passType: {
        toggleFunction: onTogglePassType,
        setValueKey: 'passType',
        setFlagKey: 'byPassType',
        setSelectedFunction: setSelectedPassType,
      },
      deviceType: {
        toggleFunction: onToggleDeviceType,
        setValueKey: 'deviceType',
        setFlagKey: 'byDeviceType',
        setSelectedFunction: setSelectedDeviceType,
      },
      status: {
        toggleFunction: onToggleStatus,
        setValueKey: 'status',
        setFlagKey: 'byStatus',
        setSelectedFunction: setSelectedStatus,
        adaptValue: (value) => ({ status: value }),
      },
    };
    Object.entries(filters).forEach(([key, value]) => {
      const config = filterConfig[key];
      if (config && value) {
        handleCheckboxChange(config.toggleFunction, () => {
          setValue(config.setValueKey, value || '');
          setValue(config.setFlagKey, true);
          config.setSelectedFunction(
            config.adaptValue ? config.adaptValue(value) : value
          );
        });
      }
    });
  }, [setValue]);

  return (
    <Box mt={{ sm: '10px' }}>
      <Flex gap={{ base: '10px', sm: '0px' }} wrap="wrap">
        {passListCtx.totalActiveFilters > 0 && (
          <Button
            variant="white02"
            size="sm"
            px="20px"
            mr="10px"
            onClick={onResetAll}
          >
            <Text
              textStyle="headingFamilyMedium"
              textTransform="none"
              fontWeight="500"
              lineHeight="28px"
            >
              Clear filters
            </Text>
          </Button>
        )}
        <Button size="sm" px="30px" onClick={onOpen}>
          <Box as="span" zIndex={1}>
            {passListCtx.totalActiveFilters ? (
              <Box as="span" display="inline-block">
                <Circle
                  bg="white"
                  size="18px"
                  mr="10px"
                  color="brand"
                  fontSize="12px"
                >
                  {passListCtx.totalActiveFilters}
                </Circle>
              </Box>
            ) : (
              <CustomFilterIcon boxSize="19px" mr="10px" />
            )}
            Filter
          </Box>
        </Button>
      </Flex>

      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent bg={modeBoxBg}>
            <DrawerCloseButton />
            <DrawerHeader
              borderBottomWidth="1px"
              borderBottomColor={modeBorderColor}
              pt={{ base: '16px', lg: '56px' }}
              bg={modeTextBg}
            >
              <Heading>Filter Passes</Heading>
            </DrawerHeader>
            <DrawerBody p="0" bg={modeLabelBg}>
              <VStack spacing={0}>
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byId"
                    {...register('byId')}
                    onChange={() =>
                      handleCheckboxChange(onToggleId, () => {
                        reset({ id: '' });
                        setSelectedUuid('');
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By pass UUID</Text>
                  </Checkbox>
                  <Collapse in={isOpenId} animateOpacity>
                    <Input
                      mt={2}
                      id="id"
                      type="text"
                      placeholder="Enter pass UUID"
                      value={selectedUuid}
                      onChange={(e) => setSelectedUuid(e.target.value)}
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>

                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byExternalId"
                    {...register('byExternalId')}
                    onChange={() =>
                      handleCheckboxChange(onToggleExtId, () => {
                        reset({ extId: '' });
                        setSelectedExternalId('');
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By external ID</Text>
                  </Checkbox>
                  <Collapse in={isOpenExtId} animateOpacity>
                    <Input
                      mt={2}
                      id="extId"
                      type="text"
                      placeholder="Enter external ID"
                      value={selectedExternalId}
                      onChange={(e) => setSelectedExternalId(e.target.value)}
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>

                {/* by pass template */}
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byPassTemplate"
                    {...register('byPassTemplate')}
                    onChange={() =>
                      handleCheckboxChange(onTogglePassTemplate, () => {
                        reset({ passTemplate: '' });
                        setSelectedPassTemplate('');
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By pass template</Text>
                  </Checkbox>
                  <Collapse in={isOpenPassTemplate} animateOpacity>
                    <CustomReactSelect
                      options={passTemplatesList}
                      placeholder="Select pass template"
                      formatOptionLabel={formatOptionLabelFilterPassTemplate}
                      onChange={(passTemplate) =>
                        setSelectedPassTemplate({
                          label: passTemplate.label,
                          value: passTemplate.value,
                        })
                      }
                      value={selectedPassTemplate}
                      id="passTemplateFilter"
                      passTemplate
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>

                {/* by pass type */}
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byPassType"
                    {...register('byPassType')}
                    onChange={() =>
                      handleCheckboxChange(onTogglePassType, () => {
                        reset({ passType: '' });
                        setSelectedPassType('');
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By pass type</Text>
                  </Checkbox>
                  <Collapse in={isOpenPassType} animateOpacity>
                    <CustomReactSelect
                      options={passTypes}
                      formatOptionLabel={formatOptionLabelSelectPassType}
                      placeholder="Select pass type"
                      onChange={(passType) => setSelectedPassType(passType)}
                      value={selectedPassType}
                      id="passTypeFilter"
                      passType
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>
                {/* by device type */}
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byPassType"
                    {...register('byDeviceType')}
                    onChange={() =>
                      handleCheckboxChange(onToggleDeviceType, () => {
                        reset({ deviceType: '' });
                        setSelectedDeviceType('');
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By device type</Text>
                  </Checkbox>
                  <Collapse in={isOpenDeviceType} animateOpacity>
                    <CustomReactSelect
                      options={deviceTypes}
                      formatOptionLabel={formatOptionLabelSelectDeviceType}
                      placeholder="Select device type"
                      onChange={(deviceType) =>
                        setSelectedDeviceType(deviceType)
                      }
                      value={selectedDeviceType}
                      id="deviceTypeFilter"
                      deviceType
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>

                {/* by status */}
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byStatus"
                    {...register('byStatus')}
                    onChange={() =>
                      handleCheckboxChange(onToggleStatus, () => {
                        reset({ status: '' });
                        setSelectedStatus('');
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By status</Text>
                  </Checkbox>
                  <Collapse in={isOpenStatus} animateOpacity>
                    <CustomReactSelect
                      options={statusList}
                      placeholder="Select status"
                      formatOptionLabel={formatOptionLabelSelectPassStatus}
                      onChange={(status) => setSelectedStatus(status)}
                      value={selectedStatus}
                      id="passStatus"
                      passStatus
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>

                {/* by group tag */}
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byGroupTag"
                    {...register('byGroupTag')}
                    onChange={() =>
                      handleCheckboxChange(onToggleGroupTag, () => {
                        reset({ groupTag: '' });
                        setSelectedGroupTag('');
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By group tag</Text>
                  </Checkbox>
                  <Collapse in={isOpenGroupTag} animateOpacity>
                    <CustomReactSelect
                      options={groupTagsList}
                      placeholder="Select group tag"
                      formatOptionLabel={formatOptionLabelFilterGroupTag}
                      onChange={(groupTag) => setSelectedGroupTag(groupTag)}
                      value={selectedGroupTag}
                      id="groupTagFilter"
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>
              </VStack>
            </DrawerBody>
            <DrawerFooter
              borderTopWidth="1px"
              borderTopColor={modeBorderColor}
              bg={modeTextBg}
            >
              <Link
                color={useColorModeValue('secondaryDark08', '#DDDDDD')}
                fontSize="14px"
                onClick={onResetAll}
                mr="30px"
              >
                <Text textStyle="headingFamilyMedium">Reset all</Text>
              </Link>
              <Button
                type="submit"
                alt="Apply filters"
                size="sm"
                px="30px"
                alignSelf="flex-start"
              >
                Apply filters
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </Box>
  );
}

export default PassesListFilter;
