import appsignal from '../../appsignal';
import {
  Container,
  Heading,
  Divider,
  Flex,
  Box,
  useColorModeValue,
  Spacer,
  Button,
  SimpleGrid,
  FormControl,
  Hide,
  FormLabel,
  Show,
  Collapse,
  useMediaQuery,
  useToast,
  FormErrorMessage,
  Input,
  Text,
  Stack,
  Link,
  Checkbox,
  Alert,
  AlertIcon,
  VStack,
  HStack,
  FormHelperText,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import {
  REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_SYMBOL,
  REGEX_IS_VALID_READER_ID,
} from '../../utils/validations';

import { CustomReactSelect } from '../../components/common/CustomReactSelect';
import Breadcrumbs from '../../common/Breadcrumbs';
import { useContext, useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getErrorResponsePayload, getErrors } from '../../utils/ajax';
import CustomToast from '../../common/CustomToast';
import ReadersContext from '../../store/client/ReadersContext';
import RedirectionContext from '../../context/RedirectionContext';
import { HttpContext } from '../../context/HttpContext';
import { MeContext } from '../../context/MeContext';
import { CustomCreatableSelect } from '../../components/common/CustomCreatableSelect';

const ReadersCreatePageForm = ({ isEdit }) => {
  const { authAxios } = useContext(HttpContext);
  const redirectCtx = useContext(RedirectionContext);
  const readersCtx = useContext(ReadersContext);
  const toast = useToast();
  const navigate = useNavigate();
  // initial disabled state is not used but it is necessary to be declared as initial state
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState();
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeNoteText = useColorModeValue('secondaryDark06', '#DDDDDD');
  const modeLabelColor = useColorModeValue('secondaryDark', 'white');
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const { vtapAutomaticSetupEnabled } = meCtx.state;
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [wifiSsids, setWifiSsids] = useState([]);
  const [wifiPasswords, setWifiPasswords] = useState([]);

  useEffect(() => {
    const entity =
      meCtx.state.entities.length === 1
        ? meCtx.state.entities[0]
        : filterEntity;
    setSelectedEntity(entity);
  }, [filterEntity, meCtx.state.entities]);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Pass readers',
      linkUrl: '/readers',
    },
    {
      linkName: isEdit ? readersCtx.readerState.name : 'Add reader',
      linkUrl: null,
    },
  ];

  const preloadedValues = {
    name: readersCtx.readerState.name,
    uuid: readersCtx.readerState.uuid,
    readerType: readersCtx.readerState.type,
    qrEnabled: readersCtx.readerState.qrEnabled,
    mifareClassicNfcEnabled: readersCtx.readerState.mifareClassicNfcEnabled,
    ndefTagScanningEnabled: readersCtx.readerState.ndefTagScanningEnabled,
    scanEnabled: readersCtx.readerState.scanEnabled,
    redeemEnabled: readersCtx.readerState.redeemEnabled,
    addPointsEnabled: readersCtx.readerState.addPointsEnabled,
    vtapAutomaticSetup: isEdit
      ? readersCtx.readerState.vtapAutomaticSetup
      : null,
    vtapAccountType: readersCtx.readerState.vtapApiKey
      ? 'external'
      : 'passentry',
    vtapLocation: readersCtx.readerState.vtapLocation,
    vtapSSID: readersCtx.readerState.vtapSSID,
    vtapPassword: readersCtx.readerState.vtapPassword,
    vtapApiKey: readersCtx.readerState.vtapApiKey,
  };

  const readerTypes = [
    {
      label: 'PassEntry Reader',
      value: 'PassentryReader',
    },
    {
      label: 'VTAP Reader',
      value: 'VtapReader',
    },
  ];

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required('Please enter reader name'),
      uuid: Yup.string()
        .required('Please enter reader ID')
        .matches(
          REGEX_IS_VALID_READER_ID,
          'Reader ID can only contain letters, numbers, underscores and dashes.'
        ),
      readerType: Yup.string().required('Please select reader type'),

      // VTAP Reader fields
      vtapLocation: Yup.string().when(['readerType', 'vtapAutomaticSetup'], {
        is: (readerType, vtapAutomaticSetup) =>
          readerType === 'VtapReader' && vtapAutomaticSetup,
        then: (schema) => schema.optional().nullable(),
        otherwise: (schema) => schema.optional().nullable(),
      }),
      vtapSSID: Yup.string().when(['readerType', 'vtapAutomaticSetup'], {
        is: (readerType, vtapAutomaticSetup) =>
          readerType === 'VtapReader' && vtapAutomaticSetup,
        then: (schema) => schema.required('Please enter WiFi SSID'),
        otherwise: (schema) => schema.optional().nullable(),
      }),
      vtapPassword: Yup.string().when(['readerType', 'vtapAutomaticSetup'], {
        is: (readerType, vtapAutomaticSetup) =>
          readerType === 'VtapReader' && vtapAutomaticSetup,
        then: (schema) => schema.required('Please enter WiFi password'),
        otherwise: (schema) => schema.optional().nullable(),
      }),
      vtapAutomaticSetup: Yup.boolean().when('readerType', {
        is: 'VtapReader',
        then: (schema) =>
          schema.required(
            'Please select if you want to use the VTAP automatic setup'
          ),
        otherwise: (schema) => schema.optional().nullable(),
      }),
      vtapAccountType: Yup.string().when(['readerType', 'vtapAutomaticSetup'], {
        is: (readerType, vtapAutomaticSetup) =>
          readerType === 'VtapReader' && vtapAutomaticSetup,
        then: (schema) =>
          schema.required(
            'Please select if you are using your own VTAP Cloud account or if you want to use the PassEntry VTAP Cloud account'
          ),
        otherwise: (schema) => schema.optional().nullable(),
      }),
      vtapApiKey: Yup.string().when(
        ['readerType', 'vtapAutomaticSetup', 'vtapAccountType'],
        {
          is: (readerType, vtapAutomaticSetup, vtapAccountType) =>
            readerType === 'VtapReader' &&
            vtapAutomaticSetup &&
            vtapAccountType === 'external',
          then: (schema) =>
            schema.required(
              'VTAP API Key is required if you are using your own VTAP Cloud account'
            ),
          otherwise: (schema) => schema.nullable().optional(),
        }
      ),
      password: Yup.string().when(['readerType', 'changePassword'], {
        is: (readerType, changePassword) =>
          readerType === 'PassentryReader' && (isEdit ? changePassword : true),
        then: (schema) =>
          schema
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(30, 'Password cannot be more than 30 characters')
            .matches(
              REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
              'Password must contain both upper and lower case letters'
            )
            .matches(
              REGEX_HAS_AT_LEAST_ONE_NUMBER,
              'Password must contain at least one number'
            )
            .matches(REGEX_HAS_SYMBOL, 'Password must contain a symbol'),
        otherwise: (schema) => schema.optional().nullable(),
      }),
      scanEnabled: Yup.boolean().when('readerType', {
        is: 'PassentryReader',
        then: (schema) =>
          schema.test(
            'atLeastOne',
            'At least one of the following must be enabled: scanning, redeeming points, or adding points.',
            function (value) {
              const { redeemEnabled, addPointsEnabled } = this.parent;
              return value || redeemEnabled || addPointsEnabled;
            }
          ),
        otherwise: (schema) => schema.optional().nullable(),
      }),
    });
  }, [isEdit]);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: preloadedValues,
  };

  const {
    register,
    setValue,
    watch,
    reset,
    resetField,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm(formOptions);

  const readerType = watch('readerType');
  const changePassword = watch('changePassword');

  useEffect(() => {
    const getData = async () => {
      try {
        const url = isEdit
          ? `api/v1/wifi-credentials?reader_id=${preloadedValues.uuid}`
          : filterEntity
            ? `api/v1/wifi-credentials?entity_id=${filterEntity.uuid}`
            : null;
        if (url) {
          const response = await authAxios.get(url);
          const data = response.data;
          const uniqueSsids = [...new Set(data.map((item) => item.ssid))];
          const uniquePasswords = [
            ...new Set(data.map((item) => item.password)),
          ];

          const wifiSsidOptions = uniqueSsids.map((ssid) => ({
            label: ssid,
          }));

          const wifiPasswordOptions = uniquePasswords.map((password) => ({
            label: password,
          }));

          setWifiSsids(wifiSsidOptions);
          setWifiPasswords(wifiPasswordOptions);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [filterEntity, preloadedValues.uuid]);

  useEffect(() => {
    if (!vtapAutomaticSetupEnabled && readerType === 'VtapReader') {
      setValue('vtapAutomaticSetup', false);
    }
  }, [vtapAutomaticSetupEnabled, readerType, setValue]);

  const onSubmit = async (data) => {
    async function handleEdit() {
      try {
        const formData = data;
        formData.vtapAccountType === 'external'
          ? (formData.vtapApiKey = formData.vtapApiKey.trim())
          : (formData.vtapApiKey = null);
        const changes = {};

        const addIfChanged = (formKey, apiKey) => {
          if (formData[formKey] !== preloadedValues[formKey]) {
            changes[apiKey] = formData[formKey];
          }
        };

        addIfChanged('name', 'name');

        if (readerType === 'PassentryReader') {
          if (formData.password && formData.password.trim() !== '') {
            addIfChanged('password', 'password');
          }
          addIfChanged('mifareClassicNfcEnabled', 'mifare_classic_nfc_enabled');
          addIfChanged('ndefTagScanningEnabled', 'ndef_tag_scanning_enabled');
          addIfChanged('qrEnabled', 'qr_enabled');
          addIfChanged('scanEnabled', 'scan_enabled');
          addIfChanged('redeemEnabled', 'redeem_enabled');
          addIfChanged('addPointsEnabled', 'add_points_enabled');
        }

        if (readerType === 'VtapReader') {
          addIfChanged('vtapAutomaticSetup', 'vtap_automatic_setup');
          addIfChanged('vtapAccountType', 'vtap_cloud_account_type');
          addIfChanged('vtapApiKey', 'vtap_api_key');
          addIfChanged('vtapLocation', 'location');
          addIfChanged('vtapSSID', 'wifi_ssid');
          addIfChanged('vtapPassword', 'wifi_password');
        }

        if (Object.keys(changes).length === 0) {
          toast({
            render: ({ onClose }) => (
              <CustomToast
                status="info"
                title="No changes detected"
                description="No changes were made to the reader."
                onClose={onClose}
              />
            ),
          });
          navigate('/readers', { replace: true });
          return;
        }

        const response = await authAxios.patch(
          `api/v1/readers/${preloadedValues.uuid}`,
          { reader: changes }
        );

        readersCtx.updateAllData(response?.data?.data);

        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="success"
              title="Reader has been edited"
              description=""
              onClose={onClose}
            />
          ),
        });
        navigate('/readers', { replace: true });
      } catch (error) {
        appsignal.sendError(error);
        const errors = getErrors(error);
        errors?.forEach((errorMessage) => {
          toast({
            render: ({ onClose }) => (
              <CustomToast
                status="error"
                title={errorMessage || 'Something went wrong'}
                description={errorMessage ? '' : 'Please try again later.'}
                onClose={onClose}
              />
            ),
          });
        });
      }
    }
    try {
      if (isEdit) {
        await handleEdit();
        return;
      }

      const {
        name,
        uuid,
        password,
        mifareClassicNfcEnabled,
        ndefTagScanningEnabled,
        qrEnabled,
        scanEnabled,
        redeemEnabled,
        addPointsEnabled,
        vtapAutomaticSetup,
        vtapAccountType,
        vtapApiKey,
        vtapLocation,
        vtapSSID,
        vtapPassword,
      } = data;

      const endpoint = filterEntity
        ? `api/v1/entities/${filterEntity.uuid}/readers`
        : 'api/v1/readers';

      const payload = {
        reader: {
          name,
          uuid,
          type: readerType,
        },
      };

      if (readerType === 'PassentryReader') {
        payload.reader.password = password;
        payload.reader.mifare_classic_nfc_enabled = mifareClassicNfcEnabled;
        payload.reader.ndef_tag_scanning_enabled = ndefTagScanningEnabled;
        payload.reader.qr_enabled = qrEnabled;
        payload.reader.scan_enabled = scanEnabled;
        payload.reader.redeem_enabled = redeemEnabled;
        payload.reader.add_points_enabled = addPointsEnabled;
      }

      if (readerType === 'VtapReader') {
        payload.reader.vtap_automatic_setup = vtapAutomaticSetup;
        payload.reader.vtap_cloud_account_type = vtapAccountType;
        payload.reader.vtap_api_key = vtapApiKey;
        payload.reader.location = vtapLocation;
        payload.reader.wifi_ssid = vtapSSID;
        payload.reader.wifi_password = vtapPassword;
      }

      payload.reader = Object.fromEntries(
        Object.entries(payload.reader).filter(
          ([_, value]) => value != null && value !== ''
        )
      );

      const response = await authAxios.post(endpoint, payload);

      readersCtx.updateAllData(response.data.data);

      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Reader has been added"
            description=""
            onClose={onClose}
          />
        ),
      });
      navigate('/readers', { replace: true });
    } catch (error) {
      appsignal.sendError(error);
      const errors = getErrors(error);

      errors?.forEach((errorMessage) => {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title={errorMessage || 'Something went wrong'}
              description={errorMessage ? '' : 'Please try again later.'}
              onClose={onClose}
            />
          ),
        });
      });
    }
  };

  const redeemEnabled = watch('redeemEnabled');
  const addPointsEnabled = watch('addPointsEnabled');

  useEffect(() => {
    trigger('scanEnabled');
  }, [redeemEnabled, addPointsEnabled, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container display="flex" flexDir="column" flex="1" pb={6}>
        <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          flexWrap="wrap"
          mt="15px"
          py="9px"
          minH="54px"
        >
          <Heading alignSelf="flex-start" mr={2} wordBreak="break-word">
            {isEdit ? readersCtx.readerState.name : 'Add reader'}{' '}
            {isEdit && (
              <Text
                as="span"
                display="inline-block"
                textStyle="bodyFamilyMedium"
                fontSize="14px"
                color={modeHelperText}
                lineHeight="130%"
              >
                / uuid: {readersCtx.readerState.uuid}
              </Text>
            )}
          </Heading>
        </Flex>

        <Divider borderColor="primaryBackground" mb={4} />

        <input
          type="hidden"
          {...register('changePassword')}
          value={changePassword}
        />

        {isEdit &&
          readerType === 'PassentryReader' &&
          readersCtx.readerState.loggedIn && (
            <Alert status="warning" borderRadius="15px" fontSize="14px">
              <AlertIcon color="brandDark" />
              Please note: Any changes made to the settings on this page will
              result in the automatic logout of this reader.
            </Alert>
          )}

        {!isEdit && readerType === 'PassentryReader' && (
          <Alert
            status="info"
            borderRadius="15px"
            fontSize="14px"
            backgroundColor="white"
          >
            <AlertIcon color="primaryDark06" />
            <Text>
              If you need more information on how to use the PassEntry Reader
              App, please{' '}
              <Link
                textStyle="bodyBold"
                fontSize="14px"
                href="mailto:devteam@passentry.com"
                color="secondaryDark"
              >
                contact our team
              </Link>{' '}
              to discuss your use case and receive detailed setup instructions.
            </Text>
          </Alert>
        )}

        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          flexWrap="wrap"
          minH="54px"
        >
          <Spacer />
          <SimpleGrid w="full" columns={{ base: 1 }} mt={4}>
            <Box className="autofillForDarkBg" w="full">
              <Box
                className="primaryBoxShadow"
                bg={modeFormBoxBg}
                borderRadius="15px"
                overflow="hidden"
              >
                {/* READER NAME */}
                <FormControl isInvalid={errors.name} bg={modeFormControlBg}>
                  <Flex alignItems="center">
                    <Hide below="md">
                      <Box pl="24px" flexShrink={0} w="240px">
                        <FormLabel m={0}>Reader name</FormLabel>
                      </Box>
                    </Hide>
                    <Box p="19px" w="full" bg={modeLabelBg}>
                      <Show below="md">
                        <FormLabel>Reader name</FormLabel>
                      </Show>
                      <Input
                        variant="filledForDarkBg"
                        id="name"
                        type="text"
                        placeholder="Name your reader to help identify it"
                        {...register('name')}
                        autoComplete="off"
                        onBlur={() => {
                          const value = watch('name');
                          if (value.trim() === '') {
                            setValue('name', '');
                          }
                        }}
                      />
                      <FormErrorMessage mt="4px">
                        {errors.name?.message || errors.name}
                      </FormErrorMessage>
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
                {/*  Reader Type */}
                <FormControl
                  isInvalid={errors.readerType}
                  bg={modeFormControlBg}
                >
                  <Flex alignItems="center">
                    <Hide below="md">
                      <Box flexShrink={0} w="240px" pl="24px">
                        <FormLabel m={0} pb="4px">
                          Reader type
                        </FormLabel>
                      </Box>
                    </Hide>
                    <Box p="19px" w="full" bg={modeLabelBg}>
                      <Show below="md">
                        <Stack direction="column">
                          <FormLabel m={0} pb="4px">
                            Reader type
                          </FormLabel>
                        </Stack>
                      </Show>
                      {readersCtx.readerState.setupConfirmed ? (
                        <Text ml="15px">{readersCtx.readerState.uuid}</Text>
                      ) : (
                        <CustomReactSelect
                          isDisabled={isEdit}
                          options={readerTypes}
                          menuPortalTarget={document.body}
                          value={readerTypes.find(
                            (type) => type.value === readerType
                          )}
                          variant="filledForDarkBg"
                          id="readerType"
                          type="text"
                          onChange={(e) => {
                            setValue('readerType', e.value);
                            trigger('readerType');
                          }}
                          autoComplete="off"
                        />
                      )}
                      <FormErrorMessage mt="4px">
                        {errors.readerType?.message || errors.readerType}
                      </FormErrorMessage>
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
                {/* READER ID */}
                <FormControl isInvalid={errors.uuid} bg={modeFormControlBg}>
                  <Flex alignItems="center">
                    <Hide below="md">
                      <Box flexShrink={0} w="240px" pl="24px">
                        <FormLabel m={0}>Reader ID</FormLabel>
                        <FormHelperText mt={2} w="90%">
                          {readerType === 'VtapReader'
                            ? 'Reader serial number, can be found on the back of the reader (e.g. CC123456).'
                            : readerType === 'PassentryReader'
                              ? 'Unique identifier of your Reader that will be used for login.'
                              : ''}
                        </FormHelperText>
                      </Box>
                    </Hide>
                    <Box
                      p="19px"
                      pt={{ base: '19px', md: '30px' }}
                      pb={{ base: '19px', md: '30px' }}
                      w="full"
                      bg={modeLabelBg}
                    >
                      <Show below="md">
                        <Stack direction="column">
                          <FormLabel m={0}>Reader ID</FormLabel>
                          <FormHelperText mt={2} w="90%">
                            {readerType === 'VtapReader'
                              ? 'Reader serial number, can be found on the back of the reader (e.g. CC123456).'
                              : readerType === 'PassentryReader'
                                ? 'Unique identifier of your Reader that will be used for login.'
                                : ''}
                          </FormHelperText>
                        </Stack>
                      </Show>
                      {readersCtx.readerState.setupConfirmed ? (
                        <Text ml="15px">{readersCtx.readerState.uuid}</Text>
                      ) : (
                        <>
                          <Input
                            variant="filledForDarkBg"
                            id="uuid"
                            type="text"
                            placeholder="Type reader ID here..."
                            {...register('uuid')}
                            autoComplete="off"
                            isDisabled={isEdit}
                            onBlur={() => {
                              const value = watch('uuid');
                              if (value.trim() === '') {
                                setValue('uuid', '');
                              }
                            }}
                          />

                          <FormErrorMessage mt="4px">
                            {errors.uuid?.message || errors.uuid}
                          </FormErrorMessage>
                        </>
                      )}
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
                {/*  VTAP attributes */}
                <Collapse
                  in={readerType === 'VtapReader' && vtapAutomaticSetupEnabled}
                  animateOpacity
                >
                  <FormControl bg={modeFormControlBg}>
                    <Flex alignItems="center">
                      <Hide below="md">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>Reader setup</FormLabel>
                        </Box>
                      </Hide>
                      <Box p="19px" w="full" bg={modeLabelBg}>
                        <Show below="md">
                          <FormLabel>Reader setup</FormLabel>
                        </Show>

                        <VStack align="stretch" spacing={4} py="10px">
                          <FormControl isInvalid={errors.vtapAutomaticSetup}>
                            <FormLabel mb={3}>
                              Do you want to automatically set up the reader?
                            </FormLabel>
                            <RadioGroup
                              onChange={(value) => {
                                const isAutomatic = value === 'true';
                                setValue('vtapAutomaticSetup', isAutomatic);
                                resetField('vtapAccountType');
                                resetField('vtapApiKey');
                                setValue('vtapSSID', '');
                                setValue('vtapPassword', '');
                                trigger('vtapAutomaticSetup');
                              }}
                              value={
                                watch('vtapAutomaticSetup') === true
                                  ? 'true'
                                  : watch('vtapAutomaticSetup') === false
                                    ? 'false'
                                    : undefined
                              }
                            >
                              <Stack direction="column" spacing={2}>
                                <Radio value="true">Yes</Radio>
                                <Radio value="false">No</Radio>
                              </Stack>
                            </RadioGroup>
                            <FormErrorMessage mt={2}>
                              {errors.vtapAutomaticSetup?.message}
                            </FormErrorMessage>
                          </FormControl>

                          <Collapse
                            in={watch('vtapAutomaticSetup')}
                            animateOpacity
                          >
                            <FormControl isInvalid={errors.vtapAccountType}>
                              <FormLabel mb={3}>
                                Do you have your own VTAP Cloud Account?
                              </FormLabel>
                              <RadioGroup
                                onChange={(value) => {
                                  setValue('vtapAccountType', value);
                                  resetField('vtapApiKey');
                                  trigger('vtapAccountType');
                                }}
                                value={watch('vtapAccountType')}
                              >
                                <Stack direction="column" spacing={2}>
                                  <Radio value="external">
                                    Yes, I have my own VTAP Cloud account
                                  </Radio>
                                  <Radio value="passentry">
                                    No, Readers are configured through
                                    PassEntry's VTAP Cloud account
                                  </Radio>
                                </Stack>
                              </RadioGroup>
                              <FormErrorMessage mt={2}>
                                {errors.vtapAccountType?.message}
                              </FormErrorMessage>
                            </FormControl>

                            <Collapse
                              in={watch('vtapAccountType') === 'external'}
                              animateOpacity
                            >
                              <FormControl mt={4} isInvalid={errors.vtapApiKey}>
                                <Input
                                  variant="filledForDarkBg"
                                  id="vtapApiKey"
                                  type="text"
                                  placeholder="Enter your VTAP API Key"
                                  {...register('vtapApiKey')}
                                />
                                <FormHelperText mt={2}>
                                  Please contact VTAP to obtain your API key
                                </FormHelperText>
                                <FormErrorMessage mt={1}>
                                  {errors.vtapApiKey?.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Collapse>
                          </Collapse>
                        </VStack>
                      </Box>
                    </Flex>
                  </FormControl>
                  <Divider borderColor={modeBorderColor} />

                  <Collapse in={watch('vtapAutomaticSetup')}>
                    <FormControl
                      isInvalid={errors.vtapLocation}
                      bg={modeFormControlBg}
                    >
                      <Flex alignItems="center">
                        <Hide below="md">
                          <Box pl="24px" flexShrink={0} w="240px">
                            <FormLabel m={0}>Location</FormLabel>
                          </Box>
                        </Hide>
                        <Box p="19px" w="full" bg={modeLabelBg}>
                          <Show below="md">
                            <FormLabel>Location</FormLabel>
                          </Show>
                          <Input
                            variant="filledForDarkBg"
                            id="vtapLocation"
                            type="text"
                            placeholder={`Type location here...`}
                            {...register('vtapLocation')}
                          />
                          <FormErrorMessage mt="4px">
                            {errors.vtapLocation?.message ||
                              errors.vtapLocation}
                          </FormErrorMessage>
                        </Box>
                      </Flex>
                    </FormControl>
                    <Divider borderColor={modeBorderColor} />
                    <Flex alignItems="center" bg={modeFormControlBg}>
                      <Hide below="md">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>WiFi Credentials</FormLabel>
                        </Box>
                      </Hide>
                      <Box p="19px" w="full" bg={modeLabelBg}>
                        <Flex
                          align="center"
                          justify="space-between"
                          gap={6}
                          direction={{ base: 'column', xl: 'row' }}
                        >
                          <FormControl isInvalid={errors.vtapSSID}>
                            <Show below="md">
                              <FormLabel>WiFi Credentials</FormLabel>
                            </Show>
                            <CustomCreatableSelect
                              options={wifiSsids}
                              padding="5px 16px"
                              placeholder="Enter or select WiFi SSID"
                              noOptionsMessage={() => 'No WiFi SSIDs found'}
                              menuPlacement="auto"
                              menuPortalTarget={document.body}
                              id="vtapSSID"
                              onChange={(ssid) => {
                                if (ssid) {
                                  setValue('vtapSSID', ssid.label.trim());
                                } else {
                                  setValue('vtapSSID', '');
                                }
                                trigger('vtapSSID');
                              }}
                              value={
                                watch('vtapSSID')
                                  ? { label: watch('vtapSSID') }
                                  : null
                              }
                            />
                            <FormErrorMessage mt="4px">
                              {errors.vtapSSID?.message || errors.vtapSSID}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl isInvalid={errors.vtapPassword}>
                            <CustomCreatableSelect
                              options={wifiPasswords}
                              padding="5px 16px"
                              placeholder="Enter or select WiFi Password"
                              noOptionsMessage={() => 'No WiFi Passwords found'}
                              menuPlacement="auto"
                              menuPortalTarget={document.body}
                              id="vtapPassword"
                              onChange={(password) => {
                                if (password) {
                                  setValue(
                                    'vtapPassword',
                                    password.label.trim()
                                  );
                                  trigger('vtapPassword');
                                } else {
                                  setValue('vtapPassword', '');
                                }
                              }}
                              value={
                                watch('vtapPassword')
                                  ? { label: watch('vtapPassword') }
                                  : null
                              }
                            />
                            <FormErrorMessage mt="4px">
                              {errors.vtapPassword?.message ||
                                errors.vtapPassword}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                      </Box>
                    </Flex>
                  </Collapse>
                  <Divider borderColor={modeBorderColor} />
                </Collapse>
                {/* Scanning Settings */}
                <Collapse in={readerType === 'PassentryReader'} animateOpacity>
                  <Flex alignItems="center" bg={modeFormControlBg}>
                    <Hide below="md">
                      <Box flexShrink={0} w="240px" pl="24px">
                        <FormLabel m={0} pb="4px">
                          Scanning settings
                        </FormLabel>
                      </Box>
                    </Hide>
                    <Box
                      pl="19px"
                      pr="19px"
                      pt={{ base: '19px', '2xl': '30px' }}
                      pb={{ base: '19px', '2xl': '30px' }}
                      w="full"
                      bg={modeLabelBg}
                    >
                      <FormControl px="24px" isInvalid={!!errors.scanEnabled}>
                        <VStack align="stretch" spacing={4}>
                          <Checkbox
                            id="scanEnabled"
                            {...register('scanEnabled')}
                          >
                            <Text color={modeLabelColor}>Enable scanning</Text>
                          </Checkbox>
                          <Checkbox
                            id="redeemEnabled"
                            {...register('redeemEnabled')}
                          >
                            <Text color={modeLabelColor}>
                              Enable redeeming points
                            </Text>
                          </Checkbox>
                          <Checkbox
                            id="addPointsEnabled"
                            {...register('addPointsEnabled')}
                          >
                            <Text color={modeLabelColor}>
                              Enable adding points
                            </Text>
                          </Checkbox>
                          <FormErrorMessage mt="4px">
                            {errors.scanEnabled?.message || errors.scanEnabled}
                          </FormErrorMessage>
                        </VStack>
                      </FormControl>
                      <FormControl px="24px" pt="20px">
                        <Checkbox id="qrEnabled" {...register('qrEnabled')}>
                          <Text color={modeLabelColor}>
                            Enable QR-code scanning
                          </Text>
                        </Checkbox>
                      </FormControl>
                      <FormControl px="24px" pt="20px" bg={modeLabelBg}>
                        <Checkbox
                          id="ndefTagScanningEnabled"
                          {...register('ndefTagScanningEnabled')}
                        >
                          <Text color={modeLabelColor}>
                            Enable NDEF Tag scanning
                          </Text>
                        </Checkbox>
                      </FormControl>
                    </Box>
                  </Flex>
                </Collapse>
                <Divider borderColor={modeBorderColor} />
                {/*  Password */}
                <Collapse
                  in={
                    (!isEdit && readerType === 'PassentryReader') ||
                    !!changePassword
                  }
                  animateOpacity
                >
                  <FormControl
                    isInvalid={errors.password}
                    bg={modeFormControlBg}
                  >
                    <Flex alignItems="center">
                      <Hide below="md">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>Password</FormLabel>
                        </Box>
                      </Hide>
                      <Box p="19px" w="full" bg={modeLabelBg}>
                        <Show below="md">
                          <FormLabel>Password</FormLabel>
                        </Show>
                        <Input
                          variant="filledForDarkBg"
                          id="password"
                          type="text"
                          placeholder={`Type ${
                            isEdit ? 'new' : ''
                          } reader password here...`}
                          {...register('password')}
                          autoComplete="off"
                          onBlur={() => {
                            const value = watch('password');
                            if (value.trim() === '') {
                              setValue('password', '');
                            }
                          }}
                        />
                        <FormErrorMessage mt="4px">
                          {errors.password?.message || errors.password}
                        </FormErrorMessage>
                      </Box>
                    </Flex>
                  </FormControl>
                </Collapse>
                {isEdit && readerType === 'PassentryReader' && (
                  <>
                    {changePassword && (
                      <Divider borderColor={modeBorderColor} />
                    )}
                    <Box
                      pl="19px"
                      pr="19px"
                      pt={{ base: '19px', md: '30px' }}
                      pb={{ base: '19px', md: '30px' }}
                      w="full"
                      bg={changePassword ? 'white' : modeLabelBg}
                    >
                      {changePassword ? (
                        <Button
                          alt="Cancel password update"
                          size="sm"
                          variant="secondary"
                          onClick={() => {
                            setValue('changePassword', false);
                            resetField('password');
                          }}
                        >
                          Cancel password update
                        </Button>
                      ) : (
                        <Button
                          alt="Update password"
                          size="sm"
                          onClick={() => {
                            setValue('changePassword', true);
                          }}
                        >
                          Update password
                        </Button>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </SimpleGrid>

          <Box mt={{ base: '20px' }} w="full">
            <Divider borderColor="primaryBackground" mb="15px" />
            <Box display="flex">
              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={!isEdit && !selectedEntity}
                alt="Save"
                size="sm"
                mr="24px"
                mb={{ base: '10px', sm: '0px' }}
              >
                Save
              </Button>
              <Button
                size="sm"
                variant="secondary"
                as={RouterLink}
                to={{
                  pathname: redirectCtx.addReaderFromPassState
                    ? `/passes/create`
                    : redirectCtx.addReaderFromEditPassState
                      ? `/passes/edit/${redirectCtx.passParamUrl}`
                      : '/readers',
                }}
                type="button"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Flex>
      </Container>
    </form>
  );
};

export default ReadersCreatePageForm;
