import React from 'react';
import ClientDashboardPage from '../client/ClientDashboardPage';
import ClientDashboardStatsContextProvider from '../../store/client/ClientDashboardStatsContext';
import FeedbackFormOverlay from '../../components/common/FeedbackFormOverlay/FeedbackFormOverlay';
import NewsletterPopupModal from '../../components/modals/NewsletterPopupModal';

function DashboardPage() {
  return (
    <ClientDashboardStatsContextProvider>
      <FeedbackFormOverlay />
      <NewsletterPopupModal />
      <ClientDashboardPage />
    </ClientDashboardStatsContextProvider>
  );
}

export default DashboardPage;
