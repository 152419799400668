import {
  Box,
  useColorModeValue,
  Text,
  Divider,
  FormControl,
  Flex,
  Hide,
  Show,
  FormLabel,
  Stack,
  Button,
  useDisclosure,
  UnorderedList,
  ListItem,
  useToast,
  useClipboard,
  Input,
} from '@chakra-ui/react';
import moment from 'moment';
import PassContext from '../../../store/client/PassContext';
import { useContext, useEffect } from 'react';
import PassesRenderStatus from './PassesRenderStatus';
import RevokeModal from '../../modals/RevokeModal';
import { useState } from 'react';
// import { useRef } from 'react';
import CustomToast from '../../../common/CustomToast';
// import ViewAllScansModal from '../../modals/ViewAllScansModal';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { HttpContext } from '../../../context/HttpContext';
import PassLinkSender from './PassLinkSender';
import { MeContext } from '../../../context/MeContext';
import parseString from '../../../hooks/useLinkFormatter';
import { Link } from 'react-router-dom';

const PassInfoView = () => {
  const passCtx = useContext(PassContext);
  const template = passCtx.passState.template;
  // const { updateLastScan } = passCtx;
  const { authAxios } = useContext(HttpContext);
  // const [revokeStateLoading, setRevokeStateLoading] = useState(false);
  // const [isReaderRemoved, setIsReaderRemoved] = useState(null);
  // const [showViewAllScansBtn, setShowViewAllScansBtn] = useState(null);
  // const [allScansList, setAllScansList] = useState([]);
  // const [scanReader, setScanReader] = useState('');
  // const [scanDate, setScanDate] = useState('N/A');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('#fff', 'secondaryDark');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeFormControlDeactivatedInsideBg = useColorModeValue(
    'white',
    'primaryDark'
  );
  // const modeNotDefinedText = useColorModeValue('secondaryDark06', 'grey');
  // const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const meContext = useContext(MeContext);
  const accountEmail = meContext.state.account.email;
  const smsAndEmailMessagingEnabled =
    meContext.state.smsAndEmailMessagingEnabled;

  // const previousAllScansListRef = useRef();
  const { onCopy, hasCopied } = useClipboard(passCtx.passState.url);

  // const prepareList = (list) => {
  //   if (!list || list.length === 0) return [];

  //   let newList = [];
  //   let prevListLength = previousAllScansListRef.current.length;
  //   let listLength = list.length;

  //   newList = list.map((item, ind) => {
  //     if (ind < listLength - prevListLength) {
  //       return { ...item, new: true };
  //     }
  //     return { ...item, new: false };
  //   });
  //   return newList;
  // };

  const {
    isOpen: isRevokeModalOpen,
    onOpen: onRevokeModalOpen,
    onClose: onRevokeModalClose,
  } = useDisclosure();
  // const {
  //   isOpen: isViewAllScansModalOpen,
  //   onOpen: onViewAllScansModalOpen,
  //   onClose: onViewAllScansModalClose,
  // } = useDisclosure();

  // const scanDate = passCtx.passState.lastScan?.createdAt
  //   ? `${moment(passCtx.passState.lastScan.createdAt).format(
  //       'D MMMM YYYY hh:mm:ss A'
  //     )} on a reader: `
  //   : 'N/D';

  const deactivatedMessage = `on ${moment(
    passCtx.passState.deactivatedAt
  ).format('D MMMM YYYY hh:mm A')}`;

  const issuedMessage = ` Issued on ${moment(
    passCtx.passState.createdAt
  ).format('D MMMM YYYY hh:mm A')}`;

  const expiryMessage = `Expires on ${moment(
    passCtx.passState.expiryDate
  ).format('D MMMM YYYY hh:mm A')}`;

  const addedToWalletMessage = `First time added to a wallet on ${moment(
    passCtx.passState.addedToWalletAt
  ).format('D MMMM YYYY hh:mm A')}`;

  const formatBackfield = (field) => {
    if (!field) return '';
    return field.split('\\n').map((i, key) => {
      return <Text key={key}>{parseString(i)}</Text>;
    });
  };

  let passInfoFields = [
    {
      // name: 'Last scanned on',
      // value: scanDate,
      // uuid: passCtx.lastScan?.reader?.uuid,
    },
    {
      name: 'Device type',
      value: passCtx.passState.deviceType
        ? passCtx.passState.deviceType === 'iphone'
          ? 'Apple'
          : 'Android'
        : 'N/A',
    },
    {
      name: 'External identifier',
      value: passCtx.externalId || 'N/A',
    },
    {
      name: 'Group tag',
      value: passCtx.groupTag.label || 'N/A',
    },
    {
      name: 'QR code value',
      value: passCtx.qrValue || 'N/A',
    },
    {
      name:
        template.passType !== 'stampCard'
          ? template.headerOneLabel
            ? template.headerOneLabel
            : template.headerOneId
              ? `<${template.headerOneId}>`
              : ''
          : '',
      value:
        template.passType !== 'stampCard'
          ? !!template.headerOneValue && !passCtx.headerOne
            ? template.headerOneValue
            : passCtx.headerOne
          : '',
    },
    {
      name: template.primaryOneLabel
        ? template.primaryOneLabel
        : template.primaryOneId
          ? `<${template.primaryOneId}>`
          : '',
      value:
        !!template.primaryOneValue && !passCtx.primaryOne
          ? template.primaryOneValue
          : passCtx.primaryOne,
    },
    {
      name: template.secOneLabel
        ? template.secOneLabel
        : template.secOneId
          ? `<${template.secOneId}>`
          : '',
      value:
        !!template.secOneValue && !passCtx.secOne
          ? template.secOneValue
          : passCtx.secOne,
    },
    {
      name: template.secTwoLabel
        ? template.secTwoLabel
        : template.secTwoId
          ? `<${template.secTwoId}>`
          : '',
      value:
        !!template.secTwoValue && !passCtx.secTwo
          ? template.secTwoValue
          : passCtx.secTwo,
    },
    {
      name: template.secThreeLabel
        ? template.secThreeLabel
        : template.secThreeId
          ? `<${template.secThreeId}>`
          : '',
      value:
        !!template.secThreeValue && !passCtx.secThree
          ? template.secThreeValue
          : passCtx.secThree,
    },
    {
      name: template.auxOneLabel
        ? template.auxOneLabel
        : template.auxOneId
          ? `<${template.auxOneId}>`
          : '',
      value:
        !!template.auxOneValue && !passCtx.auxOne
          ? template.auxOneValue
          : passCtx.auxOne,
    },
    {
      name: template.auxTwoLabel
        ? template.auxTwoLabel
        : template.auxTwoId
          ? `<${template.auxTwoId}>`
          : '',
      value:
        !!template.auxTwoValue && !passCtx.auxTwo
          ? template.auxTwoValue
          : passCtx.auxTwo,
    },
    {
      name: template.auxThreeLabel
        ? template.auxThreeLabel
        : template.auxThreeId
          ? `<${template.auxThreeId}>`
          : '',
      value:
        !!template.auxThreeValue && !passCtx.auxThree
          ? template.auxThreeValue
          : passCtx.auxThree,
    },
    {
      name: template.backOneLabel
        ? template.backOneLabel
        : template.backOneId
          ? `<${template.backOneId}>`
          : '',
      value:
        !!template.backOneValue && !passCtx.backOne
          ? formatBackfield(template.backOneValue)
          : formatBackfield(passCtx.backOne),
    },
    {
      name: template.backTwoLabel
        ? template.backTwoLabel
        : template.backTwoId
          ? `<${template.backTwoId}>`
          : '',
      value:
        !!template.backTwoValue && !passCtx.backTwo
          ? formatBackfield(template.backTwoValue)
          : formatBackfield(passCtx.backTwo),
    },
    {
      name: template.backThreeLabel
        ? template.backThreeLabel
        : template.backThreeId
          ? `<${template.backThreeId}>`
          : '',
      value:
        !!template.backThreeValue && !passCtx.backThree
          ? formatBackfield(template.backThreeValue)
          : formatBackfield(passCtx.backThree),
    },
    {
      name: template.backFourLabel
        ? template.backFourLabel
        : template.backFourId
          ? `<${template.backFourId}>`
          : '',
      value:
        !!template.backFourValue && !passCtx.backFour
          ? formatBackfield(template.backFourValue)
          : formatBackfield(passCtx.backFour),
    },
    {
      name: template.backFiveLabel
        ? template.backFiveLabel
        : template.backFiveId
          ? `<${template.backFiveId}>`
          : '',
      value:
        !!template.backFiveValue && !passCtx.backFive
          ? formatBackfield(template.backFiveValue)
          : formatBackfield(passCtx.backFive),
    },
    // {
    //   name: 'Assigned event',
    //   // value: ticket.event?.name,
    //   // uuid: ticket.event?.uuid,
    // },
    {
      name: 'Assigned pass template',
      value: template.name,
      uuid: template.uuid,
    },
  ];

  // check if the last reader with which the scan is done, is removed in the meantime in order to apply styling
  // useEffect(() => {
  //   if (
  //     passCtx.passState.lastScan?.reader !== null &&
  //     passCtx.passState.lastScan?.reader !== undefined &&
  //     passCtx.passState.lastScan?.createdAt
  //   ) {
  //     setScanReader(
  //       `${passCtx.passState.lastScan?.reader?.name}, ${passCtx.passState.lastScan?.reader?.userDefinedIdentifier}`
  //     );
  //     setIsReaderRemoved(false);
  //   } else if (
  //     passCtx.passState.lastScan?.reader === null &&
  //     passCtx.passState.lastScan?.createdAt
  //   ) {
  //     setScanReader('Removed reader');
  //     setIsReaderRemoved(true);
  //   } else {
  //     setScanReader('');
  //   }
  // }, [passCtx.passState.lastScan?.createdAt, passCtx.passState.lastScan?.reader]);

  // // view all scans logic
  // useEffect(() => {
  //   const getData = async () => {
  //     const { uuid } = passCtx.passState;
  //     try {
  //       const { data } = await authAxios.get(`/passes/${uuid}/scans`);
  //       setAllScansList(prepareList(data.data));
  //       updateLastScan(data.data[0]);
  //       data.data.length > 1
  //         ? setShowViewAllScansBtn(true)
  //         : setShowViewAllScansBtn(false); // view all scans can be shown only if the are 1+ scans done
  //     } catch (error) {
  //       console.log(error);
  //       const { message, code } = getErrorResponsePayload(error);
  //       code !== 401 &&
  //         toast({
  //           render: (props) => (
  //             <CustomToast
  //               status="error"
  //               title={message ? message : `Something went wrong`}
  //               description={!message && 'Please try again later'}
  //               onClose={props.onClose}
  //             />
  //           ),
  //         });
  //     }
  //   };

  //   getData();

  //   const interval = setInterval(() => {
  //     getData();
  //   }, 5000);

  //   return () => {
  //     clearInterval(interval);
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // add previous value to reference in order to detect newly added rows
  // useEffect(() => {
  //   previousAllScansListRef.current = allScansList;
  // }, [allScansList]);

  // // without this last scan on field display N/A ( up to 5s) is pass is revoked . reissued
  // useEffect(() => {
  //   if (passCtx.passState.lastScan?.createdAt) {
  //     const dateString = `${moment(passCtx.passState.lastScan.createdAt).format(
  //       'D MMMM YYYY hh:mm:ss A'
  //     )} on a reader: `;
  //     setScanDate(dateString);
  //     return;
  //   }
  // }, [passCtx.passState.revoked, passCtx.passState.lastScan]);

  // useEffect(() => {
  //   if (passCtx.passState.revoked) {
  //     setRevokeStateLoading(false);
  //   }
  // }, [passCtx.passState.revoked]);

  const onSendNotification = async () => {
    try {
      const { uuid } = passCtx.passState;
      const response = await authAxios.post(
        `/api/v1/passes/${uuid}/notification`,
        {
          notification: {
            message: notificationMessage,
          },
        }
      );
      setNotificationMessage('');
      if (response.response === 'Sent push notification.') {
        toast({
          render: (props) => (
            <CustomToast
              status="success"
              title={`Notification sent`}
              description=""
              onClose={props.onClose}
            />
          ),
        });
      }
    } catch (error) {
      console.log(error);
      const { message, code } = getErrorResponsePayload(error);
      if (code === 409) {
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={`Pass has not been added to wallet.`}
              description=""
              onClose={props.onClose}
            />
          ),
        });
      } else if (code === 410) {
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={`User has unsubscribed from push notifications.`}
              description=""
              onClose={props.onClose}
            />
          ),
        });
      } else if (code === 422) {
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={`User has not accepted notification permissions.`}
              description=""
              onClose={props.onClose}
            />
          ),
        });
      } else {
        if (code !== 401) {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
        }
      }
    }
  };

  const handleStampPass = async () => {
    setIsLoading(true);
    try {
      const response = await authAxios.patch(
        `/api/v1/loyalty/${passCtx.passState.uuid}`,
        {
          balance: 1,
        }
      );
      const newBalance = response.data.meta.loyalty.balance;
      const newBanner = response.data.data.attributes.passDesign.bannerImageUrl;
      const newPassContent = response.data.data.attributes.passContent;
      passCtx.updateStampedPass(newPassContent);
      passCtx.updateHeaderOne(newBalance);
      passCtx.updatePassDesign({
        ...passCtx.passState.passDesign,
        bannerImageUrl: newBanner,
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <Flex direction="column">
      <Box
        className="autofillForDarkBg"
        w="full"
        borderRadius="15px"
        mb={{ base: 10, xl: 5 }}
        overflow="hidden"
      >
        <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
          <Stack
            p="14px 14px 14px 24px"
            borderRadius="15px 15px 0 0"
            bg={modeTextBg}
            direction={{ base: 'column', '2xl': 'row' }}
          >
            <Text fontSize="18px" textStyle="headingFamilyMedium">
              Pass issuance
            </Text>
          </Stack>
          <Divider borderColor={modeBorderColor} />

          <Box
            bg={modeFormControlBg}
            borderBottomLeftRadius="15px"
            borderBottomRightRadius="15px"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Pass link</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel pl="10px" variant="adminLabel">
                    Pass link
                  </FormLabel>
                </Show>
                <Flex mb={2} alignItems="center">
                  <Input
                    variant="filledForDarkBg"
                    id="passUrl"
                    type="text"
                    value={passCtx.passState.url}
                    disabled
                  />
                  <Button ml={3} onClick={onCopy}>
                    {hasCopied ? 'Copied!' : 'Copy'}
                  </Button>
                </Flex>
              </Box>
            </Flex>
            {smsAndEmailMessagingEnabled && (
              <>
                <Divider borderColor={modeBorderColor} />
                <Flex alignItems="center" justifyContent="space-between">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>Send pass via</FormLabel>
                    </Box>
                  </Hide>
                  <Box
                    p="14px"
                    w="full"
                    bg={modeLabelBg}
                    borderBottomRightRadius="15px"
                    borderBottomLeftRadius={{ base: '15px', '2xl': '0px' }}
                  >
                    <Show below="2xl">
                      <FormLabel pl="10px" variant="adminLabel">
                        Send pass via
                      </FormLabel>
                    </Show>
                    <PassLinkSender />
                  </Box>
                </Flex>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {passCtx.passState.status === 'active' && (
        <Box
          className="autofillForDarkBg"
          w="full"
          borderRadius="15px"
          mb={{ base: 10, xl: 5 }}
          overflow="hidden"
        >
          <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
            <Stack
              p="14px 14px 14px 24px"
              borderRadius="15px 15px 0 0"
              bg={modeTextBg}
              direction={{ base: 'column', '2xl': 'row' }}
            >
              <Text fontSize="18px" textStyle="headingFamilyMedium">
                Pass notifications
              </Text>
            </Stack>
            <Divider borderColor={modeBorderColor} />

            <Box
              bg={modeFormControlBg}
              borderBottomLeftRadius="15px"
              borderBottomRightRadius="15px"
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Send notification</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel pl="10px" variant="adminLabel">
                      Send notification
                    </FormLabel>
                  </Show>
                  <Flex mb={2} alignItems="center">
                    <Input
                      variant="filledForDarkBg"
                      id="passUrl"
                      type="text"
                      placeholder="Enter message here"
                      value={notificationMessage}
                      onChange={(event) =>
                        setNotificationMessage(event.target.value)
                      }
                    />
                    <Button
                      ml={3}
                      onClick={onSendNotification}
                      isDisabled={!notificationMessage}
                    >
                      Send
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
      )}
      <Box className="autofillForDarkBg" w="full" borderRadius="15px">
        <RevokeModal isOpen={isRevokeModalOpen} onClose={onRevokeModalClose} />
        {/* <ViewAllScansModal
          isOpen={isViewAllScansModalOpen}
          onClose={onViewAllScansModalClose}
          allScansList={allScansList}
        /> */}
        <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
          <Text
            fontSize="18px"
            textStyle="headingFamilyMedium"
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            borderRadius="15px 15px 0 0"
          >
            Pass info
          </Text>
          <Divider borderColor={modeBorderColor} />

          {/* Pass status */}
          <FormControl bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Pass status</FormLabel>
                </Box>
              </Hide>
              <Box p="10px 14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel pl="10px" variant="adminLabel">
                    Pass status
                  </FormLabel>
                </Show>
                <Flex
                  bg={
                    passCtx.passState.status !== 'passed'
                      ? modeFormControlDeactivatedInsideBg
                      : ''
                  }
                  borderRadius="25px"
                  p={{ base: '7px 20px', sm: '7px 7px 7px 20px' }}
                  w="full"
                  alignItems="center"
                  justifyContent={{ base: 'center', sm: 'space-between' }}
                  flexDirection="row"
                  flexWrap="wrap"
                  minH="50px"
                >
                  <Stack
                    mb={{ md: '3px' }}
                    direction={{ base: 'column', sm: 'row' }}
                    alignItems="center"
                  >
                    <PassesRenderStatus
                      value={passCtx.passState.status}
                      hasTooltip
                    />
                    {passCtx.passState.status === 'deactivated' && (
                      <Text
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                        lineHeight="120%"
                        pl="10px"
                      >
                        {deactivatedMessage}
                      </Text>
                    )}
                  </Stack>

                  {passCtx.passState.status === 'deactivated' ? (
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      spacing="10px"
                      width={{ base: 'full', sm: 'auto' }}
                    >
                      {/* {passCtx.passState.ticket.event.status !== 'passed' && (
              <Button
                alt="Reissue pass"
                size="sm"
                flexShrink={0}
                px="20px"
                mt={{ base: '4px', sm: '0' }}
                onClick={() => {
                  passCtx.updateRevokedState(false);
                  // setRevokeStateLoading(true);
                }}
                // isLoading={revokeStateLoading}
              >
                Reissue pass
              </Button>
            )} */}
                    </Stack>
                  ) : passCtx.passState.status === 'expired' ||
                    passCtx.passState.status === 'removed' ? null : (
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      spacing="10px"
                      width={{ base: 'full', sm: 'auto' }}
                    >
                      <Button
                        variant="secondary"
                        size="sm"
                        flexShrink={0}
                        px="20px"
                        mt={{ base: '4px', sm: '0' }}
                        onClick={() => {
                          onRevokeModalOpen();
                        }}
                      >
                        Deactivate pass
                      </Button>
                    </Stack>
                  )}
                </Flex>
                <UnorderedList spacing={2}>
                  {!!passCtx.passState.addedToWalletAt && (
                    <ListItem
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                      lineHeight="120%"
                      mt="10px"
                      ml="20px"
                      pl="5px"
                    >
                      {addedToWalletMessage}
                    </ListItem>
                  )}
                  <ListItem
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                    lineHeight="120%"
                    mt="10px"
                    ml="20px"
                    pl="5px"
                  >
                    {issuedMessage}
                  </ListItem>
                  {passCtx.passState.status !== 'deactivated' &&
                    !!passCtx.passState.expiryDate && (
                      <ListItem
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                        lineHeight="120%"
                        mt="10px"
                        ml="20px"
                        pl="5px"
                      >
                        {expiryMessage}
                      </ListItem>
                    )}
                </UnorderedList>
              </Box>
            </Flex>
          </FormControl>

          {/* Stamps */}
          {template.passType === 'stampCard' && (
            <>
              <Divider borderColor={modeBorderColor} />
              <FormControl bg={modeFormControlBg}>
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>{template.headerOneId}</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="10px 14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel pl="10px" variant="adminLabel">
                        {template.headerOneLabel}
                      </FormLabel>
                    </Show>
                    <Flex
                      bg={modeFormControlDeactivatedInsideBg}
                      borderRadius="25px"
                      p={{ base: '7px 20px', sm: '7px 7px 7px 20px' }}
                      w="full"
                      alignItems="center"
                      justifyContent={{ base: 'center', sm: 'space-between' }}
                      flexDirection="row"
                      flexWrap="wrap"
                      minH="50px"
                    >
                      <Stack
                        mb={{ md: '3px' }}
                        direction={{ base: 'column', sm: 'row' }}
                        alignItems="center"
                      >
                        <Text ml={1} mt={1}>
                          {passCtx.headerOne}
                        </Text>
                      </Stack>
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        spacing="10px"
                        width={{ base: 'full', sm: 'auto' }}
                      >
                        <Button
                          size="sm"
                          flexShrink={0}
                          px="20px"
                          mt={{ base: '4px', sm: '0' }}
                          isLoading={isLoading}
                          onClick={handleStampPass}
                        >
                          Stamp pass
                        </Button>
                      </Stack>
                    </Flex>
                  </Box>
                </Flex>
              </FormControl>
            </>
          )}

          {passInfoFields.map((field, index) => {
            return (
              <Box key={index}>
                <FormControl
                  bg={modeFormControlBg}
                  borderBottomLeftRadius={
                    index === passInfoFields.length - 1 ? '15px' : '0'
                  }
                  borderBottomRightRadius="15px"
                >
                  {!!field.name && field.value !== 'N/A' && (
                    <Flex alignItems="center">
                      <Hide below="2xl">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>{field.name}</FormLabel>
                        </Box>
                      </Hide>
                      <Box
                        p="14px"
                        w="full"
                        bg={modeLabelBg}
                        borderBottomLeftRadius={{
                          base: index === passInfoFields.length - 1 && '15px',
                          '2xl': index === passInfoFields.length - 1 && '0px',
                        }}
                        borderBottomRightRadius={{
                          base: index === passInfoFields.length - 1 && '15px',
                          '2xl':
                            index === passInfoFields.length - 1 ? '15px' : '0',
                        }}
                      >
                        <Show below="2xl">
                          <FormLabel pl="10px" variant="adminLabel">
                            {field.name}
                          </FormLabel>
                        </Show>
                        {field.name === 'Pass status' ? (
                          <Box p={3}>
                            <PassesRenderStatus value={field.value} />
                          </Box>
                        ) : field.name === 'Assigned event' ? (
                          <Box p={3}>
                            <Text
                              as={Link}
                              to={`/events/${field.uuid}`}
                              key={index}
                              color="brand"
                              _hover={{ textDecoration: 'underline' }}
                              wordBreak="break-word"
                            >
                              {field.value}
                            </Text>
                          </Box>
                        ) : field.name === 'Assigned pass template' ? (
                          <Box p={3}>
                            <Text
                              as={Link}
                              to={`/pass-templates/edit/${field.uuid}`}
                              key={index}
                              color="brand"
                              _hover={{ textDecoration: 'underline' }}
                              wordBreak="break-word"
                            >
                              {field.value}
                            </Text>
                          </Box>
                        ) : (
                          <Text p={3} wordBreak="break-word">
                            {field.value}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  )}
                </FormControl>
                {index < passInfoFields.length - 1 &&
                  field.value !== null &&
                  field.value !== 'N/A' &&
                  field.value !== '' && (
                    <Divider borderColor={modeBorderColor} />
                  )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Flex>
  );
};
export default PassInfoView;

// Last scan logic

//    : field.name === 'Last scanned on' && field.value === 'N/D' ? (
//   <Text p={3} color={modeNotDefinedText}>
//     N/D
//   </Text>
// ) : field.name === 'Last scanned on' && field.value !== 'N/D' ? (
//   <Flex direction="column">
//     <Text p={3} wordBreak="break-word">
//       {field.value}
//       {!isReaderRemoved ? (
//         <Text
//           as={Link}
//           to={`/readers/${field.uuid}`}
//           color="brand"
//           _hover={{ textDecoration: 'underline' }}
//           wordBreak="break-word"
//         >
//           {scanReader}
//         </Text>
//       ) : (
//         <Text
//           as="span"
//           wordBreak="break-word"
//           color={modeNotDefinedText}
//         >
//           {scanReader}
//         </Text>
//       )}
//     </Text>
//     {showViewAllScansBtn && (
//       <Text
//         as="span"
//         pl={3}
//         wordBreak="break-word"
//         color={modeHelperText}
//         fontSize="14px"
//       >
//         This pass was scanned multiple times.{' '}
//         <Button
//           variant="link"
//           size="xs"
//           onClick={onViewAllScansModalOpen}
//         >
//           View all scans
//         </Button>
//       </Text>
//     )}
//   </Flex>
// )
