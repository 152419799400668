export const REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE =
  /^(?=.*[a-z])(?=.*[A-Z]).+$/;
export const REGEX_HAS_SYMBOL = /(\W)+/;
export const REGEX_HAS_AT_LEAST_ONE_NUMBER = /[0-9]/;
export const REGEX_IS_VALID_URL =
  /^$|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
// /^$|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const REGEX_IS_VALID_DOMAIN =
  /^$|((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

export const REGEX_IS_LESS_THAN_255 = /^.{0,255}$/;

export const REGEX_IS_MOBILE_NUMBER = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export const REGEX_IS_VALID_EMAIL =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_IS_VALID_MOBILE =
  // eslint-disable-next-line no-useless-escape
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const REGEX_IS_VALID_READER_ID = /^[a-zA-Z0-9_-]+$/;

export const addProtocolPrefixUrl = (url) => {
  if (url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    }
    return url;
  }
};

export function lengthInBetween(str, min, max) {
  return str === undefined || min === undefined || max === undefined
    ? false
    : str.trim().length >= min && str.trim().length <= max;
}

export function hasLowerAndUpperCase(str) {
  const pattern = new RegExp(REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE);

  return pattern.test(str);
}

export function hasSymbol(str) {
  const pattern = new RegExp(REGEX_HAS_SYMBOL);

  return pattern.test(str);
}

export function hasDigit(str) {
  const pattern = new RegExp(REGEX_HAS_AT_LEAST_ONE_NUMBER);

  return pattern.test(str);
}
