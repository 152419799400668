import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  Box,
  Divider,
  Button,
  Text,
  useDisclosure,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import LogoutReaderModal from '../../modals/LogoutReaderModal';
import MoreInfoLine from '../../common/MoreInfoLine';
import MessageStatus from '../../client/messages/MessageStatus';
import { CustomVisibilityOffIcon } from '../../../theme/multicolor-icons/CustomVisibilityOffIcon';
import { CustomVisibilityIcon } from '../../../theme/multicolor-icons/CustomVisibilityIcon';

function ReadersListMoreInfo({ cell, children, vtapAutomaticSetupEnabled }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const [showPassword, setShowPassword] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);

  const {
    isOpen: isLogoutReaderOpen,
    onOpen: onLogoutReaderOpen,
    onClose: onLogoutReaderClose,
  } = useDisclosure();

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg={modeBoxBg}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomColor={modeBorderColor}
            pt={{ base: '16px', lg: '56px' }}
            pb={3}
            bg={modeTextBg}
          >
            Reader overview
          </DrawerHeader>
          <DrawerBody p="0" bg={modeLabelBg}>
            <Grid
              templateColumns={{ base: '150px 1fr', md: '192px 1fr' }}
              gap={0}
            >
              <MoreInfoLine
                heading="Reader name"
                value={cell.row.original.name}
              />
              <MoreInfoLine heading="Type" value={cell.row.original.type} />
              <MoreInfoLine
                heading="Reader ID"
                value={cell.row.original.uuid}
              />

              {cell.row.original.type === 'VtapReader' &&
                vtapAutomaticSetupEnabled && (
                  <>
                    {cell.row.original.attributes.location && (
                      <MoreInfoLine
                        heading="Location"
                        value={cell.row.original.attributes.location}
                      />
                    )}
                    <MoreInfoLine
                      heading="VTAP Reader Setup Status"
                      value={
                        <MessageStatus
                          type="reader_setup"
                          status={cell.row.original.attributes.vtapReaderSetup}
                        />
                      }
                    />
                    {cell.row.original.attributes.wifiSsid && (
                      <MoreInfoLine
                        heading="Wifi SSID"
                        value={cell.row.original.attributes.wifiSsid}
                      />
                    )}
                    {cell.row.original.attributes.wifiPassword && (
                      <MoreInfoLine
                        heading={
                          <Text display="flex" alignItems="center">
                            Wifi Password
                          </Text>
                        }
                        value={
                          <Flex alignItems="center">
                            {showPassword ? (
                              <CustomVisibilityOffIcon
                                w="16px"
                                h="16px"
                                mr="10px"
                                cursor="pointer"
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <CustomVisibilityIcon
                                w="16px"
                                h="16px"
                                mr="10px"
                                cursor="pointer"
                                onClick={() => setShowPassword(true)}
                              />
                            )}
                            <Text
                              whiteSpace="normal"
                              overflow="hidden"
                              wordBreak="break-word"
                            >
                              {showPassword
                                ? cell.row.original.attributes.wifiPassword
                                : '••••••••'}
                            </Text>
                          </Flex>
                        }
                      />
                    )}

                    <MoreInfoLine
                      heading="VTAP Automatic Setup"
                      value={
                        cell.row.original.attributes.vtapAutomaticSetup
                          ? 'Enabled'
                          : 'Disabled'
                      }
                    />
                    {cell.row.original.attributes.vtapAutomaticSetup && (
                      <>
                        <MoreInfoLine
                          heading="VTAP Account Type"
                          value={
                            cell.row.original.attributes.vtapApiKey
                              ? 'External VTAP Account'
                              : 'Passentry VTAP Account'
                          }
                        />

                        {cell.row.original.attributes.vtapApiKey && (
                          <MoreInfoLine
                            heading={
                              <Text display="flex" alignItems="center">
                                VTAP API Key
                              </Text>
                            }
                            value={
                              <Flex alignItems="center" width="100%">
                                {showApiKey ? (
                                  <CustomVisibilityOffIcon
                                    w="16px"
                                    h="16px"
                                    mr="10px"
                                    cursor="pointer"
                                    onClick={() => setShowApiKey(false)}
                                  />
                                ) : (
                                  <CustomVisibilityIcon
                                    w="16px"
                                    h="16px"
                                    mr="10px"
                                    cursor="pointer"
                                    onClick={() => setShowApiKey(true)}
                                  />
                                )}
                                <Text
                                  whiteSpace="normal"
                                  overflow="hidden"
                                  wordBreak="break-word"
                                >
                                  {showApiKey
                                    ? cell.row.original.attributes.vtapApiKey
                                    : '••••••••'}
                                </Text>
                              </Flex>
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}

              {cell.row.original.type === 'PassentryReader' && (
                <>
                  <MoreInfoLine
                    heading="Scanning"
                    value={
                      cell.row.original.attributes.scanEnabled
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />
                  <MoreInfoLine
                    heading="Redeeming"
                    value={
                      cell.row.original.attributes.redeemEnabled
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />
                  <MoreInfoLine
                    heading="Adding Points"
                    value={
                      cell.row.original.attributes.addPointsEnabled
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />
                  <MoreInfoLine
                    heading="QR scanning"
                    value={
                      cell.row.original.attributes.qrEnabled
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />
                  <MoreInfoLine
                    heading="NDEF Tag Scanning"
                    value={
                      cell.row.original.attributes.ndefTagScanningEnabled
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />

                  <MoreInfoLine
                    heading="Status"
                    value={<Box display="inline-block">{children}</Box>}
                  />

                  <MoreInfoLine
                    heading="Last Seen At"
                    value={
                      cell.row.original.attributes.lastSeenAt
                        ? moment(
                            cell.row.original.attributes.lastSeenAt
                          ).fromNow()
                        : ''
                    }
                  />
                </>
              )}
              <GridItem colSpan={2}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  padding="16px 0px 16px 22px"
                  flexWrap="wrap"
                >
                  {!!cell.row.original.attributes.loggedIn && (
                    <Button
                      alt="Delete reader"
                      size="xs"
                      px="30px"
                      variant="link"
                      color="brand"
                      onClick={onLogoutReaderOpen}
                    >
                      Log out reader
                    </Button>
                  )}
                  <Button
                    size="sm"
                    alt="Edit reader"
                    mr="20px"
                    ml="auto"
                    onClick={() => {
                      navigate(`/readers/edit/${cell.row.original.uuid}`);
                    }}
                  >
                    Edit reader
                  </Button>
                </Flex>
              </GridItem>

              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            borderTopColor={modeBorderColor}
            bg={modeTextBg}
          >
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
            <Button
              alt="Go to reader page"
              size="sm"
              px="30px"
              alignSelf="flex-start"
              as={RouterLink}
              to={cell.row.original.exampleForNestedObject.readerSinglePageUrl}
            >
              Go to reader page
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <LogoutReaderModal
        isOpen={isLogoutReaderOpen}
        onClose={onLogoutReaderClose}
        readerId={cell.row.original.uuid}
      />
    </>
  );
}

export default ReadersListMoreInfo;
