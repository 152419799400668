import appsignal from '../../../appsignal';
import { useContext, useMemo, useState } from 'react';
import LiveEventDataListTable from './LiveEventDataListTable';
import { useEffect } from 'react';
import LiveEventDataListContext from '../../../store/client/LiveEventDataListContext';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import CustomToast from '../../../common/CustomToast';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { useIsMount } from '../../../hooks/useIsMount';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
import ActionCable from 'actioncable';
import { format, utcToZonedTime } from 'date-fns-tz';

const LiveEventDataList = () => {
  const { authAxios } = useContext(HttpContext);
  const {
    initialLiveEventData,
    initialLiveEventData: { items, included, loading, pageCount, total },
    updateInitialLiveEventData,
    filterValues,
  } = useContext(LiveEventDataListContext);
  const toast = useToast();
  const [isSmallerThan350px] = useMediaQuery('(max-width: 350px)');
  const [newScanCounter, setNewScanCounter] = useState(0);
  const [query, setQuery] = useState('');
  const [formattedData, setFormattedData] = useState([]);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [bearerToken, setBearerToken] = useState(null);
  const [includedData, setIncludedData] = useState([]);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  // websocket

  useEffect(() => {
    if (!bearerToken) {
      return;
    }

    let scansSubscription = null; // Track the ScansChannel subscription

    const url =
      process.env.REACT_APP_API_URL_AXIOS ??
      window.env?.REACT_APP_API_URL_AXIOS ??
      '';
    const strippedUrl = url.replace(/^https?:\/\//, '');
    const isDev = process.env.NODE_ENV === 'development';
    const protocol = isDev ? 'ws' : 'wss';
    const cableUrl = `${protocol}://${strippedUrl}/cable`;

    const consumer = ActionCable.createConsumer(cableUrl);

    function subscribeToPassScansChannel() {
      // Clean up existing subscription if it exists
      if (scansSubscription) {
        scansSubscription.unsubscribe();
      }

      // Create new subscription
      scansSubscription = consumer.subscriptions.create(
        {
          channel: 'ScansChannel',
        },
        {
          connected: () =>
            console.log('ScansChannel connected! Entity:', filterEntity?.uuid),
          disconnected: () => console.log('ScansChannel disconnected!'),
          received: (message) => {
            setIncludedData((prev) => [...prev, ...(message?.included || [])]);
            updateInitialLiveEventData((prev) => {
              const isNewScan = !prev.items.some(
                (item) => item.id === message.data.id
              );

              if (isNewScan) {
                setNewScanCounter((prev) => prev + 1);
              }

              return {
                ...prev,
                items: [message.data, ...prev.items],
                loading: false,
                total: prev.total + 1,
              };
            });
          },
        }
      );
    }

    consumer.subscriptions.create('AuthenticationChannel', {
      connected() {
        this.perform('authenticate', {
          token: bearerToken,
          entity: filterEntity?.uuid || null,
        });
      },

      disconnected() {
        console.log('AuthChannel disconnected');
      },

      received(data) {
        if (data.status === 'rejected') {
          console.log('Authentication rejected');
        } else if (data.status === 'authenticated') {
          console.log('Authentication successful');
          subscribeToPassScansChannel();
        }
      },
    });

    return () => {
      if (scansSubscription) {
        scansSubscription.unsubscribe();
      }
      if (consumer) {
        consumer.disconnect();
      }
    };
  }, [bearerToken, filterEntity]);

  // render data in table only if it matches the filter - all events is default
  useEffect(() => {
    const queryReader =
      filterValues.reader !== ''
        ? `&readerId=${filterValues?.reader?.value}`
        : '';
    const queryPass =
      filterValues.pass !== '' ? `&passId=${filterValues.pass}` : '';
    const queryScanType =
      filterValues.scanType !== ''
        ? `&scanType=${filterValues?.scanType?.value}`
        : '';
    const queryStatus =
      filterValues.status !== ''
        ? `&status=${filterValues?.status?.value}`
        : '';
    const queryValidationType =
      filterValues.validationType !== ''
        ? `&validationType=${filterValues?.validationType?.value}`
        : '';
    const queryGroupTag =
      filterValues.groupTag !== ''
        ? `&groupTag=${filterValues?.groupTag?.label}`
        : '';
    const query = `${queryReader}${queryPass}${queryScanType}${queryStatus}${queryValidationType}${queryGroupTag}`;
    setQuery(query);
    setNewScanCounter(0);
    if (query !== '') {
      updateInitialLiveEventData((prev) => {
        return {
          ...prev,
          items: [],
          included: [],
          pageCount: 0,
          loading: true,
        };
      });
    }
  }, [filterValues]);

  const fetchData = async (currentPage = pageCount) => {
    try {
      let response;
      if (filterEntity) {
        response = await authAxios.get(
          `api/v1/scans?entity=${filterEntity.uuid}&perPage=30&page=${currentPage + 1}${query}`
        );
      } else {
        response = await authAxios.get(
          `api/v1/scans?perPage=30&page=${currentPage + 1}${query}`
        );
      }
      const data = response?.data?.data || [];
      const total = response?.data?.meta?.totalCount ?? 0;
      const filteredData = data.filter(
        (newItem) =>
          !items.some((existingItem) => existingItem.id === newItem.id)
      );

      const hasMore =
        filteredData.length > 0 && total > items.length + filteredData.length;
      setIncludedData((prev) => [
        ...prev,
        ...(response?.data?.included ? response.data.included : []),
      ]);
      updateInitialLiveEventData((prev) => {
        return {
          ...prev,
          items: currentPage === 0 ? data : [...prev.items, ...data],
          included: [...prev.included, ...(response?.data?.included || [])],
          pageCount: currentPage + 1,
          total,
          hasMore,
          loading: false,
          status: 'resolved',
        };
      });
    } catch (error) {
      appsignal.sendError(error);
      updateInitialLiveEventData({
        ...initialLiveEventData,
        items: [],
        total: 0,
        loading: false,
        error: 'Something went wrong',
        status: 'rejected',
      });
      const { message, code } = getErrorResponsePayload(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  useEffect(() => {
    fetchData(0);
  }, [query, filterEntity]);

  const columns = useMemo(
    () => [
      {
        Header: 'Pass Ext ID',
        accessor: 'externalId',
        className: 'modeTdColor',
        style: {
          fontFamily: 'Inter Bold, sans-serif',
          fontSize: '16px',
          wordBreak: 'break-word',
          minWidth: '30px',
          width: '20%',
        },
      },
      {
        Header: 'View Pass',
        accessor: 'viewPass',
        style: {
          minWidth: '30px',
          width: '120px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Reader',
        accessor: 'readerName',
        style: {
          minWidth: '50px',
          width: '200px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        style: {
          minWidth: '50px',
          width: '130px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Validation',
        accessor: 'validation',
        style: {
          minWidth: '50px',
          width: '130px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: isSmallerThan350px ? '' : 'Status',
        accessor: 'status',
        style: {
          width: isSmallerThan350px ? '0px' : '110px',
        },
      },
      {
        Header: 'Time',
        accessor: 'time',
        style: {
          width: '120px',
        },
      },
      {
        Header: '',
        accessor: 'moreInfo',
        style: {
          width: '10px',
          padding: 0,
        },
      },
    ],
    []
  );

  const getDisplayTime = (isoTimeString) => {
    const zonedDate = utcToZonedTime(isoTimeString, timeZone);
    const formattedDate = format(zonedDate, 'dd/MM/yyyy HH:mm:ss', timeZone);
    return formattedDate;
  };

  const normalizedData = (data) => {
    if (data?.length > 0) {
      return data
        .map((item) => {
          const passUuid = item?.relationships?.pass?.data?.id ?? null;
          const readerUuid = item?.relationships?.reader?.data?.id ?? null;

          const passExternalId =
            includedData.find((item) => item.id === passUuid)?.attributes
              ?.extId ?? null;

          const readerName =
            includedData.find((item) => item.id === readerUuid)?.attributes
              ?.name ?? null;

          const type =
            item?.attributes?.scanType === 'barcode'
              ? 'Barcode'
              : item?.attributes?.scanType === 'nfc'
                ? 'NFC'
                : 'N/A';
          const validationType =
            item?.attributes?.validationType === 'internalValidation'
              ? 'PassEntry'
              : item?.attributes?.validationType === 'externalValidation'
                ? 'Third-Party'
                : 'N/A';
          const newItem = {
            ...item,
            externalId: passExternalId ?? null,
            readerName: readerName ?? null,
            readerId: readerUuid ?? null,
            status: item?.attributes.status ?? null,
            statusDetail: item?.attributes.statusDetail ?? null,
            time: getDisplayTime(item?.attributes.scannedAt),
            passUuid: passUuid ?? null,
            viewPass: passUuid ? '/passes/' + passUuid : null,
            type: type,
            validation: validationType,
            scanValue: item?.attributes?.scanValue ?? null,
          };
          return newItem;
        })
        .filter((item) => item !== null);
    }
    return [];
  };

  useEffect(() => {
    const fetchAndProcessData = async () => {
      try {
        const filteredItems = items.filter((item) => item !== null);
        const filteredData = normalizedData(filteredItems);
        setFormattedData(filteredData);
      } catch (error) {
        appsignal.sendError(error);
        console.error(error);
      }
    };
    fetchAndProcessData();
  }, [items]);

  const filtersApplied =
    filterValues.reader !== '' ||
    filterValues.event !== '' ||
    filterValues.groupTag !== '';

  return (
    <LiveEventDataListTable
      columns={columns}
      update={fetchData}
      data={formattedData}
      isLoading={loading}
      query={query}
      filtersApplied={filtersApplied}
      newScanCounter={newScanCounter}
    />
  );
};

export default LiveEventDataList;
