import {
  Box,
  Heading,
  Center,
  useColorModeValue,
  Grid,
  Skeleton,
  Flex,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import { CustomWalletIconStrokeAnimated } from '../../../theme/icons-animated/CustomWalletIconStrokeAnimated';
import { CustomPassesMultiIconStrokeAnimated } from '../../../theme/icons-animated/CustomPassesMultiIconStrokeAnimated';
import { CustomReaderIconAnimated } from '../../../theme/icons/CustomReaderIconAnimated';
import { CustomSmsIconStrokeAnimated } from '../../../theme/icons-animated/CustomSmsIconStrokeAnimated';
import KeyStatItem from './KeyStatItem';
import moment from 'moment';

const KeyStats = ({ isLoading }) => {
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeBgDark = useColorModeValue('gradientDark', 'primaryDark');
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#EFEFEF', '#515d66');
  const dashboardCtx = useContext(ClientDashboardStatsContext);
  const { dashboardState } = dashboardCtx;

  return (
    <Box bg={modeBgDark} borderRadius="15px" px={8} py={5} mb={8} pb={8}>
      <Heading color="white" mb={6}>
        Monthly Overview
      </Heading>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          xl: 'repeat(4, 1fr)',
        }}
        gap={8}
      >
        <Skeleton isLoaded={!isLoading} fadeDuration={1}>
          <KeyStatItem
            icon={<CustomPassesMultiIconStrokeAnimated boxSize="30px" />}
            text="Issued Passes"
            helper={`In ${moment().format('MMMM')}`}
            isLoading={isLoading}
          >
            {!isLoading && (
              <Heading pt="10px">
                {dashboardState.keyStats.issuedThisMonth}
              </Heading>
            )}
          </KeyStatItem>
        </Skeleton>
        <Skeleton isLoaded={!isLoading} fadeDuration={1}>
          <KeyStatItem
            icon={<CustomWalletIconStrokeAnimated boxSize="27px" />}
            text="Added to Wallet"
            helper={`In ${moment().format('MMMM')}`}
            isLoading={isLoading}
          >
            {!isLoading && (
              <Heading pt="10px">
                {dashboardState.keyStats.addedToWalletThisMonth}
              </Heading>
            )}
          </KeyStatItem>
        </Skeleton>
        <Skeleton isLoaded={!isLoading} fadeDuration={1}>
          <KeyStatItem
            icon={<CustomReaderIconAnimated boxSize="30px" />}
            text="Scans"
            helper={`In ${moment().format('MMMM')}`}
            isLoading={isLoading}
          >
            {!isLoading && (
              <Heading pt="10px">
                {dashboardState.keyStats.scansThisMonth}
              </Heading>
            )}
          </KeyStatItem>
        </Skeleton>
        <Skeleton isLoaded={!isLoading} fadeDuration={1}>
          <KeyStatItem
            icon={<CustomSmsIconStrokeAnimated boxSize="30px" mode="dark" />}
            text="Notifications Sent"
            helper={`In ${moment().format('MMMM')}`}
            isLoading={isLoading}
          >
            {!isLoading && (
              <Heading pt="10px">
                {dashboardState.keyStats.notificationsThisMonth}
              </Heading>
            )}
          </KeyStatItem>
        </Skeleton>
      </Grid>
    </Box>
  );
};

export default KeyStats;
