import {
  Container,
  Heading,
  SimpleGrid,
  Divider,
  VStack,
  Flex,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import FormAccountDetails from '../../components/client/account/FormAccountDetails';
import FormChangeEmailAddress from '../../components/client/account/FormChangeEmailAddress';
import FormChangePassword from '../../components/client/account/FormChangePassword';
import { useContext } from 'react';
// import NightModeSwitcher from '../../components/client/account/NightModeSwitcher';
import { MeContext } from '../../context/MeContext';
import TeamInvitations from '../../components/client/account/TeamInvitations';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

function AccountSettingsPage() {
  const meCtx = useContext(MeContext);
  const { invitedAccount } = meCtx.state;
  const { shouldRender, components } = useOrganizationStatus();

  const user = meCtx?.state?.data;

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Account settings',
      linkUrl: null,
    },
  ];

  return (
    <Container pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <Flex alignItems="center" mt="15px" py="9px" minH="54px">
        <Heading>Account settings</Heading>
      </Flex>
      <Divider borderColor="primaryBackground" mb={6} />

      {components[0]}

      <SimpleGrid
        w="full"
        columns={{ base: 1, xl: 2 }}
        columnGap={6}
        rowGap={6}
      >
        <VStack spacing={6}>
          <FormAccountDetails user={user} />
          {!invitedAccount && shouldRender && <TeamInvitations />}
          {/* <NightModeSwitcher /> */}
        </VStack>
        <VStack spacing={6}>
          <FormChangeEmailAddress user={user} />
          <FormChangePassword />
        </VStack>
      </SimpleGrid>
    </Container>
  );
}

export default AccountSettingsPage;
