import {
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Flex,
  useColorModeValue,
  Square,
  Center,
  Box,
} from '@chakra-ui/react';

const KeyStatItem = (props) => {
  const { inverse, icon, text, helper, children, isLoading } = props;
  const modeSquareBg = useColorModeValue('gradientDark', 'primaryDark');
  const modeSquareBgDark = useColorModeValue('quinaryBackground', 'white');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeBoxBgDark = useColorModeValue('gradientDark', 'gradientBrand');

  return (
    <Center bg={modeBoxBg} borderRadius="15px" px={8} py={5} height="100%">
      <Flex
        align="center"
        gap={4}
        w="100%"
        justify="space-between"
        height="100%"
      >
        <Stat
          height="100%"
          sx={{
            '& dl': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            },
          }}
        >
          <StatLabel fontSize="16px" fontWeight="600">
            {text}
          </StatLabel>
          <Box>
            <StatNumber>{children}</StatNumber>
            <StatHelpText>{helper}</StatHelpText>
          </Box>
        </Stat>
        <Square
          size="58px"
          bg={inverse ? modeSquareBgDark : modeSquareBg}
          borderRadius="15px"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          className={inverse ? ' ' : 'multicolorDarkBg'}
        >
          {icon}
        </Square>
      </Flex>
    </Center>
  );
};

export default KeyStatItem;
