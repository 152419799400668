import { Suspense, useContext } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { useColorMode } from '@chakra-ui/react';
import Styleguide from './components/Styleguide';
import LayoutStyleguide from './common/LayoutStyleguide';
import LayoutAuth from './common/LayoutAuth';
import LayoutPlain from './common/LayoutPlain';
import LayoutLogged from './common/LayoutLogged';
import RegisterTicketingEventsPage from './pages/public/RegisterTicketingEventsPage';
import LoginPage from './pages/public/LoginPage';
import ForgotPasswordPage from './pages/public/ForgotPasswordPage';
import ResetPasswordPage from './pages/public/ResetPasswordPage';
import ThankYouPage from './pages/public/ThankYouPage';
import VerifyingYourEmail from './pages/public/VerifyingYourEmailPage';
import DashboardPage from './pages/public/DashboardPage';
import AccountSettingsPage from './pages/client/AccountSettingsPage';
import HeaderNotLogged from './common/HeaderNotLogged';
import HeaderPlain from './common/HeaderPlain';
import {
  HEADER_TOP_XL,
  HEADER_TYPE_RESET,
  HEADER_TYPE_LOGIN,
  HEADER_TYPE_BACK,
} from './utils/consts';
import { AuthContext } from './context/AuthContext';
import RedirectPage from './pages/public/RedirectPage';
import VerifyingChangedEmailPage from './pages/public/VerifyingChangedEmailPage';
import { MeProvider } from './context/MeContext';
import UseMe from './hooks/useMe';
import PassTemplateCreatePage from './pages/client/PassTemplateCreatePage';
import PassTemplateEditPage from './pages/client/PassTemplateEditPage';
import PassTemplateClientPage from './pages/client/PassTemplateClientPage';
import EventsListPage from './pages/client/EventsListPage';
import EventsCreatePage from './pages/client/EventsCreatePage';
import EventsEditPage from './pages/client/EventsEditPage';
import ReadersListPage from './pages/client/ReadersListPage';
import PassesListPage from './pages/client/PassesListPage';
import ReadersCreatePage from './pages/client/ReadersCreatePage';
import ReadersEditPage from './pages/client/ReadersEditPage';
import PassesCreatePage from './pages/client/PassesCreatePage';
import PassesConfirmPage from './pages/client/PassesConfirmPage';
import PassesViewPage from './pages/client/PassesViewPage';
import PassesEditPage from './pages/client/PassesEditPage';
import ErrorNotFoundPage from './components/errors/ErrorNotFoundPage';
import EventsViewPage from './pages/client/EventsViewPage';
import ReadersViewPage from './pages/client/ReadersViewPage';
import LiveEventDataPage from './pages/client/LiveEventDataPage';
import DeveloperToolsPage from './pages/client/DeveloperToolsPage';
import MessageLogsPage from './pages/client/MessageLogsPage';
import MessagesPage from './pages/client/MessagesPage';
import PassesBatchesPage from './pages/client/PassesBatchesPage';
import PassesCSVCreatePage from './pages/client/PassesCSVCreatePage';
import ReaderConfigurationPage from './pages/client/ReaderConfigurationPage';
import PassDownloadTemplatePage from './pages/client/PassDownloadTemplatePage';
import FeatureFlagGuard from './common/FeatureFlagGuard';

const LoadingFallback = () => <div>Loading...</div>;

function AppRoutes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          {/* STYLEGUIDE */}
          <Route
            path="/styleguide"
            element={
              <LayoutStyleguide>
                <Styleguide />
              </LayoutStyleguide>
            }
          />
          {/* Home route */}
          <Route element={<HomeRoute />}>
            <Route
              path="/"
              element={
                <LayoutAuth
                  header={
                    <HeaderNotLogged
                      top={HEADER_TOP_XL}
                      type={HEADER_TYPE_LOGIN}
                    />
                  }
                >
                  <LoginPage />
                </LayoutAuth>
              }
            />
          </Route>
          {/* PUBLIC */}
          <Route element={<PublicRoutes />}>
            <Route path="/error" element={<ErrorNotFoundPage />} />
            <Route
              path="/confirm/:confirmationToken"
              element={
                <LayoutPlain header={<HeaderPlain top={HEADER_TOP_XL} />}>
                  <VerifyingYourEmail />
                </LayoutPlain>
              }
            />
            <Route
              path="/invite/:inviteToken"
              element={
                <LayoutAuth header={<HeaderNotLogged top={HEADER_TOP_XL} />}>
                  <RegisterTicketingEventsPage />
                </LayoutAuth>
              }
            />
            <Route
              path="/email/confirm/:confirmationToken"
              element={
                <LayoutPlain header={<HeaderPlain top={HEADER_TOP_XL} />}>
                  <VerifyingChangedEmailPage />
                </LayoutPlain>
              }
            />
          </Route>

          {/* CAN BE BOTH PRIVATE AND PUBLIC */}

          {/* AUTH  */}
          <Route element={<AuthRoute />}>
            <Route
              path="/login"
              element={
                <LayoutAuth
                  header={
                    <HeaderNotLogged
                      top={HEADER_TOP_XL}
                      type={HEADER_TYPE_LOGIN}
                    />
                  }
                >
                  <LoginPage />
                </LayoutAuth>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <LayoutAuth
                  header={
                    <HeaderNotLogged
                      top={HEADER_TOP_XL}
                      type={HEADER_TYPE_RESET}
                    />
                  }
                >
                  <ForgotPasswordPage />
                </LayoutAuth>
              }
            />
            <Route
              path="/password-reset/:resetToken"
              element={
                <LayoutAuth
                  header={
                    <HeaderNotLogged
                      top={HEADER_TOP_XL}
                      type={HEADER_TYPE_RESET}
                    />
                  }
                >
                  <ResetPasswordPage />
                </LayoutAuth>
              }
            />
            <Route
              path="/enquire"
              element={
                <LayoutAuth header={<HeaderNotLogged top={HEADER_TOP_XL} />}>
                  <RegisterTicketingEventsPage />
                </LayoutAuth>
              }
            />
            <Route
              path="/thank-you"
              element={
                <LayoutAuth
                  header={
                    <HeaderNotLogged
                      top={HEADER_TOP_XL}
                      type={HEADER_TYPE_BACK}
                    />
                  }
                >
                  <ThankYouPage />
                </LayoutAuth>
              }
            />
          </Route>

          {/* PRIVATE */}
          <Route element={<PrivateRoutes />}>
            <Route
              path="/passes"
              element={
                <LayoutLogged>
                  <PassesListPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/passes/create"
              element={
                <LayoutLogged>
                  <PassesCreatePage />
                </LayoutLogged>
              }
            />
            <Route
              path="/passes/batches"
              element={
                <LayoutLogged>
                  <PassesBatchesPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/passes/batches/create"
              element={
                <LayoutLogged>
                  <PassesCSVCreatePage />
                </LayoutLogged>
              }
            />
            <Route
              path="/passes/confirm/:uuid"
              element={
                <LayoutLogged>
                  <PassesConfirmPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/passes/:uuid"
              element={
                <LayoutLogged>
                  <PassesViewPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/passes/edit/:uuid"
              element={
                <LayoutLogged>
                  <PassesEditPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/live-data"
              element={
                <LayoutLogged>
                  <LiveEventDataPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/pass-templates"
              element={
                <LayoutLogged>
                  <PassTemplateClientPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/pass-templates/create"
              element={
                <LayoutLogged>
                  <PassTemplateCreatePage />
                </LayoutLogged>
              }
            />
            <Route
              path="/pass-templates/edit/:uuid"
              element={
                <LayoutLogged>
                  <PassTemplateEditPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/pass-download"
              element={
                <LayoutLogged>
                  <PassDownloadTemplatePage />
                </LayoutLogged>
              }
            />
            <Route
              path="/events"
              element={
                <LayoutLogged>
                  <FeatureFlagGuard flagKey="eventsEnabled">
                    <EventsListPage />
                  </FeatureFlagGuard>
                </LayoutLogged>
              }
            />
            <Route
              path="/events/create"
              element={
                <LayoutLogged>
                  <FeatureFlagGuard flagKey="eventsEnabled">
                    <EventsCreatePage />
                  </FeatureFlagGuard>
                </LayoutLogged>
              }
            />
            <Route
              path="/events/edit/:uuid"
              element={
                <LayoutLogged>
                  <FeatureFlagGuard flagKey="eventsEnabled">
                    <EventsEditPage />
                  </FeatureFlagGuard>
                </LayoutLogged>
              }
            />
            <Route
              path="/events/:uuid"
              element={
                <LayoutLogged>
                  <FeatureFlagGuard flagKey="eventsEnabled">
                    <EventsViewPage />
                  </FeatureFlagGuard>
                </LayoutLogged>
              }
            />
            <Route
              path="/account-settings"
              element={
                <LayoutLogged>
                  <AccountSettingsPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/developer-tools"
              element={
                <LayoutLogged>
                  <DeveloperToolsPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/readers"
              element={
                <LayoutLogged>
                  <ReadersListPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/readers/create"
              element={
                <LayoutLogged>
                  <ReadersCreatePage />
                </LayoutLogged>
              }
            />
            <Route
              path="/readers/:uuid"
              element={
                <LayoutLogged>
                  <ReadersViewPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/readers/edit/:uuid"
              element={
                <LayoutLogged>
                  <ReadersEditPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/readers/reader-configuration"
              element={
                <LayoutLogged>
                  <ReaderConfigurationPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/messaging"
              element={
                <LayoutLogged>
                  <MessageLogsPage />
                </LayoutLogged>
              }
            />
            <Route
              path="/messaging/create"
              element={
                <LayoutLogged>
                  <MessagesPage />
                </LayoutLogged>
              }
            />
            {/* <Route path="/logout" element={<LogoutPage />} /> */}
            <Route
              path="*"
              element={
                <LayoutLogged>
                  <RedirectPage />
                </LayoutLogged>
              }
            />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route
              path="/dashboard"
              element={
                <LayoutLogged>
                  <DashboardPage />
                </LayoutLogged>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

function PrivateRoutes() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  return isAuthenticated() ? (
    <MeProvider>
      <UseMe>
        <Outlet />
      </UseMe>
    </MeProvider>
  ) : (
    <Navigate to="/login" state={{ next: location }} replace />
  );
}

// MODE ALWAYS LIGHT ROUTES
function PublicRoutes() {
  const { colorMode, toggleColorMode } = useColorMode();
  if (colorMode === 'dark') {
    toggleColorMode();
  }
  return <Outlet />;
}

function AuthRoute() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { colorMode, toggleColorMode } = useColorMode();
  if (colorMode === 'dark') {
    toggleColorMode();
  }

  if (location.pathname === '/login' && urlParams.get('impersonationUuid')) {
    return <Outlet />;
  }

  if (isAuthenticated() === undefined) {
    return null; // or loading spinner, etc...
  }

  return isAuthenticated() ? (
    <Navigate to={'/dashboard'} replace />
  ) : (
    <Outlet />
  );
}

function HomeRoute() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  if (location.pathname === '/login' && urlParams.get('impersonationUuid')) {
    return <Outlet />;
  }

  return isAuthenticated() ? <Navigate to="/dashboard" /> : <Outlet />;
}

export default AppRoutes;
