import React, { useEffect, useState } from 'react';
import { useColorModeValue, Text } from '@chakra-ui/react';
import FormFieldItem from './FormFieldItem';
import { Droppable } from 'react-beautiful-dnd';

const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    // This is a workaround for React Strict Mode
    // in React 18, useEffect is called twice in development
    const animation = requestAnimationFrame(() => {
      setEnabled(true);
    });

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const FormFields = ({ formFields, setFormFields, templateFields }) => {
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');

  useEffect(() => {
    const orderHasChanged = formFields.some(
      (field, index) => field.position !== index
    );

    if (orderHasChanged) {
      setFormFields(
        formFields.map((field, index) => ({ ...field, position: index }))
      );
    }
  }, [formFields]);

  return (
    <>
      {formFields?.length > 0 ? (
        <StrictModeDroppable droppableId="formFields">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                minHeight: formFields.length ? 'auto' : '100px',
                transition: 'background-color 0.2s ease',
                backgroundColor: snapshot.isDraggingOver
                  ? 'rgba(0, 0, 0, 0.02)'
                  : 'transparent',
                position: 'relative',
                width: '100%',
                maxWidth: '100%',
              }}
            >
              {formFields.map((field, index) => (
                <FormFieldItem
                  key={index}
                  field={field}
                  index={index}
                  formFields={formFields}
                  setFormFields={setFormFields}
                  templateFields={templateFields}
                  type={field.type}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      ) : (
        <Text
          textStyle="bodyFamilyRegular"
          fontSize="12px"
          color={modeHelperText}
          pl={'14px'}
        >
          No form fields added
        </Text>
      )}
    </>
  );
};

export default FormFields;
