import {
  Box,
  Text,
  Divider,
  Flex,
  Button,
  useToast,
  useColorModeValue,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Badge,
  useMediaQuery,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MeContext } from '../../../context/MeContext';
import { HttpContext } from '../../../context/HttpContext';
import TableBox from '../../common/TableBox';
import { CustomSendIcon } from '../../../theme/icons/CustomSendIcon';
import SendInvitationModal from '../../modals/SendInvitationModal';
import appsignal from '../../../appsignal';
import CustomToast from '../../../common/CustomToast';
import InvitationMoreInfo from './InvitationMoreInfo';

function TeamInvitations() {
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const meCtx = useContext(MeContext);
  const { invitations, invitationLimit, entities } = meCtx.state;
  const [isSendInvitationOpen, setIsSendInvitationOpen] = useState(false);
  const { authAxios } = useContext(HttpContext);
  const fetchIdRef = useRef(0);
  const toast = useToast();

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 30em)');

  const onSendInvitationOpen = () => {
    setIsSendInvitationOpen(true);
  };

  const onSendInvitationClose = () => {
    setIsSendInvitationOpen(false);
  };

  const fetchData = useCallback(() => {
    const fetchId = ++fetchIdRef.current;
    setTimeout(async () => {
      if (fetchId === fetchIdRef.current) {
        try {
          const response = await authAxios.get(`accounts/me`);
          const account = response?.data?.account;
          const organisationStatus = response?.data?.organisation_status;
          const trialExpiry = response?.data?.trial_expiry;
          const entities = response?.data?.entities;
          const passTemplates = response?.data?.pass_templates;
          const invitations = response?.data?.invitations;
          const invitationLimit = account?.invitation_limit;
          meCtx.updateState({
            ...meCtx.state,
            account,
            entities,
            organisationStatus,
            trialExpiry,
            passTemplates,
            invitations,
            invitationLimit,
            status: 'resolved',
            error: null,
          });
        } catch (onError) {
          appsignal.sendError(onError);
          console.log(onError);
        }
      }
    }, 0);
  }, [authAxios]);

  const getEntityName = (entityId) => {
    const entity = entities.find((entity) => entity.id === entityId);
    return entity ? entity.name : 'All entities';
  };

  const handleResendInvitation = async (email, role, entityId, companyName) => {
    try {
      await authAxios.post(`accounts/invitation`, {
        invitation: {
          email: email,
          role: role,
          entity_id: role === 'ent_admin' ? entityId : null,
          company_name: companyName,
        },
      });
      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title="Invitation sent."
            description="You have successfully resent the invitation."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      const errors = onError.response.data.errors;
      if (errors) {
        errors.forEach((error) => {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={error}
                description=""
                onClose={props.onClose}
              />
            ),
          });
        });
      }
    }
  };

  useEffect(() => {
    fetchData({ inviteSent: false });
  }, [fetchData]);

  return (
    <>
      <Box className="autofillForDarkBg" w="full">
        <Box
          className="primaryBoxShadow"
          bg={modeBoxBg}
          borderRadius="15px"
          pb={0}
        >
          <Flex
            direction="row"
            justifyContent="space-between"
            align="center"
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            borderRadius="15px 15px 0 0"
          >
            <Flex direction="column">
              <Text fontSize="18px" textStyle="headingFamilyMedium">
                Team
              </Text>
              <Text
                fontSize="14px"
                mt={0.5}
                textStyle="bodyRoman"
                color="secondaryDark08"
                bg={modeTextBg}
              >
                {invitationLimit} invitation{invitationLimit === 1 ? '' : 's'}{' '}
                left
              </Text>
            </Flex>
            <Button
              onClick={() => onSendInvitationOpen()}
              gap={2}
              size="xs"
              isDisabled={invitationLimit === 0}
            >
              Send invite
            </Button>
          </Flex>
          <Divider borderColor={modeBorderColor} />

          {invitations.length === 0 && (
            <Box p="14px 24px">
              <Text textStyle="bodyRoman" color="secondaryDark08">
                You have not sent any invitations.
              </Text>
            </Box>
          )}
          {invitations.length > 0 && (
            <>
              <TableBox>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Email</Th>
                      <Th display={isLargerThanXS ? 'table-cell' : 'none'}>
                        Status
                      </Th>
                      <Th display={isLargerThan2XL ? 'table-cell' : 'none'}>
                        Role
                      </Th>
                      <Th
                        display={isLargerThan2XL ? 'table-cell' : 'none'}
                      ></Th>
                      <Th
                        display={!isLargerThan2XL ? 'table-cell' : 'none'}
                      ></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {invitations.map((invitation, index) => (
                      <Tr key={index}>
                        <Td>{invitation.email}</Td>
                        <Td display={isLargerThanXS ? 'table-cell' : 'none'}>
                          <Badge
                            alignContent="center"
                            align="center"
                            display="inline"
                            variant={
                              invitation.email_verified ? 'active' : 'pending'
                            }
                          >
                            {invitation.email_verified ? 'Accepted' : 'Pending'}
                          </Badge>
                        </Td>
                        <Td display={isLargerThan2XL ? 'table-cell' : 'none'}>
                          {invitation.role === 'org_admin'
                            ? 'Organisation Admin'
                            : `Entity Admin`}
                          {invitation.role === 'ent_admin' &&
                            invitation.entity_id &&
                            ` (${getEntityName(invitation.entity_id)})`}
                        </Td>
                        <Td display={isLargerThan2XL ? 'table-cell' : 'none'}>
                          {!invitation.email_verified && (
                            <Button
                              variant="link"
                              pt="1px"
                              size="sm"
                              onClick={() =>
                                handleResendInvitation(
                                  invitation.email,
                                  invitation.role,
                                  invitation.entity_id,
                                  invitation.company_name
                                )
                              }
                            >
                              <Flex justifyContent="center" align="center">
                                <CustomSendIcon />
                                <Text textStyle="bodyFamilyMedium" pl="7px">
                                  Resend
                                </Text>
                              </Flex>
                            </Button>
                          )}
                        </Td>
                        <Td display={!isLargerThan2XL ? 'table-cell' : 'none'}>
                          <InvitationMoreInfo
                            invitation={invitation}
                            handleResendInvitation={handleResendInvitation}
                            getEntityName={getEntityName}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableBox>
            </>
          )}
        </Box>
      </Box>
      <SendInvitationModal
        isOpen={isSendInvitationOpen}
        onClose={onSendInvitationClose}
        fetchData={fetchData}
      />
    </>
  );
}

export default TeamInvitations;
