import { useContext, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import {
  Stack,
  Flex,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  useMediaQuery,
  Spinner,
  Hide,
  Image,
  HStack,
  Show,
  useColorModeValue,
} from '@chakra-ui/react';
import TableBox from '../../common/TableBox';
import ReactTablePagination from '../../../common/ReactTablePagination';
import EmptyState from '../../../common/EmptyState';
import readersIconSrc from '../../../assets/vectors/readersIcon.svg';
import readersIconDarkSrc from '../../../assets/vectors/readersIcon-dark.svg';
import ReadersRenderStatus from './ReadersRenderStatus';
import ReadersListMoreInfo from './ReadersListMoreInfo';
import ReadersListContext from '../../../store/client/ReadersListContext';
import RedirectionContext from '../../../context/RedirectionContext';
import ReaderIconAnimated from '../../../theme/illustrations-animated//ReaderIconAnimated';
import ReaderIconDarkAnimated from '../../../theme/illustrations-animated//ReaderIconDarkAnimated';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';

// Create a default prop getter (per documentation https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles)
const defaultPropGetter = () => ({});

export default function ReadersListTable({
  columns,
  data,
  fetchData,
  isLoading,
  total,
  pageCount: controlledPageCount,
  filterEntity,
  vtapAutomaticSetupEnabled,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  query,
}) {
  const navigate = useNavigate();
  const readerListCtx = useContext(ReadersListContext);
  const redirectCtx = useContext(RedirectionContext);

  // use this ref to keep track of updating internal table state
  const tableStateUpdateRef = useRef(false);

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');

  const modeReadersIcon = useColorModeValue(
    <ReaderIconAnimated />,
    <ReaderIconDarkAnimated />
  );
  const modeReadersIconSrc = useColorModeValue(
    readersIconSrc,
    readersIconDarkSrc
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns([]);
    } else if (isLargerThanXL) {
      setHiddenColumns([]);
    } else if (isLargerThanLG) {
      setHiddenColumns(['uuid']);
    } else if (isLargerThanMD) {
      setHiddenColumns(['uuid']);
    } else if (isLargerThanSM) {
      setHiddenColumns(['uuid']);
    } else if (isLargerThanS) {
      setHiddenColumns(['uuid', 'type', 'actionFirst']);
    } else if (isLargerThanXS) {
      setHiddenColumns(['uuid', 'type', 'actionFirst']);
    } else {
      setHiddenColumns(['name', 'uuid', 'type', 'actionFirst']);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanLG,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    setHiddenColumns,
    isLargerThanXS,
  ]);

  useEffect(() => {
    gotoPage(0);
  }, [filterEntity, gotoPage]);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // reset the page index to 0 when the table data updates due to something other than internal table state changes
  useEffect(() => {
    gotoPage(0);
  }, [gotoPage, readerListCtx.clearPageIndex]);

  // clear our ref when the data is loaded, after we perform any side effects
  useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  function handleEditClick(e, row) {
    e.stopPropagation();
    navigate(`/readers/edit/${row.original.uuid}`);
  }

  function handleRowClick(row) {
    navigate(row.original.exampleForNestedObject.readerSinglePageUrl);
  }

  if (isLoading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        mt={4}
        mx="auto"
      />
    );
  }

  if (data.length === 0 && !isLoading && query === '') {
    return (
      <EmptyState
        iconSvgAnimated={modeReadersIcon}
        heading="No readers have been added yet."
        text="Click on the button below and start adding Readers."
      >
        <Button
          alt="Add reader"
          size="sm"
          as={RouterLink}
          to="/readers/create"
          onClick={() => {
            // reset for add reader
            redirectCtx.updateAddReaderFromPassState(false);
            redirectCtx.updateAddReaderFromEditPassState(false);
          }}
        >
          Add reader
        </Button>
      </EmptyState>
    );
  } else if (data.length === 0 && !isLoading && query !== '') {
    return (
      <EmptyState
        iconSvgAnimated={modeReadersIcon}
        heading="No results found."
        text="Try adjusting your filters to find the readers that you are looking for."
      />
    );
  }

  return (
    <>
      <TableBox>
        <Heading size="lg" mb="16px">
          Pass readers{' '}
          <Text
            as="span"
            textStyle="headingFamilyMedium"
            fontSize="18px"
            fontWeight="500"
          >
            (
            <Box as="span" color="brand">
              {total}
            </Box>
            )
          </Text>
        </Heading>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      {...column.getToggleHiddenProps()}
                      isNumeric={column.isNumeric}
                    >
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {data.length > 0 && (
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                          isNumeric={cell.column.isNumeric}
                          textAlign={
                            (cell.column.id === 'actionZero' ||
                              cell.column.id === 'actionFirst') &&
                            'right'
                          }
                        >
                          {cell.column.id === 'name' ? (
                            <Box>
                              <HStack spacing={3}>
                                <Hide below="md">
                                  <Box>
                                    <Image
                                      src={modeReadersIconSrc}
                                      maxWidth="50px"
                                    />
                                  </Box>
                                </Hide>
                                <Text>{cell.value}</Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'status' ? (
                            <ReadersRenderStatus
                              loggedIn={cell.row.original.attributes.loggedIn}
                            />
                          ) : cell.column.id === 'actionFirst' ? (
                            <Button
                              size="sm"
                              px={{ base: '14px', md: '19px' }}
                              onClick={(e) => {
                                handleEditClick(e, row);
                              }}
                            >
                              <HStack zIndex={1}>
                                <Show below="md">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Show above="xl">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Hide below="md">
                                  <Text
                                    textStyle="headingFamilyMedium"
                                    fontWeight="500"
                                    lineHeight="28px"
                                    textTransform="none"
                                  >
                                    Edit reader
                                  </Text>
                                </Hide>
                              </HStack>
                            </Button>
                          ) : cell.column.id === 'moreInfo' ? (
                            <ReadersListMoreInfo
                              cell={cell}
                              vtapAutomaticSetupEnabled={
                                vtapAutomaticSetupEnabled
                              }
                            >
                              <ReadersRenderStatus
                                loggedIn={cell.row.original.attributes.loggedIn}
                              />
                            </ReadersListMoreInfo>
                          ) : cell.value !== null ? (
                            cell.render('Cell')
                          ) : (
                            <Text as="span" color="secondaryDark06">
                              N/D
                            </Text>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        {data.length > 0 && (
          <Flex
            mt={6}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'space-between' }}
          >
            <Stack
              spacing="18px"
              direction={{ base: 'column', sm: 'row' }}
              shouldWrapChildren="true"
            ></Stack>

            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </TableBox>
    </>
  );
}
