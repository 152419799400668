import { useEffect } from 'react';

const HubSpotForm = ({
  formId,
  portalId,
  region = 'eu1',
  fields,
  handleSubmit,
}) => {
  useEffect(() => {
    const scriptId = 'hubspot-form-script';
    let script = document.getElementById(scriptId);

    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.type = 'text/javascript';
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.charset = 'utf-8';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }

    const handleScriptLoad = () => {
      if (window.hbspt && !document.querySelector(`#form-${formId} form`)) {
        window.hbspt.forms.create({
          region: region,
          formId: formId,
          portalId: portalId,
          target: `#form-${formId}`,

          onFormReady: function ($form) {
            // Use the handleFieldPrefill function to set field values
            handleFieldPrefill($form);
          },
          onFormSubmitted: function () {
            handleSubmit();
          },
        });
      }
    };

    const handleFieldPrefill = ($form) => {
      if (!fields || !$form) return;

      // Loop through all fields in the fields object
      Object.entries(fields).forEach(([fieldName, fieldValue]) => {
        // Skip if no value provided
        if (!fieldValue) return;

        // Find the input element for this field
        const inputElement = $form.querySelector(`input[name="${fieldName}"]`);

        if (inputElement) {
          // Set the input value
          inputElement.value = fieldValue;

          // Trigger necessary events for HubSpot to recognize the value
          ['input', 'change', 'blur'].forEach((eventType) => {
            const event = new Event(eventType, { bubbles: true });
            inputElement.dispatchEvent(event);
          });
        }
      });
    };

    if (window.hbspt) {
      handleScriptLoad();
    } else {
      script.addEventListener('load', handleScriptLoad);
      script.setAttribute('data-loaded', 'true');
    }

    // Cleanup function
    return () => {
      if (script && !window.hbspt) {
        script.removeEventListener('load', handleScriptLoad);
      }
    };
  }, [formId, portalId, region, fields]);

  return <div id={`form-${formId}`} data-testid={`form-${formId}`}></div>;
};

export default HubSpotForm;
