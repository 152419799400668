import React, { useEffect, useState } from 'react';
import { Divider, Heading, useColorModeValue } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Flex,
  FormLabel,
  FormControl,
  Input,
  Switch,
  Tooltip,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import { CustomReactSelect } from '../../../common/CustomReactSelect';
import { CustomExclamationMarkInverseIcon } from '../../../../theme/icons/CustomExclamationMarkInverseIcon';
import { DragIcon } from '../../../../theme/icons/DragIcon';
import { AddIcon } from '@chakra-ui/icons';
import { CustomEditIcon } from '../../../../theme/icons/CustomEditIcon';

const tooltipStyles = {
  hasArrow: true,
  placement: 'top',
  fontSize: '12px',
  w: '205px',
  borderRadius: '6px',
  textAlign: 'center',
  p: '10px',
};

const inputStyles = {
  variant: 'filledForDarkBg',
  type: 'text',
  autoComplete: 'off',
};

const formErrorMessageStyles = {
  fontSize: '12px',
  paddingLeft: '22px',
  paddingTop: '10px',
  color: '#E53E3E',
};

const FormFieldItem = ({
  field,
  index,
  formFields,
  setFormFields,
  templateFields,
  type = 'input',
}) => {
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeHelperText = useColorModeValue('secondaryDark', '#DDDDDD');

  const isDropdownField = type === 'dropdown';
  const hasLabelError = !field.label?.trim();
  const hasValidationError =
    !isDropdownField &&
    field?.validation?.name === 'custom' &&
    !field?.validation?.pattern?.trim();

  const handleRemoveField = () => {
    const newFields = [...formFields];
    newFields.splice(index, 1);
    setFormFields(newFields);
  };

  const [editDropdownOptions, setEditDropdownOptions] = useState();

  useEffect(() => {
    setEditDropdownOptions(false);
  }, []);

  const handleChangeFieldType = (option) => {
    const newFields = [...formFields];
    newFields[index] = {
      ...field,
      type: option.value,
      ...(option.value === 'dropdown' && {
        placeholder: 'Select an option...',
        options: [
          {
            value: 'option1',
            label: 'Option 1',
          },
          {
            value: 'option2',
            label: 'Option 2',
          },
          {
            value: 'option3',
            label: 'Option 3',
          },
        ],
      }),
      ...(option.value === 'input' && {
        validation: {
          name: 'none',
          pattern: null,
          message: null,
        },
      }),
    };
    if (option.value === 'dropdown') {
      delete newFields[index].validation;
    } else {
      delete newFields[index].placeholder;
      delete newFields[index].options;
    }
    setFormFields(newFields);
  };

  return (
    <Draggable draggableId={`draggable-${index}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          position="relative"
          style={{
            ...provided.draggableProps.style,
            background: snapshot.isDragging ? modeBoxBg : modeLabelBg,
            boxShadow: snapshot.isDragging
              ? '0 5px 10px rgba(0, 0, 0, 0.15)'
              : 'none',
          }}
          width="100%"
          maxWidth="100%"
          borderBottom={'2px dashed'}
          borderTop={{
            base: index === 0 ? '2px dashed' : 'none',
            '2xl': 'none',
          }}
          borderColor={modeBorderColor}
        >
          <Button
            size="sm"
            variant="ghost"
            onClick={handleRemoveField}
            zIndex="1"
            color={modeHelperText}
            textStyle="bodyFamilyRegular"
            fontSize="12px"
            position="absolute"
            right={0}
            top={0}
          >
            ✕
          </Button>
          <Flex>
            <Flex
              fontSize={'24px'}
              background="#DDDDDD"
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={
                formFields.length === 1
                  ? '0 16px 16px 0'
                  : index === 0
                    ? '0 16px 0 0'
                    : index === formFields.length - 1
                      ? '0 0 16px 0'
                      : 'none'
              }
              {...provided.dragHandleProps}
              _active={{ cursor: 'grabbing' }}
            >
              <DragIcon />
            </Flex>
            <Flex
              p="14px"
              gap={4}
              mb={4}
              flexDirection={{ base: 'column' }}
              width="100%"
              maxWidth="100%"
            >
              <Flex flexDirection={{ base: 'row', sm: 'column' }}>
                <FormLabel
                  flexShrink={0}
                  w={{ sm: '120px' }}
                  color={modeHelperText}
                >
                  Field type
                </FormLabel>
                <Box flex="1">
                  <CustomReactSelect
                    value={{
                      value: isDropdownField ? 'dropdown' : 'input',
                      label: isDropdownField ? 'Dropdown' : 'Input',
                    }}
                    id="passType"
                    options={[
                      { value: 'input', label: 'Input' },
                      { value: 'dropdown', label: 'Dropdown' },
                    ]}
                    onChange={(option) => handleChangeFieldType(option)}
                    placeholder="Select field type..."
                  />
                </Box>
              </Flex>
              <Flex flexDirection={{ base: 'row', sm: 'column' }}>
                <FormLabel
                  flexShrink={0}
                  w={{ sm: '120px' }}
                  color={modeHelperText}
                >
                  <Flex alignItems={'center'} gap={2}>
                    Template field
                    {field.type === 'dropdown' && (
                      <Tooltip
                        {...tooltipStyles}
                        bg={bgTooltip}
                        color={textTooltip}
                        label="When set to none, the field value will be added to the pass as a group tag."
                      >
                        <Box cursor="pointer" pb={0.5}>
                          <CustomExclamationMarkInverseIcon
                            boxSize="12px"
                            mode={'light'}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>
                </FormLabel>
                <Box flex="1">
                  <CustomReactSelect
                    value={{
                      value: field.id,
                      label: field.id,
                    }}
                    id="passType"
                    isOptionDisabled={(option) =>
                      formFields.some(
                        (af) => af.id === option.value && af.id !== field.id
                      )
                    }
                    options={templateFields
                      .map((f) => ({
                        value: f.id,
                        label: f.id,
                      }))
                      .concat(
                        !formFields.find((f) => f.id == 'none') &&
                          field.type === 'dropdown'
                          ? {
                              value: 'none',
                              label: 'none',
                            }
                          : []
                      )}
                    onChange={(option) => {
                      const newFields = [...formFields];
                      newFields[index] = {
                        ...field,
                        id: option.value,
                      };
                      setFormFields(newFields);
                    }}
                    placeholder="Select template field..."
                  />
                </Box>
              </Flex>
              <Flex flexDirection={{ base: 'column', sm: 'column' }}>
                <FormLabel flexShrink={0} w={{ sm: '120px' }}>
                  Label
                </FormLabel>
                <FormControl isInvalid={hasLabelError}>
                  <Input
                    value={field.label}
                    variant="filledForDarkBg"
                    type="text"
                    fontSize="16px"
                    placeholder="Enter field label..."
                    autoComplete="off"
                    maxLength={30}
                    onChange={(e) => {
                      const newFields = [...formFields];
                      newFields[index] = {
                        ...field,
                        label: e.target.value,
                      };
                      setFormFields(newFields);
                    }}
                  />
                  {hasLabelError && (
                    <FormErrorMessage style={formErrorMessageStyles}>
                      Label is required
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              {!isDropdownField && (
                <>
                  <Flex flexDirection={{ base: 'column', sm: 'column' }}>
                    <FormLabel flexShrink={0} w={{ sm: '120px' }}>
                      Validation type
                    </FormLabel>
                    <Box flex="1">
                      <CustomReactSelect
                        value={{
                          value: field?.validation?.name || 'none',
                          label: field?.validation?.name || 'None',
                        }}
                        id="passType"
                        type="text"
                        variant="filledForDarkBg"
                        options={[
                          { value: 'none', label: 'None' },
                          { value: 'phone', label: 'Phone' },
                          { value: 'email', label: 'Email' },
                          { value: 'url', label: 'URL' },
                          { value: 'custom', label: 'Custom' },
                        ]}
                        onChange={(option) => {
                          const newFields = [...formFields];
                          newFields[index] = {
                            ...field,
                            validation: {
                              name: option.value,
                              pattern: null,
                              message: null,
                            },
                          };
                          setFormFields(newFields);
                        }}
                        placeholder="Select validation type..."
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: '100%',
                          }),
                        }}
                      />
                    </Box>
                  </Flex>
                  {!isDropdownField && field?.validation?.name === 'custom' && (
                    <Box>
                      <Flex alignItems="center" gap={2}>
                        <FormLabel m={0}>Custom Regex</FormLabel>
                        <Tooltip
                          {...tooltipStyles}
                          bg={bgTooltip}
                          color={textTooltip}
                          label="Enter a custom regex pattern for the field. This will be used to validate the field when the user submits the form, e.g. '[A-Za-z]'"
                        >
                          <Box cursor="pointer" pb={0.5}>
                            <CustomExclamationMarkInverseIcon
                              boxSize="12px"
                              mode={'light'}
                            />
                          </Box>
                        </Tooltip>
                      </Flex>
                      <FormControl isInvalid={hasValidationError}>
                        <Input
                          {...inputStyles}
                          value={field?.validation?.pattern || ''}
                          placeholder="Enter regex pattern..."
                          onChange={(e) => {
                            const newFields = [...formFields];
                            newFields[index] = {
                              ...field,
                              validation: {
                                ...field?.validation,
                                pattern: e.target.value,
                              },
                            };
                            setFormFields(newFields);
                          }}
                        />
                        {hasValidationError && (
                          <FormErrorMessage style={formErrorMessageStyles}>
                            Custom regex is required
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  )}
                </>
              )}
              {isDropdownField && (
                <>
                  <Flex flexDirection={{ base: 'column', sm: 'column' }}>
                    <FormLabel flexShrink={0} w={{ sm: '120px' }}>
                      Placeholder
                    </FormLabel>
                    <FormControl>
                      <Input
                        value={field.placeholder}
                        variant="filledForDarkBg"
                        type="text"
                        fontSize="16px"
                        autoComplete="off"
                        maxLength={30}
                        onChange={(e) => {
                          const newFields = [...formFields];
                          newFields[index] = {
                            ...field,
                            placeholder: e.target.value,
                          };
                          setFormFields(newFields);
                        }}
                      />
                    </FormControl>
                  </Flex>
                  <Flex flexDirection={{ base: 'column', sm: 'column' }}>
                    <FormLabel flexShrink={0}>Dropdown options</FormLabel>
                    <Flex
                      gap={2}
                      flexDirection={{ base: 'column', sm: 'column' }}
                    >
                      {editDropdownOptions ? (
                        field?.options?.map((option, index) => (
                          <Flex key={index} alignItems="center" gap={2}>
                            <Input
                              height={'32px'}
                              value={option.label}
                              variant="filledForDarkBg"
                              type="text"
                              onChange={(e) => {
                                const newFields = [...formFields];
                                const optionIndex = field.options.findIndex(
                                  (o) => o.label === option.label
                                );
                                field.options[optionIndex].label =
                                  e.target.value;
                                setFormFields(newFields);
                              }}
                            />
                            {editDropdownOptions && (
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => {
                                  const newFields = [...formFields];
                                  const optionIndex = field.options.findIndex(
                                    (o) => o.label === option.label
                                  );
                                  field.options.splice(optionIndex, 1);
                                  setFormFields(newFields);
                                }}
                                isDisabled={field.options.length <= 1}
                              >
                                <Text fontSize="12px" color={modeHelperText}>
                                  Remove
                                </Text>
                              </Button>
                            )}
                          </Flex>
                        ))
                      ) : (
                        <Flex justifyContent={'space-between'}>
                          <Box flexDirection={'column'} width={'100%'} gap={2}>
                            {field?.options?.map((option, index) => (
                              <>
                                <Text
                                  key={index}
                                  color={modeHelperText}
                                  textStyle="bodyFamilyRegular"
                                  fontSize="12px"
                                  mt={index === 0 ? 0 : 2}
                                >
                                  {option.label}
                                </Text>
                                <Divider borderColor={modeBorderColor} />
                              </>
                            ))}
                          </Box>
                          <Box
                            cursor="pointer"
                            onClick={() => setEditDropdownOptions(true)}
                            display="inline-flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            gap={2}
                            pl={4}
                          >
                            <CustomEditIcon color="brand" boxSize="14px" />
                            <Heading color="secondaryDark" fontSize="14px">
                              Edit
                            </Heading>
                          </Box>
                        </Flex>
                      )}
                    </Flex>
                    {editDropdownOptions && (
                      <Flex gap={2} justifyContent={'flex-end'}>
                        <Button
                          height={'24px'}
                          alignSelf={'flex-end'}
                          mt={'14px'}
                          size="sm"
                          p={2}
                          onClick={() => {
                            const newOptions = [
                              ...field.options,
                              {
                                value: `option${field.options.length + 1}`,
                                label: `Option ${field.options.length + 1}`,
                              },
                            ];
                            field.options = newOptions;
                            const newFields = [...formFields];
                            newFields[index] = field;
                            setFormFields(newFields);
                          }}
                          variant="secondary"
                        >
                          <AddIcon />
                          <Text pl={1} fontSize="12px">
                            Add option
                          </Text>
                        </Button>
                        <Button
                          height={'24px'}
                          alignSelf={'flex-end'}
                          mt={'14px'}
                          size="sm"
                          p={2}
                          onClick={() => {
                            setEditDropdownOptions(false);
                          }}
                        >
                          <Text pl={1} fontSize="12px">
                            Close
                          </Text>
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </>
              )}
              {!isDropdownField && field?.validation?.name !== 'none' && (
                <Box>
                  <Flex alignItems="center" gap={2}>
                    <FormLabel m={0}>Custom Error Message</FormLabel>
                    <Tooltip
                      {...tooltipStyles}
                      bg={bgTooltip}
                      color={textTooltip}
                      label={`Customise the error message shown to the user for invalid input. This defaults to 'Please enter a valid ${field.label}.'`}
                    >
                      <Box cursor="pointer" pb={0.5}>
                        <CustomExclamationMarkInverseIcon
                          boxSize="12px"
                          mode={'light'}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                  <FormControl>
                    <Input
                      value={field?.validation?.message || ''}
                      variant="filledForDarkBg"
                      type="text"
                      placeholder="Enter a custom error message..."
                      autoComplete="off"
                      maxLength={100}
                      onChange={(e) => {
                        const newFields = [...formFields];
                        newFields[index] = {
                          ...field,
                          validation: {
                            ...field?.validation,
                            message: e.target.value,
                          },
                        };
                        setFormFields(newFields);
                      }}
                    />
                  </FormControl>
                </Box>
              )}
              <Flex justifyContent={'space-between'}>
                <Flex alignItems={'center'} gap={2}>
                  <FormLabel m={0} flexShrink={0}>
                    Required?
                  </FormLabel>
                  <Tooltip
                    {...tooltipStyles}
                    bg={bgTooltip}
                    color={textTooltip}
                    label="When enabled a user must complete this field in order to submit this form."
                  >
                    <Box cursor="pointer" pb={0.5}>
                      <CustomExclamationMarkInverseIcon
                        boxSize="12px"
                        mode={'light'}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
                <Switch
                  isChecked={field.required || false}
                  isDisabled={field.extId}
                  onChange={(e) => {
                    const newFields = [...formFields];
                    newFields[index] = {
                      ...field,
                      required: e.target.checked,
                    };
                    setFormFields(newFields);
                  }}
                />
              </Flex>
              {!isDropdownField && (
                <Flex justifyContent={'space-between'}>
                  <Flex alignItems={'center'} gap={2}>
                    <FormLabel m={0} flexShrink={0}>
                      Ext ID?
                    </FormLabel>
                    <Tooltip
                      {...tooltipStyles}
                      bg={bgTooltip}
                      color={textTooltip}
                      label="The value of this field will be used as the external ID when creating a pass for a user."
                    >
                      <Box cursor="pointer" pb={0.5}>
                        <CustomExclamationMarkInverseIcon
                          boxSize="12px"
                          mode={'light'}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                  <Switch
                    isChecked={field.extId}
                    onChange={(e) => {
                      const newFields = [...formFields];
                      newFields.forEach((f) => {
                        f.extId = false;
                      });
                      newFields[index] = {
                        ...field,
                        extId: e.target.checked,
                        required: e.target.checked,
                      };
                      setFormFields(newFields);
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Box>
      )}
    </Draggable>
  );
};

export default FormFieldItem;
