import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { useContext, useState, useEffect } from 'react';
import { HttpContext } from '../../context/HttpContext';
import { MeContext } from '../../context/MeContext';
import HubSpotForm from '../common/HubspotForm';

const NewsletterPopupModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const { authAxios } = useContext(HttpContext);
  const meContext = useContext(MeContext);
  const { state } = meContext;
  const email = state?.account?.email;
  const firstName = state?.account?.first_name;
  const lastName = state?.account?.last_name;

  useEffect(() => {
    if (state.newsletterPopUpAccepted === true) {
      return;
    }

    // Check if the popup was dismissed recently
    const dismissedAt = localStorage.getItem('newsletterPopupDismissedAt');

    if (dismissedAt) {
      const currentTime = new Date().getTime();
      const dismissedTime = parseInt(dismissedAt, 10);

      const dismissedTimeInDays = 7;
      const dismissedTimeInMilliseconds =
        dismissedTimeInDays * 24 * 60 * 60 * 1000;

      if (currentTime - dismissedTime < dismissedTimeInMilliseconds) {
        return;
      }
    }

    // Otherwise, show the popup
    setIsOpen(true);
  }, [state.newsletterPopUpAccepted]);

  // Update newsletter popup status when modal is closed
  const handleClose = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('newsletterPopupDismissedAt', currentTime);
    setIsOpen(false);
  };

  const handleSubmit = () => {
    authAxios
      .post('/accounts/me/newsletter-pop-up/accept', {
        newsletter_pop_up_accepted: true,
      })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.error('Failed to update newsletter popup status:', error);
        setIsOpen(false);
      });
  };

  // Don't render anything if not open
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      motionPreset="slideInBottom"
      isCentered={{ base: true, md: false }}
      blockScrollOnMount={true}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        bg={modeBoxBg}
        position="fixed"
        bottom={{ base: null, md: '2rem' }}
        right={{ base: null, md: '2rem' }}
        width={{ base: '90%', md: '40%' }}
        height={{ base: 'auto', md: 'auto' }}
        maxH={{ base: '90vh', md: 'auto' }}
        maxW="unset"
        margin="0"
        overflow="hidden"
      >
        <ModalCloseButton />
        <ModalBody
          p={6}
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(0,0,0,0.2)',
            },
          }}
        >
          <HubSpotForm
            formId="ffd12db4-3f58-4b7d-a091-ee86aae1a7c1"
            portalId="139540062"
            region="eu1"
            fields={{
              email: email,
              firstname: firstName,
              lastname: lastName,
            }}
            handleSubmit={handleSubmit}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewsletterPopupModal;
