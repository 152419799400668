import { createContext, useState } from 'react';

const initialState = {
  totalPassTemplates: 0,
  keyStats: {
    addedToWalletThisMonth: 0,
    scansThisMonth: 0,
    issuedThisMonth: 0,
    notificationsThisMonth: 0,
  },
  passStats: {
    timeRange: 'all',
    totalPasses: 0,
    passesByStatus: {
      active: 0,
      issued: 0,
      deactivated: 0,
      removed: 0,
    },
    passesByDevice: {
      apple: 0,
      google: 0,
    },
    issuedOverTime: {},
  },
  scanStats: {
    timeRange: 'all',
    totalScans: 0,
    scansOverTime: {},
    scansPerReaderType: {
      passentry: 0,
      vtap: 0,
    },
    scansPerStatus: {
      accepted: 0,
      rejected: 0,
    },
    scansPerScanType: {
      nfc: 0,
      barcode: 0,
    },
    topReaders: {},
    heatmap: [],
  },
  messagingStats: {
    timeRange: 'all',
    totalNotifications: 0,
    notificationsByStatus: {
      successful: 0,
      failed: 0,
      cancelled: 0,
      pending: 0,
    },
    recentBatches: [],
    upcomingBatches: [],
  },
};

export const ClientDashboardStatsContext = createContext({
  dashboardState: initialState,
  updateDashboardData: () => {},
  updateSectionTimeRange: () => {},
});

const ClientDashboardStatsContextProvider = ({ children }) => {
  const [dashboardState, setDashboardState] = useState(initialState);
  const [loadingStates, setLoadingStates] = useState({
    global: false,
    pass: false,
    scan: false,
    messaging: false,
  });

  const setLoading = (section, isLoading) => {
    setLoadingStates((prev) => ({
      ...prev,
      [section]: isLoading,
    }));
  };

  const handleUpdateDashboardData = (data) => {
    setDashboardState((prevState) => ({
      ...prevState,
      totalPassTemplates:
        data.totalPassTemplates ?? prevState.totalPassTemplates,
      keyStats: {
        ...prevState.keyStats,
        ...data.keyStats,
      },
      passStats: {
        ...prevState.passStats,
        ...data.passStats,
        timeRange: prevState.passStats.timeRange,
      },
      scanStats: {
        ...prevState.scanStats,
        ...data.scanStats,
        timeRange: prevState.scanStats.timeRange,
      },
      messagingStats: {
        ...prevState.messagingStats,
        ...data.messagingStats,
        timeRange: prevState.messagingStats.timeRange,
      },
    }));
  };

  const handleUpdateSectionTimeRange = (section, timeRange) => {
    setDashboardState((prevState) => ({
      ...prevState,
      [`${section}Stats`]: {
        ...prevState[`${section}Stats`],
        timeRange,
      },
    }));
  };

  const handleUpdatePassTemplateFilter = (templateFilter) => {
    setDashboardState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        passTemplate: templateFilter,
      },
    }));
  };

  const context = {
    dashboardState,
    loadingStates,
    setLoading,
    updateDashboardData: handleUpdateDashboardData,
    updateSectionTimeRange: handleUpdateSectionTimeRange,
    updatePassTemplateFilter: handleUpdatePassTemplateFilter,
  };

  return (
    <ClientDashboardStatsContext.Provider value={context}>
      {children}
    </ClientDashboardStatsContext.Provider>
  );
};

export default ClientDashboardStatsContextProvider;
