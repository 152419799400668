import { ResponsiveHeatMap } from '@nivo/heatmap';
import { useMediaQuery } from '@chakra-ui/react';

const HeatMap = ({ data }) => {
  const [isLargerThanMd] = useMediaQuery('(min-width: 768px)');

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const fullDaysMap = {
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun',
  };

  const transformedData = (data || []).map((timeSlot) => {
    const existingValues = new Map(
      timeSlot.data.map((item) => [fullDaysMap[item.x] || item.x, item.y])
    );

    const completeData = daysOfWeek.map((day) => ({
      x: day,
      y: existingValues.get(day),
    }));

    return {
      id: timeSlot.id,
      data: completeData,
    };
  });

  return (
    <ResponsiveHeatMap
      data={transformedData}
      margin={{
        top: 0,
        right: isLargerThanMd ? 0 : 15,
        bottom: 100,
        left: 100,
      }}
      valueFormat=">-.0s"
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isLargerThanMd ? 0 : 45,
        legend: 'Day of Week',
        legendOffset: isLargerThanMd ? 46 : 70,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Time of Day',
        legendPosition: 'middle',
        legendOffset: -85,
      }}
      colors={{
        type: 'quantize',
        steps: 5,
        minValue: 0,
        maxValue: 50,
        colors: ['#f8cfcb', '#f5b5af', '#f29c93', '#ef8276', '#ec685a'],
      }}
      emptyColor="#fff7f7"
      borderRadius={6}
      borderWidth={4}
      borderColor="white"
      cellComponent="rect"
    />
  );
};

export default HeatMap;
