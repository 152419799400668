import appsignal from '../appsignal';
import React, { createContext, useCallback, useState } from 'react';
// import axios from 'axios';
import { useColorMode } from '@chakra-ui/react';

const AuthContext = createContext();
const { Provider } = AuthContext;

function parseJwt(jwt) {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const KEYS = {
  // token: process.env.REACT_APP_TOKEN,
  // exp: process.env.REACT_APP_EXP,
  // user: process.env.REACT_APP_USER,
  // refreshToken: process.env.REACT_APP_REFRESH_TOKEN,
  // refreshTokenTTL: process.env.REACT_APP_REFRESH_TOKEN_TTL_SEC,

  exp: 'passkings-exp',
  refreshToken: 'passkings-refresh-token',
  refreshTokenTTL: 'passkings-refresh-token-ttl-sec',
  token: 'passkings-token',
  account: 'passkings-account',
};

function getExpiresAt(token) {
  const payload = parseJwt(token);
  const { exp } = payload;
  return exp;
}

const AuthProvider = ({ children }) => {
  const token =
    sessionStorage.getItem(KEYS.token) || localStorage.getItem(KEYS.token);
  const exp =
    sessionStorage.getItem(KEYS.exp) || localStorage.getItem(KEYS.exp);
  const account =
    sessionStorage.getItem(KEYS.account) || localStorage.getItem(KEYS.account);
  const refreshToken =
    sessionStorage.getItem(KEYS.refreshToken) ||
    localStorage.getItem(KEYS.refreshToken);

  const { colorMode, toggleColorMode } = useColorMode();

  const [authState, setAuthState] = useState({
    token,
    exp,
    account: account ? JSON.parse(account) : {},
    refreshToken,
  });

  const setAuthInfo = ({ token, account, refreshToken }) => {
    const exp = getExpiresAt(token);
    setTokens({ token, refreshToken });
    setAccount(account);

    setAuthState({ token, account, exp, refreshToken });
  };

  const setAccount = (account) => {
    localStorage.setItem(KEYS.account, JSON.stringify(account));
    setAuthState({
      ...authState,
      account,
    });
  };

  const setTokens = ({ token, refreshToken }) => {
    const exp = getExpiresAt(token);
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.exp, exp);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    // ...authState
    setAuthState({ token, exp, refreshToken });
  };

  const setImpersonationAuthInfo = ({ token, account, refreshToken }) => {
    const exp = getExpiresAt(token);
    sessionStorage.setItem(KEYS.token, token);
    sessionStorage.setItem(KEYS.account, JSON.stringify(account));
    sessionStorage.setItem(KEYS.exp, exp);
    sessionStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({ token, account, exp, refreshToken });
  };

  const setImpersonationAccount = (account) => {
    sessionStorage.setItem(KEYS.account, JSON.stringify(account));
    setAuthState({
      ...authState,
      account,
    });
  };

  const setImpersonationTokens = ({ token, refreshToken }) => {
    const exp = getExpiresAt(token);
    sessionStorage.setItem(KEYS.token, token);
    sessionStorage.setItem(KEYS.exp, exp);
    sessionStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({
      ...authState,
      token,
      exp,
      refreshToken,
    });
  };

  const logout = useCallback(async () => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
    try {
      if (sessionStorage.getItem('impersonation') === 'true') {
        sessionStorage.removeItem(KEYS.token);
        sessionStorage.removeItem(KEYS.exp);
        sessionStorage.removeItem(KEYS.account);
        sessionStorage.removeItem(KEYS.refreshToken);
        sessionStorage.removeItem('impersonation');
      } else {
        localStorage.removeItem(KEYS.token);
        localStorage.removeItem(KEYS.exp);
        localStorage.removeItem(KEYS.account);
        localStorage.removeItem(KEYS.refreshToken);
      }
      setAuthState({
        token: null,
        exp: null,
        account: {},
        refreshToken: null,
      });
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
    }
  }, [token, setAuthState]);

  const isAuthenticated = () => {
    if (!authState.exp) {
      return false;
    }
    return true;
  };

  const isAdmin = () => {
    const roles = authState?.account?.roles || [];
    return roles.includes('ROLE_ADMIN') && isAuthenticated();
  };

  const getAccessToken = () => {
    return (
      sessionStorage.getItem(KEYS.token) || localStorage.getItem(KEYS.token)
    );
  };

  const getRefreshToken = () => {
    return (
      sessionStorage.getItem(KEYS.refreshToken) ||
      localStorage.getItem(KEYS.refreshToken)
    );
  };

  function isTokenExpired(token) {
    const exp = getExpiresAt(token);
    return exp * 1000 < Date.now();
  }

  return (
    <Provider
      value={{
        KEYS,
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        setAuthTokens: (tokens) => setTokens(tokens),
        setAuthAccount: (account) => setAccount(account),
        setImpersonationAuthState: (authInfo) =>
          setImpersonationAuthInfo(authInfo),
        setImpersonationAccount: (account) => setImpersonationAccount(account),
        setImpersonationTokens: (tokens) => setImpersonationTokens(tokens),
        logout,
        parseJwt,
        isAuthenticated,
        isAdmin,
        getAccessToken,
        getRefreshToken,
        getExpiresAt,
        isTokenExpired,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
