import {
  Container,
  Heading,
  Flex,
  Divider,
  Grid,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import PassDownloadTemplateForm from '../../components/client/pass-download-template/PassDownloadTemplateForm';
import PreviewDownloadScreens from '../../components/client/pass-download-template/PreviewDownloadScreens';
import PassDownloadTemplateContext from '../../store/client/PassDownloadTemplateContext';
import { useContext, useState } from 'react';
import { HttpContext } from '../../context/HttpContext';
import { MeContext } from '../../context/MeContext';
import CustomToast from '../../common/CustomToast';

const PassDownloadTemplateEditPage = () => {
  const passDownloadCtx = useContext(PassDownloadTemplateContext);
  const meCtx = useContext(MeContext);
  const toast = useToast();
  const { passDownloadTemplateUuid } = meCtx.state;
  const { authAxios } = useContext(HttpContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Pass download page',
      linkUrl: null,
    },
  ];

  const prepareDataForSubmit = () => {
    const {
      backgroundColor,
      headerText,
      headerColor,
      passDownloadText,
      passDownloadTextColor,
      clientWebsite,
      pageTitle,
      skipPreview,
      logoImage,
      faviconImage,
      componentFillColor,
      componentFontColor,
    } = passDownloadCtx;

    const submittedData = {
      background_color: backgroundColor,
      header_text: headerText,
      header_color: headerColor,
      pass_download_text: passDownloadText,
      pass_download_text_color: passDownloadTextColor,
      client_website: clientWebsite,
      page_title: pageTitle,
      skip_preview: skipPreview,
      logo_image: logoImage,
      favicon_image: faviconImage,
      component_fill_color: componentFillColor,
      component_font_color: componentFontColor,
    };
    return submittedData;
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const uuid = passDownloadTemplateUuid;
      const submittedData = prepareDataForSubmit();
      const { data: responseData } = await authAxios.patch(
        `api/v1/pass_download_templates/${uuid}`,
        submittedData
      );
      passDownloadCtx.updateAllData(responseData.data?.attributes);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Pass download page updated successfully"
            description=""
            onClose={onClose}
          />
        ),
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error(error);
      error.response.data.errors.forEach((errorMessage) => {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title={errorMessage}
              description=""
              onClose={onClose}
            />
          ),
        });
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems="center"
        flexWrap="wrap"
        mt="15px"
        py="9px"
        minH="54px"
      >
        <Heading>Edit pass download page</Heading>
      </Flex>
      <Divider borderColor="primaryBackground" mb={6} />
      <Grid
        role="grid"
        templateColumns={{ base: '100%', xl: '1fr 1fr' }}
        gap={6}
      >
        <PassDownloadTemplateForm />
        <PreviewDownloadScreens />
      </Grid>
      <Box mt={{ base: '30px' }} w="full">
        <Divider borderColor="primaryBackground" mb="15px" />
        <Button
          onClick={() => onSubmit()}
          isLoading={isSubmitting}
          alt="Save"
          size="sm"
          mr="24px"
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

export default PassDownloadTemplateEditPage;
