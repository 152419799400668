import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Hide,
  Show,
  Divider,
  Text,
  Stack,
  Switch,
  Tooltip,
  Spinner,
  Button,
  Collapse,
  Link,
} from '@chakra-ui/react';
import LogoImage from '../LogoImage';
import FaviconImage from '../FaviconImage';
import { useColorModeValue } from '@chakra-ui/react';
import CustomColorPicker from '../pass-templates/CustomColorPicker';
import { useContext, useState, useEffect } from 'react';
import PassDownloadTemplateContext from '../../../store/client/PassDownloadTemplateContext';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

const PassDownloadForm = () => {
  const passDownloadCtx = useContext(PassDownloadTemplateContext);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  useEffect(() => {
    function determineFooterColor(hex) {
      const cleanHex = hex.replace(/^#/, '');
      const intVal = parseInt(cleanHex, 16);
      const r = (intVal >> 16) & 255;
      const g = (intVal >> 8) & 255;
      const b = intVal & 255;
      const average = (r + g + b) / 3;
      passDownloadCtx.updateFooterColor(average > 127 ? 'dark' : 'light');
    }
    if (!passDownloadCtx.backgroundColor) return;
    determineFooterColor(passDownloadCtx.backgroundColor);
  }, [passDownloadCtx.backgroundColor]);

  return (
    <Box role="form" className="autofillForDarkBg" borderRadius="15px">
      <Box
        className="primaryBoxShadow"
        bg={modeBoxBg}
        borderRadius="15px"
        overflow="hidden"
      >
        <FormControl
          isRequired
          bg={modeFormControlBg}
          borderRadius="15px 15px 0 0"
        >
          <Flex data-testid="logo-image">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0} pt="18px">
                  Logo image
                </FormLabel>
              </Box>
            </Hide>
            <Box
              p="14px"
              w="full"
              bg={modeLabelBg}
              borderRadius={{ base: '15px 15px 0 0', '2xl': '0 15px 0 0' }}
            >
              <Show below="2xl">
                <FormLabel>Logo image</FormLabel>
              </Show>
              <LogoImage
                image={passDownloadCtx.logo}
                setImage={(image) => passDownloadCtx.updateLogo(image)}
                setLogo={(image) => passDownloadCtx.updateLogoImage(image)}
                parent="download_page"
              />
            </Box>
          </Flex>
        </FormControl>
        <Divider />
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Header text</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Header text</FormLabel>
              </Show>
              <Input
                value={passDownloadCtx.headerText}
                onChange={(e) =>
                  passDownloadCtx.updateHeaderText(e.target.value)
                }
                variant="filledForDarkBg"
                id="headerText"
                type="text"
                placeholder="Type header text here..."
              />
            </Box>
          </Flex>
        </FormControl>
        <Divider />
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <Flex direction="row" align="center" gap={1} my={0.5}>
                  <FormLabel m={0}>Body text</FormLabel>
                  <Tooltip
                    label="This is additional text that appears within the card on the screen"
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box
                      data-testid="body-text-tooltip-button"
                      mb={1}
                      cursor="pointer"
                    >
                      <CustomExclamationMarkInverseIcon
                        boxSize="12px"
                        mode={'light'}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <Flex direction="row" align="center" gap={1} my={0.5}>
                  <FormLabel m={0}>Body text</FormLabel>
                  <Tooltip
                    label="This is additional text that appears within the card on the screen"
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box
                      data-testid="body-text-tooltip-button"
                      mb={1}
                      cursor="pointer"
                    >
                      <CustomExclamationMarkInverseIcon
                        boxSize="12px"
                        mode={'light'}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              </Show>
              <Input
                value={passDownloadCtx.passDownloadText}
                onChange={(e) =>
                  passDownloadCtx.updatePassDownloadText(e.target.value)
                }
                variant="filledForDarkBg"
                id="bodyText"
                type="text"
                placeholder="Type body text here..."
              />
            </Box>
          </Flex>
        </FormControl>
        <Divider />
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Background colour</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Background colour</FormLabel>
              </Show>
              <CustomColorPicker
                parent="download_page"
                color={passDownloadCtx.backgroundColor}
                onChange={(color) =>
                  passDownloadCtx.updateBackgroundColor(color)
                }
                hexError={false}
              />
            </Box>
          </Flex>
        </FormControl>
        <Divider />
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Header font colour</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Header font colour</FormLabel>
              </Show>
              <CustomColorPicker
                parent="download_page"
                color={passDownloadCtx.headerColor}
                onChange={(color) => passDownloadCtx.updateHeaderColor(color)}
                hexError={false}
              />
            </Box>
          </Flex>
        </FormControl>
        <Divider />
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Body font colour</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Body font colour</FormLabel>
              </Show>
              <CustomColorPicker
                parent="download_page"
                color={passDownloadCtx.passDownloadTextColor}
                onChange={(color) =>
                  passDownloadCtx.updatePassDownloadTextColor(color)
                }
                hexError={false}
              />
            </Box>
          </Flex>
        </FormControl>
        <Divider />
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Website URL</FormLabel>
                <Text
                  color={modeHelperText}
                  textStyle="bodyFamilyRegular"
                  fontSize="12px"
                >
                  Linked to logo image
                </Text>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel m={0}>Website URL</FormLabel>
                <Text
                  color={modeHelperText}
                  textStyle="bodyFamilyRegular"
                  fontSize="12px"
                  m="0 0 14px 0"
                >
                  Linked to logo image
                </Text>
              </Show>
              <Input
                value={passDownloadCtx.clientWebsite}
                onChange={(e) =>
                  passDownloadCtx.updateClientWebsite(e.target.value)
                }
                variant="filledForDarkBg"
                id="websiteUrl"
                type="text"
                placeholder="Type website URL here..."
              />
            </Box>
          </Flex>
        </FormControl>
        <Divider />
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <Flex direction="row" align="center" gap={1} my={0.5}>
                  <FormLabel m={0}> Skip "Add to Wallet" screen</FormLabel>
                  <Tooltip
                    label="Skipping this screen will automatically trigger the pass download on a user's device"
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box
                      data-testid="skip-add-to-wallet-tooltip-button"
                      mb={1}
                      cursor="pointer"
                    >
                      <CustomExclamationMarkInverseIcon
                        boxSize="12px"
                        mode={'light'}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <Flex direction="row" align="center" gap={1} my={0.5}>
                  <FormLabel m={0}> Skip "Add to Wallet" screen</FormLabel>
                  <Tooltip
                    label="Skipping this screen will automatically trigger the pass download on a user's device"
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box
                      data-testid="skip-add-to-wallet-tooltip-button"
                      mb={1}
                      cursor="pointer"
                    >
                      <CustomExclamationMarkInverseIcon
                        boxSize="12px"
                        mode={'light'}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              </Show>
              <Flex direction="row" gap={3} alignItems="center" ml={3} py={1.5}>
                <Switch
                  role="switch"
                  aria-checked={passDownloadCtx.skipPreview}
                  id="skipPreview"
                  isChecked={passDownloadCtx.skipPreview}
                  onChange={() => {
                    passDownloadCtx.updateSkipPreview(
                      !passDownloadCtx.skipPreview
                    );
                  }}
                />
              </Flex>
            </Box>
          </Flex>
        </FormControl>
      </Box>
      <Box
        className="autofillForDarkBg"
        borderRadius="15px"
        overflow="hidden"
        w="full"
        mt="20px"
      >
        <Box className="primaryBoxShadow" bg={modeFormBoxBg} w="full">
          <Stack
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            direction="row"
            align="center"
          >
            <Flex direction="row" gap={1} align="center">
              <Text fontSize="18px" textStyle="headingFamilyMedium">
                Browser tab
              </Text>
            </Flex>
          </Stack>
          <Divider borderColor={modeBorderColor} />
          <FormControl bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Page title</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Page title</FormLabel>
                </Show>
                <Input
                  value={passDownloadCtx.pageTitle}
                  onChange={(e) =>
                    passDownloadCtx.updatePageTitle(e.target.value)
                  }
                  variant="filledForDarkBg"
                  id="pageTitle"
                  type="text"
                  placeholder="Type page title here..."
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider />
          <FormControl isRequired bg={modeFormControlBg}>
            <Flex>
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0} pt="18px">
                    Favicon
                  </FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Favicon</FormLabel>
                </Show>
                <FaviconImage
                  faviconImage={passDownloadCtx.faviconLogo}
                  setFaviconImage={(faviconImage) =>
                    passDownloadCtx.updateFaviconImage(faviconImage)
                  }
                  setFaviconLogo={(faviconLogo) =>
                    passDownloadCtx.updateFaviconLogo(faviconLogo)
                  }
                />
              </Box>
            </Flex>
          </FormControl>
        </Box>
      </Box>
      <Box className="autofillForDarkBg" borderRadius="15px" w="full" mt="20px">
        <Box
          className="primaryBoxShadow"
          bg={modeFormBoxBg}
          w="full"
          borderRadius="15px"
        >
          <Stack
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            direction="row"
            align="center"
            borderRadius="15px 15px 0 0"
          >
            <Flex direction="row" gap={1} align="center">
              <Text fontSize="18px" textStyle="headingFamilyMedium">
                Interactive UI components
              </Text>
              <Tooltip
                label="Includes interactive elements such as the loading spinner, buttons, and links"
                hasArrow
                placement="top"
                fontSize="12px"
                bg={bgTooltip}
                color={textTooltip}
                w="205px"
                borderRadius="6px"
                textAlign="center"
                p="10px"
              >
                <Box
                  data-testid="interactive-ui-components-tooltip-button"
                  mb={1}
                  cursor="pointer"
                >
                  <CustomExclamationMarkInverseIcon
                    boxSize="14px"
                    mode={'light'}
                  />
                </Box>
              </Tooltip>
            </Flex>
          </Stack>
          <Divider borderColor={modeBorderColor} />
          <FormControl bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Fill colour</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Fill colour</FormLabel>
                </Show>
                <CustomColorPicker
                  parent="download_page"
                  color={passDownloadCtx.componentFillColor}
                  onChange={(color) =>
                    passDownloadCtx.updateComponentFillColor(color)
                  }
                  hexError={false}
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider />
          <FormControl bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Button font colour</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Button font colour</FormLabel>
                </Show>
                <CustomColorPicker
                  parent="download_page"
                  color={passDownloadCtx.componentFontColor}
                  onChange={(color) =>
                    passDownloadCtx.updateComponentFontColor(color)
                  }
                  hexError={false}
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider />
          <FormControl
            data-testid="preview-field"
            bg={modeFormControlBg}
            borderRadius={isPreviewOpen ? '0' : '15px'}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box
                  pl="24px"
                  flexShrink={0}
                  w="240px"
                  borderRadius={isPreviewOpen ? '0' : '0 0 0 15px'}
                >
                  <FormLabel m={0}>Preview</FormLabel>
                </Box>
              </Hide>
              <Box
                p="14px"
                w="full"
                bg={modeLabelBg}
                borderRadius={
                  isPreviewOpen
                    ? '0'
                    : { base: '0 0 15px 15px', '2xl': '0 0 15px 0' }
                }
              >
                <Show below="2xl">
                  <Flex
                    data-testid="preview-button"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => setIsPreviewOpen(!isPreviewOpen)}
                    cursor="pointer"
                    pr={3}
                  >
                    <FormLabel>Preview</FormLabel>
                    {isPreviewOpen ? (
                      <ChevronUpIcon color="gray" boxSize="20px" />
                    ) : (
                      <ChevronDownIcon color="gray" boxSize="20px" />
                    )}
                  </Flex>
                </Show>
                <Hide below="2xl">
                  <Flex
                    data-testid="preview-button"
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    onClick={() => setIsPreviewOpen(!isPreviewOpen)}
                    cursor="pointer"
                    pr={3}
                    py={1}
                  >
                    {isPreviewOpen ? (
                      <ChevronUpIcon color="gray" boxSize="20px" />
                    ) : (
                      <ChevronDownIcon color="gray" boxSize="20px" />
                    )}
                  </Flex>
                </Hide>
              </Box>
            </Flex>
          </FormControl>
          <Collapse in={isPreviewOpen} animateOpacity>
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Loading spinner</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Loading spinner</FormLabel>
                  </Show>
                  <Spinner
                    data-testid="spinner"
                    width="40px"
                    height="40px"
                    speed="3s"
                    borderWidth="6px"
                    color={passDownloadCtx.componentFillColor}
                    emptyColor="gray.200"
                    ml={2}
                  />
                </Box>
              </Flex>
            </FormControl>
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Button</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Button</FormLabel>
                  </Show>
                  <Button
                    data-testid="go-to-google-wallet-button"
                    size="sm"
                    bg={passDownloadCtx.componentFillColor}
                    color={passDownloadCtx.componentFontColor}
                    _hover={{
                      bg: passDownloadCtx.componentFillColor,
                      color: passDownloadCtx.componentFontColor,
                      opacity: 0.9,
                    }}
                  >
                    Button text
                  </Button>
                </Box>
              </Flex>
            </FormControl>
            <FormControl
              bg={modeFormControlBg}
              borderRadius={{ base: '0 0 15px 15px', '2xl': '15px' }}
            >
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Link</FormLabel>
                  </Box>
                </Hide>
                <Box
                  p="14px"
                  w="full"
                  bg={modeLabelBg}
                  borderRadius={{ base: '0 0 15px 15px', '2xl': '0 0 15px 0' }}
                >
                  <Show below="2xl">
                    <FormLabel>Link</FormLabel>
                  </Show>
                  <Link
                    color={passDownloadCtx.passDownloadTextColor || '#45525B'}
                    fontSize="16px"
                    fontWeight="bold"
                    href="#"
                    position="relative"
                    _hover={{ textDecoration: 'none' }}
                    ml={3}
                  >
                    Link text
                    <Box
                      position="absolute"
                      bottom="-3px"
                      left="0"
                      w="100%"
                      h="3px"
                      bg={passDownloadCtx.componentFillColor}
                    />
                  </Link>
                </Box>
              </Flex>
            </FormControl>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default PassDownloadForm;
