import appsignal from '../appsignal';
import { useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import CustomToast from '../common/CustomToast';
import { HttpContext } from '../context/HttpContext';
import { MeContext } from '../context/MeContext';
import { AuthContext } from '../context/AuthContext';

function UseMe({ children }) {
  const { isTokenExpired, getAccessToken } = useContext(AuthContext);
  const { authAxios } = useContext(HttpContext);
  const { updateState } = useContext(MeContext);
  const toast = useToast();
  const [getDataState, setGetDataState] = useState(true);
  const isFirstMount = React.useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      if (!isTokenExpired(getAccessToken())) return; //Prevents MeContext from being updated when token is being refreshed
    }

    const getData = async () => {
      setGetDataState(true);
      try {
        const response = await authAxios.get(`accounts/me`);
        const account = response?.data?.account;
        const organisationStatus = response?.data?.organisation_status;
        const trialExpiry = response?.data?.trial_expiry;
        const watermarkEnabled = response?.data?.watermark_enabled;
        const entities = response?.data?.entities;
        const passTemplates = response?.data?.pass_templates;
        const impersonation = sessionStorage.getItem('impersonation');
        const invitations = response?.data?.invitations;
        const invitationLimit = account?.invitation_limit;
        const invitedAccount = account['invited_account?'];
        const vtapAutomaticSetupEnabled =
          response?.data?.vtap_automatic_setup_enabled;
        const passDownloadTemplateUuid =
          response?.data?.pass_download_template_uuid;
        const scheduledPassTemplateUpdatesEnabled =
          response?.data?.scheduled_pass_template_updates_enabled;
        const dynamicFormBuilderEnabled =
          response?.data?.dynamic_form_builder_enabled;
        const eventsEnabled = response?.data?.events_enabled;
        const filterEntity = response?.data?.filter_entity;
        const smsAndEmailMessagingEnabled =
          response?.data?.sms_and_email_messaging_enabled;
        const newsletterPopUpAccepted =
          response?.data?.newsletter_pop_up_accepted;

        updateState({
          account,
          organisationStatus,
          trialExpiry,
          watermarkEnabled,
          entities,
          passTemplates,
          impersonation,
          invitations,
          invitationLimit,
          invitedAccount,
          eventsEnabled,
          scheduledPassTemplateUpdatesEnabled,
          dynamicFormBuilderEnabled,
          filterEntity,
          vtapAutomaticSetupEnabled,
          status: 'resolved',
          error: null,
          passDownloadTemplateUuid,
          smsAndEmailMessagingEnabled,
          newsletterPopUpAccepted,
        });
        setGetDataState(false);
      } catch (onError) {
        appsignal.sendError(onError);
        const errors = onError.response.data.errors;
        if (errors) {
          errors.forEach((error) => {
            toast({
              render: (props) => (
                <CustomToast
                  status="error"
                  title={error}
                  description=""
                  onClose={props.onClose}
                />
              ),
            });
          });
        }
        setGetDataState(false);
        updateState({
          account: null,
          organisationStatus: null,
          trialExpiry: null,
          watermarkEnabled: null,
          entities: null,
          invitations: null,
          invitationLimit: null,
          passDownloadTemplateUuid: null,
          eventsEnabled: null,
          scheduledPassTemplateUpdatesEnabled: null,
          dynamicFormBuilderEnabled: null,
          filterEntity: null,
          status: 'rejected',
          error: 'Something went wrong',
          vtapAutomaticSetupEnabled: null,
          impersonation: null,
        });
      }
    };

    getData();
  }, [authAxios]);

  if (!getDataState) return <>{children}</>;
  return null;
}

export default UseMe;
