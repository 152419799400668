import { Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 0.9,
      ease: 'easeInOut',
    },
  },
};

export const CustomSmsIconStrokeAnimated = (props) => (
  <Icon viewBox="0 0 34 34" {...props} fill="transparent">
    <motion.path
      d="M1.38867 10.5953C1.38867 5.60515 5.31598 1.6424 9.8778 1.6424H24.1221C28.6891 1.6424 32.6112 5.54429 32.6112 10.5953V16.558C32.6112 21.5482 28.6839 25.5109 24.1221 25.5109H17.4306C11.6261 25.5109 6.08375 28.1124 1.38867 31.2204V10.5953Z"
      stroke={props.mode === 'dark' ? '#FF8477' : '#29323A'}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <motion.line
      x1="8.45081"
      y1="11.5577"
      x2="20.8142"
      y2="11.5577"
      stroke={props.mode === 'dark' ? '#fff' : '#FF8477'}
      strokeWidth="2.5"
      strokeLinecap="round"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <motion.path
      d="M8.38672 16.04H13.3019"
      stroke={props.mode === 'dark' ? '#fff' : '#FF8477'}
      strokeWidth="2.5"
      strokeLinecap="round"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
  </Icon>
);
