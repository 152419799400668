import React, { useState } from 'react';
import { Text, useColorModeValue } from '@chakra-ui/react';
import CreatableSelect from 'react-select/creatable';

export const CustomCreatableSelect = React.forwardRef((props, ref) => {
  const [inputValue, setInputValue] = useState('');

  const modePlaceholderColor = useColorModeValue(
    'rgba(59, 72, 81, 0.6)',
    props.liveEventData ? '#ddd' : '#7F8488'
  );
  const modeMenuBg = useColorModeValue('#fff', '#3B4851');
  const modeMenuColor = useColorModeValue('#29323A', '#fff');
  const modeMenuBgHover = useColorModeValue(
    'rgba(221, 221, 221, 0.5)',
    '#29323A'
  );
  const modeBorderHover = useColorModeValue('#3B4851', '#fff');

  const reactSelectGroupTagStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: props.padding || '0px 16px',
      display: 'flex',
      borderRadius: '25px',
      border: '2px solid transparent',
      boxShadow: 'none',
      ':hover': {
        borderColor: modeBorderHover,
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      backgroundColor: modeMenuBg,
      ':hover': {
        backgroundColor: modeMenuBgHover,
        color: modeMenuColor,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      zIndex: '5',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: modePlaceholderColor,
      fontSize: '14px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingRight: '0px',
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      backgroundColor: '#DDDD',
      borderRadius: '25px',
      padding: '0px 5px',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      ':hover': {
        color: '#3B4851',
      },
    }),
  };

  const handleInputChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(newValue);
    }
  };

  const formatOptionLabel = ({ label }) => {
    if (!label) {
      return <Text fontSize="14px">Create tag "{inputValue}"</Text>;
    }
    return <Text fontSize="14px">{label}</Text>;
  };

  return (
    <CreatableSelect
      isClearable
      {...props}
      ref={ref}
      styles={reactSelectGroupTagStyles}
      formatOptionLabel={formatOptionLabel}
      onInputChange={handleInputChange}
    />
  );
});
