import { ResponsivePie } from '@nivo/pie';
import { Box, Text, Image, Button } from '@chakra-ui/react';
import analytics from '../../../theme/multicolor-icons/analytics.svg';
import { Link as RouterLink } from 'react-router-dom';

const DonutChart = ({
  data,
  label,
  includeButton = false,
  noTotal = false,
  small = true,
  buttonText,
  buttonLink,
}) => {
  const totalPasses = data.reduce((sum, item) => sum + item.value, 0);

  if (!data || data.length === 0 || totalPasses === 0) {
    return (
      <Box
        p={10}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        textAlign="center"
      >
        <Image
          src={analytics}
          alt="Analytics"
          width={small ? '40px' : '50px'}
          height={small ? '40px' : '50px'}
          mb={4}
        />
        <Text fontSize="16px" fontWeight="bold">
          {small ? 'No data' : "Whoops! Looks like there's no data."}
        </Text>
        <Text fontSize="14px" mt={1}>
          Try selecting a different date range or check back later.
        </Text>
        {includeButton && (
          <Button as={RouterLink} to={buttonLink} mt={6} size="sm">
            {buttonText}
          </Button>
        )}
      </Box>
    );
  }

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 10, right: 10, bottom: 55, left: 10 }}
      innerRadius={0.8}
      padAngle={1}
      cornerRadius={4}
      activeOuterRadiusOffset={4}
      colors={{ datum: 'data.color' }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      layers={[
        'arcs',
        'arcLabels',
        'arcLinkLabels',
        'legends',
        ({ centerX, centerY }) => (
          <g transform={`translate(${centerX}, ${centerY})`}>
            {!noTotal && totalPasses > 0 && (
              <>
                <text
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fontSize: '32px',
                    fontFamily: 'Inter Bold, sans-serif',
                    fill: 'currentColor',
                    letterSpacing: '-0.5px',
                  }}
                  dy={-10}
                >
                  {totalPasses}
                </text>
                <text
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fontSize: '15px',
                    fontFamily: 'Inter Regular, sans-serif',
                    fill: 'currentColor',
                    opacity: 0.7,
                    letterSpacing: '0.5px',
                  }}
                  dy={20}
                >
                  {label}
                </text>
              </>
            )}
          </g>
        ),
      ]}
      legends={
        noTotal
          ? [
              {
                anchor: 'center', // This places the legend in the center
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemsSpacing: 8,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#333',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
                data: data.map((d) => ({
                  id: d.id,
                  label: d.id,
                  color: d.color,
                })),
              },
            ]
          : [
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 35, // First row closer to the chart
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18, // Reduced height for more compact layout
                itemTextColor: '#333',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
                // Filter to show only first two items
                data: data
                  .slice(0, 2)
                  .map((d) => ({ id: d.id, label: d.id, color: d.color })),
              },
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 55, // Second row below the first
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#333',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
                // Filter to show only last two items
                data: data
                  .slice(2, 4)
                  .map((d) => ({ id: d.id, label: d.id, color: d.color })),
              },
            ]
      }
    />
  );
};

export default DonutChart;
