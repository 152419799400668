import React, { useState, createContext, useContext } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import CustomToast from '../common/CustomToast';
import { AuthContext } from './AuthContext';

const HttpContext = createContext();
const { Provider } = HttpContext;

const HttpProvider = ({ children }) => {
  const toast = useToast();
  const authContext = useContext(AuthContext);
  const [dashboardRender, setDashboardRender] = useState({
    location: '',
    prev: '',
  });

  let isRefreshing = false; // Flag to track if refresh is happening
  let refreshQueue = []; // Queue to store pending requests

  function updateDashboardRenderHandler(param) {
    setDashboardRender(param);
  }

  const publicAxios = axios.create({
    baseURL:
      process.env.REACT_APP_API_URL_AXIOS ||
      window.env?.REACT_APP_API_URL_AXIOS,
    headers: {
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning':
        (process.env.NODE_ENV || window.env?.REACT_APP_NODE_ENV) ===
        'development',
    },
  });

  const authAxios = axios.create({
    baseURL:
      process.env.REACT_APP_API_URL_AXIOS ||
      window.env?.REACT_APP_API_URL_AXIOS,
    headers: {
      'X-Auth-Token': authContext.getAccessToken(),
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning':
        (process.env.NODE_ENV || window.env?.REACT_APP_NODE_ENV) ===
        'development',
    },
  });

  const refreshAccessToken = async () => {
    if (isRefreshing) {
      // Wait for the current refresh process to complete
      return new Promise((resolve) => {
        refreshQueue.push(resolve); // Store the request in the queue
      });
    }

    isRefreshing = true;

    try {
      const response = await authAxios.post('/accounts/refresh_token', {
        refresh_token: authContext.getRefreshToken(),
      });

      const { token, refresh_token: refreshToken } = response.data;
      if (sessionStorage.getItem('impersonation')) {
        authContext.setImpersonationTokens({ token, refreshToken });
      } else {
        authContext.setAuthTokens({ token, refreshToken });
      }

      // Resolve all requests in the queue with the new token
      refreshQueue.forEach((resolve) => resolve(token));
      refreshQueue = []; // Clear the queue

      return token;
    } catch (error) {
      console.log('Refresh Token Error', error);
      authContext.logout();
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Session Expired"
            description="You have been signed out due to inactivity, please log in again."
            onClose={props.onClose}
          />
        ),
      });
      return null;
    } finally {
      isRefreshing = false; // Reset flag
    }
  };

  authAxios.interceptors.request.use(
    async (config) => {
      if (
        config.url.includes('/refresh_token') ||
        config.url.includes('/impersonate') ||
        config.url.includes('invitation')
      ) {
        return config;
      }

      let token = authContext.getAccessToken();
      if (!token || authContext.isTokenExpired(token)) {
        token = await refreshAccessToken();
        if (!token) {
          const formattedError = {
            response: {
              data: {
                code: 401,
                message: 'Session Expired',
                errors: null,
              },
            },
          };

          return Promise.reject(formattedError);
        }
      }

      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.url.includes('/refresh_token') //Prevents infinite loop
      ) {
        originalRequest._retry = true;

        try {
          const newToken = await refreshAccessToken();
          if (newToken) {
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            return authAxios(originalRequest);
          }
        } catch (refreshError) {
          console.error('Retry failed:', refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
        dashboardRender,
        updateDashboardRender: updateDashboardRenderHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { HttpContext, HttpProvider };
