import { createContext, useState, useEffect } from 'react';

const ReadersListContext = createContext({
  readerState: {
    items: [],
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
    update: 0,
  },
  filterValues: {
    name: '',
    uuid: '',
    type: '',
  },
  clearPageIndex: 0,
  updateReaderState: () => {},
  updateFilterValues: () => {},
  updateReaderStatus: () => {},
});

export const ReadersListContextProvider = ({ children }) => {
  const [readerState, setReaderState] = useState({
    items: [],
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
    update: 0,
  });
  const [filterValues, setFilterValues] = useState({
    name: '',
    uuid: '',
    type: '',
  });
  const [clearPageIndex, setClearPageIndex] = useState(0);

  const updateReaderStateHandler = (param) => {
    setReaderState(param);
  };

  function updateFilterValuesHandler(filterValues) {
    setFilterValues(filterValues);
    setClearPageIndex(clearPageIndex + 1);
  }

  const updateReaderStatus = (uuid, loggedIn) => {
    setReaderState((prevState) => ({
      ...prevState,
      items: prevState.items.map((reader) =>
        reader.id === uuid
          ? {
              ...reader,
              attributes: { ...reader.attributes, loggedIn: loggedIn },
            }
          : reader
      ),
    }));
  };

  const context = {
    readerState,
    filterValues,
    clearPageIndex,
    updateReaderState: updateReaderStateHandler,
    updateFilterValues: updateFilterValuesHandler,
    updateReaderStatus,
  };

  return (
    <ReadersListContext.Provider value={context}>
      {children}
    </ReadersListContext.Provider>
  );
};

export default ReadersListContext;
