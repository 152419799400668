import React from 'react';
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Flex,
  Skeleton,
  useMediaQuery,
} from '@chakra-ui/react';
import DonutChart from './DonutChart';
import LineChart from './LineChart';
import BarChart from './BarChart';
import HeatMap from './HeatMap';
import { useContext } from 'react';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import TimeRangeSelector from './TimeRangeSelector';
import NoDataBox from './NoDataBox';
import ReaderIconAnimated from '../../../theme/illustrations-animated//ReaderIconAnimated';

export default function ScansStats({ getData }) {
  const [isLargerThanMd] = useMediaQuery('(min-width: 768px)');
  const dashboardCtx = useContext(ClientDashboardStatsContext);
  const { dashboardState, updateSectionTimeRange, loadingStates } =
    dashboardCtx;
  const {
    scansOverTime,
    heatmap,
    scansPerReaderType,
    scansPerStatus,
    scansPerScanType,
    topReaders,
  } = dashboardState.scanStats;
  const timeRange = dashboardState.scanStats.timeRange;
  const isLoading = loadingStates.scan || loadingStates.global;

  const SCAN_READER_TYPE_COLORS = {
    passentry: '#ec685a',
    vtap: '#2f3941',
  };

  const SCAN_STATUS_COLORS = {
    accepted: '#ec685a',
    rejected: '#2f3941',
  };

  const SCAN_TYPE_COLORS = {
    nfc: '#2f3941',
    barcode: '#ec685a',
  };

  const formattedTopReaders = Object.entries(topReaders)
    .map(([reader, scans]) => ({
      reader,
      scans,
    }))
    .sort((a, b) => b.scans - a.scans);

  const formatDonutDataReaderType = (scansPerReaderType) => {
    return Object.entries(scansPerReaderType).map(([readerType, value]) => ({
      id: readerType === 'passentry' ? 'PassEntry' : 'VTAP',
      label: readerType === 'passentry' ? 'PassEntry' : 'VTAP',
      value,
      color: SCAN_READER_TYPE_COLORS[readerType],
    }));
  };

  const formatDonutDataStatus = (scansPerStatus) => {
    return Object.entries(scansPerStatus).map(([status, value]) => ({
      id: status.charAt(0).toUpperCase() + status.slice(1),
      label: status.charAt(0).toUpperCase() + status.slice(1),
      value,
      color: SCAN_STATUS_COLORS[status],
    }));
  };

  const formatDonutDataScanType = (scansPerScanType) => {
    return Object.entries(scansPerScanType).map(([scanType, value]) => ({
      id: scanType === 'nfc' ? 'NFC' : 'Barcode',
      label: scanType === 'nfc' ? 'NFC' : 'Barcode',
      value,
      color: SCAN_TYPE_COLORS[scanType],
    }));
  };

  const handleRangeChange = (newRange) => {
    updateSectionTimeRange('scan', newRange);
    getData('scan', newRange);
  };

  return (
    <Box bg="white" borderRadius="15px" px={8} py={5} maxW="100%" w="100%">
      <Flex
        justify="space-between"
        align={{ base: 'flex-start', md: 'center' }}
        mb={8}
        flexDir={{ base: 'column', md: 'row' }}
        gap={3}
        w="100%"
      >
        <Heading>Readers & Scans</Heading>
        <TimeRangeSelector
          selectedRange={timeRange}
          onRangeChange={handleRangeChange}
        />
      </Flex>
      {dashboardState.scanStats.totalScans === 0 && !isLoading ? (
        <NoDataBox
          icon={<ReaderIconAnimated width="105px" height="92px" />}
          buttonText="Set up readers"
          buttonLink="/readers"
        />
      ) : (
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          gap={8}
        >
          <GridItem>
            <Skeleton isLoaded={!isLoading} fadeDuration={1}>
              <Box height="350px" mb={{ base: 4, xl: 0 }}>
                <Heading as="h4" fontSize="16px" mb={4}>
                  Scan Volume Trends
                </Heading>
                <LineChart
                  data={scansOverTime}
                  label="Scans"
                  timeRange={timeRange}
                  tickRotation={isLargerThanMd ? 0 : 45}
                />
              </Box>
            </Skeleton>
          </GridItem>
          <GridItem>
            <Skeleton isLoaded={!isLoading} fadeDuration={1}>
              <Box height="400px" width="100%">
                <Heading as="h4" fontSize="16px" mb={4}>
                  Scan Activity Heatmap
                </Heading>
                <HeatMap data={heatmap} />
              </Box>
            </Skeleton>
          </GridItem>
          <GridItem colSpan={{ base: 1, xl: 2 }}>
            <Grid
              templateColumns={{
                base: 'repeat(1, 1fr)',
                xl: 'repeat(5, 1fr)',
                '2xl': 'repeat(2, 1fr)',
              }}
              gap={8}
              alignItems="center"
            >
              <GridItem colSpan={{ base: 1, xl: 3, '2xl': 1 }}>
                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(3, 1fr)',
                  }}
                  gap={{ base: 2, md: 8 }}
                >
                  <Skeleton isLoaded={!isLoading} fadeDuration={1}>
                    <Box
                      height="300px"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Heading
                        as="h4"
                        fontSize="16px"
                        mb={4}
                        textAlign="center"
                      >
                        Scans by Reader Type
                      </Heading>
                      <DonutChart
                        data={formatDonutDataReaderType(scansPerReaderType)}
                        label="Scans per Reader Type"
                        noTotal={true}
                      />
                    </Box>
                  </Skeleton>
                  <Skeleton isLoaded={!isLoading} fadeDuration={1}>
                    <Box
                      height="300px"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Heading
                        as="h4"
                        fontSize="16px"
                        mb={4}
                        textAlign="center"
                      >
                        Scans by Status
                      </Heading>
                      <DonutChart
                        data={formatDonutDataStatus(scansPerStatus)}
                        label="Accepted Scans"
                        noTotal={true}
                      />
                    </Box>
                  </Skeleton>
                  <Skeleton isLoaded={!isLoading} fadeDuration={1}>
                    <Box
                      height="300px"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Heading
                        as="h4"
                        fontSize="16px"
                        mb={4}
                        textAlign="center"
                      >
                        Scans by Type
                      </Heading>
                      <DonutChart
                        data={formatDonutDataScanType(scansPerScanType)}
                        label="NFC/Barcode Scans"
                        noTotal={true}
                      />
                    </Box>
                  </Skeleton>
                </Grid>
              </GridItem>
              <GridItem colSpan={{ base: 1, xl: 2, '2xl': 1 }}>
                <Skeleton isLoaded={!isLoading} fadeDuration={1}>
                  <Box
                    height="300px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Heading as="h4" fontSize="16px" mb={4}>
                      Top readers
                    </Heading>
                    <BarChart data={formattedTopReaders} label="Top Readers" />
                  </Box>
                </Skeleton>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
}
