import { Box, Text } from '@chakra-ui/react';

const CustomBanner = (props) => {
  const { bgColour, height, sticky, padding, textColour, text } = props;
  return (
    <Box
      bg={bgColour}
      h={height ? height : 'auto'}
      width="100%"
      position={sticky ? 'sticky' : ''}
      top={sticky ? '0' : ''}
      padding={padding ? padding : ''}
      zIndex="10"
    >
      <Text color={textColour} textAlign="center" fontSize="14px">
        {text}
      </Text>
    </Box>
  );
};

export default CustomBanner;
