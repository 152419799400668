import appsignal from '../appsignal';
import { Container, Flex, useColorModeValue, useToast } from '@chakra-ui/react';
import CustomBanner from '../components/common/CustomBanner';
import Footer from './Footer';
import ScrollToTopForRouter from '../components/common/ScrollToTopForRouter';
import Sidebar from '../pages/client/Sidebar';
import TopRightAccountCallToActions from '../pages/client/TopRightAccountCallToActions';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import { HttpContext } from '../context/HttpContext';
import { AuthContext } from '../context/AuthContext';
import { MeContext } from '../context/MeContext';
import CustomToast from './CustomToast';
const LayoutDashboard = (props) => {
  const { publicAxios } = useContext(HttpContext);
  const { authState, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();
  const meCtx = useContext(MeContext);
  const impersonationText = meCtx.state.impersonation
    ? `⚠️ IMPERSONATION MODE: You are impersonating ${meCtx.state.account.email}. Be mindful of any changes ⚠️`
    : '';

  const handleClick = async () => {
    try {
      const response = await publicAxios.delete(`/accounts/sign_out`, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      if (response.status === 200) {
        logout();
        navigate('/login', { replace: true });
        toast({
          render: (props) => (
            <CustomToast
              status="success"
              title={response.data.message}
              description=""
              onClose={props.onClose}
            />
          ),
        });
      }
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Something went wrong"
            description="Please try again later"
            onClose={props.onClose}
          />
        ),
      });
    }
  };

  return (
    <>
      <ScrollToTopForRouter />
      {meCtx.state.impersonation && (
        <CustomBanner
          text={impersonationText}
          textColour={'white'}
          bgColour={'red'}
          padding={'3px'}
          sticky={true}
        />
      )}
      <Sidebar handleClick={handleClick} />
      <TopRightAccountCallToActions
        publicPage={props.publicPage}
        handleClick={handleClick}
      />
      <Flex
        direction="column"
        minH="100vh"
        pl={
          props.publicPage ? { base: 0, lg: '250px' } : { base: 0, lg: '266px' }
        }
        pr={props.publicPage ? { base: 0, lg: 0 } : { base: 0, lg: 6 }}
        bg={useColorModeValue('#E8E7E7', 'primaryDark')}
      >
        <Container variant="bodyContent" flexDirection="column" w="full">
          {props.children}
        </Container>
        {props.publicPage ? (
          <Footer fullWidth public footerColorMode="dark" />
        ) : (
          <Footer fullWidth />
        )}
      </Flex>
    </>
  );
};

export default LayoutDashboard;
