import { Link } from 'react-router-dom';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';
import {
  Box,
  Text,
  HStack,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';

const NoDataBatchItem = ({ text, subtext, showButton = true }) => {
  const modeBoxBgDark = useColorModeValue('gradientDark', 'gradientBrand');

  return (
    <Box py={4} px={6} bg={modeBoxBgDark} borderRadius="10px" color="white">
      <HStack justify="space-between">
        <Box>
          <Text fontWeight="600">{text}</Text>
          <Text fontSize="sm">{subtext}</Text>
        </Box>
        {showButton && (
          <IconButton
            as={Link}
            to="/messaging/create"
            icon={<CustomPlusIcon />}
            variant="white"
            size="sm"
            w="36px"
            mr={1}
          />
        )}
      </HStack>
    </Box>
  );
};

export default NoDataBatchItem;
