import {
  IconButton,
  useColorModeValue,
  Flex,
  Link,
  Text,
  Badge,
  Divider,
  Grid,
  GridItem,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import InfoDrawer from '../../common/InfoDrawer';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import { CustomSendIcon } from '../../../theme/icons/CustomSendIcon';

const InvitationMoreInfo = ({
  invitation,
  handleResendInvitation,
  getEntityName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const bgColors = {
    modeBoxBg,
    modeTextBg,
    modeBorderColor,
    modeLabelBg,
  };

  const handleMoreInfoClick = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      <IconButton
        aria-label="More Info"
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <InfoDrawer
        isOpen={isOpen}
        onClose={onClose}
        header="Invitation details"
        footer={
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
          </Flex>
        }
        bgColors={bgColors}
      >
        <Grid
          templateColumns={{
            base: '150px 1fr',
            md: '192px 1fr',
          }}
          gap={0}
        >
          <GridItem
            fontFamily="Inter Medium, sans-serif"
            fontSize="12px"
            color={modeIconColor}
            textTransform="uppercase"
            lineHeight="21px"
            padding="16px 4px 16px 22px"
          >
            Email
          </GridItem>
          <GridItem
            fontFamily="Inter Bold, sans-serif"
            fontSize="14px"
            padding="16px 10px 16px 22px"
            bg="quinaryBackground"
          >
            <Text wordBreak="break-word">{invitation.email}</Text>
          </GridItem>
          <GridItem colSpan={2} w="full">
            <Divider borderColor={modeBorderColor} />
          </GridItem>
          <GridItem
            fontFamily="Inter Medium, sans-serif"
            fontSize="12px"
            color={modeIconColor}
            textTransform="uppercase"
            lineHeight="21px"
            padding="16px 4px 16px 22px"
          >
            Status
          </GridItem>
          <GridItem
            fontFamily="Inter Bold, sans-serif"
            fontSize="14px"
            padding="16px 10px 16px 22px"
            bg="quinaryBackground"
          >
            <Badge
              alignContent="center"
              align="center"
              display="inline"
              variant={invitation.email_verified ? 'active' : 'pending'}
            >
              {invitation.email_verified ? 'Accepted' : 'Pending'}
            </Badge>
          </GridItem>
          <GridItem colSpan={2} w="full">
            <Divider borderColor={modeBorderColor} />
          </GridItem>
          <GridItem
            fontFamily="Inter Medium, sans-serif"
            fontSize="12px"
            color={modeIconColor}
            textTransform="uppercase"
            lineHeight="21px"
            padding="16px 4px 16px 22px"
          >
            Role
          </GridItem>
          <GridItem
            fontFamily="Inter Bold, sans-serif"
            fontSize="14px"
            padding="16px 10px 16px 22px"
            bg="quinaryBackground"
          >
            {invitation.role === 'org_admin'
              ? 'Organisation Admin'
              : `Entity Admin`}
            {invitation.role === 'ent_admin' &&
              invitation.entity_id &&
              ` (${getEntityName(invitation.entity_id)})`}{' '}
          </GridItem>
          <GridItem colSpan={2} w="full">
            <Divider borderColor={modeBorderColor} />
          </GridItem>
          {!invitation.email_verified && (
            <>
              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Resend invite
              </GridItem>
              <GridItem
                fontFamily="Inter Bold, sans-serif"
                fontSize="14px"
                padding="16px 10px 16px 22px"
                bg="quinaryBackground"
              >
                <Button
                  variant="link"
                  pt="1px"
                  size="sm"
                  onClick={() =>
                    handleResendInvitation(
                      invitation.email,
                      invitation.role,
                      invitation.entity_id,
                      invitation.company_name
                    )
                  }
                >
                  <Flex justifyContent="center" align="center">
                    <CustomSendIcon />
                    <Text textStyle="bodyFamilyMedium" pl="7px">
                      Resend
                    </Text>
                  </Flex>
                </Button>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>
            </>
          )}
        </Grid>
      </InfoDrawer>
    </>
  );
};

export default InvitationMoreInfo;
