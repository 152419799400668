import { ResponsiveBar } from '@nivo/bar';

const BarChart = ({ data, label }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={['scans']}
      indexBy="reader"
      margin={{ top: 20, right: 20, bottom: 50, left: 150 }}
      padding={0.3}
      layout="horizontal"
      borderRadius={4}
      colors={({ index }) => {
        const colors = ['#ec685a', '#ef8276', '#f29c93', '#f5b5af', '#f8cfcb'];
        return colors[index];
      }}
      axisBottom={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: (value) => {
          return value.length > 15 ? value.substring(0, 20) + '...' : value;
        },
      }}
      tooltip={({ indexValue, value }) => (
        <div
          style={{
            background: 'white',
            padding: '9px 12px',
            borderRadius: '5px',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            fontSize: '14px',
          }}
        >
          <strong>{indexValue}</strong>
          <div>{value} scans</div>
        </div>
      )}
    />
  );
};

export default BarChart;
