import { ResponsiveLine } from '@nivo/line';

const LineChart = ({ data, label, timeRange, tickRotation = 0 }) => {
  const precision =
    timeRange === 'all' || timeRange === '6months' ? 'month' : 'day';

  const getTimeRangeData = () => {
    if (!data) return [{ id: 'Passes Issued', data: [] }];

    // Generate complete date range
    const today = new Date();
    let startDate = new Date();

    switch (timeRange) {
      case '7days':
        startDate.setDate(today.getDate() - 7);
        break;
      case '1month':
        startDate.setMonth(today.getMonth() - 1);
        break;
      case '6months':
        startDate.setMonth(today.getMonth() - 5);
        startDate.setDate(1);
        break;
      case 'all':
        if (Object.keys(data).length > 0) {
          const dates = Object.keys(data).sort(
            (a, b) => new Date(a) - new Date(b)
          );
          startDate = new Date(dates[0]);
        } else {
          startDate.setFullYear(today.getFullYear() - 1);
        }
        break;
      default:
        startDate.setDate(today.getDate() - 6);
    }

    // Ensure dates are at start of day for consistent comparison
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(today);
    endDate.setHours(23, 59, 59, 999);

    // Create array of all dates in range
    const dateRange = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      let dateStr = currentDate.toISOString().split('T')[0];
      if (precision === 'month') {
        dateStr = `${dateStr.substring(0, 7)}-01`;
      }
      dateRange.push({
        x: dateStr,
        y: data[dateStr] || 0,
      });

      if (
        precision === 'month' &&
        (timeRange === '6months' || timeRange === 'all')
      ) {
        currentDate.setMonth(currentDate.getMonth() + 1);
      } else {
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    return [
      {
        id: 'Passes Issued',
        data: dateRange,
      },
    ];
  };

  const formatNumber = (value) => {
    const roundedValue = Math.round(value);
    if (roundedValue >= 1000) {
      return `${Math.floor(roundedValue / 1000)}K`;
    }
    return roundedValue;
  };

  const getTickValues = () => {
    if (!data || Object.keys(data).length === 0) return 'every day';

    const dates = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[dates.length - 1]);
    let monthsDiff;
    let monthsInterval;
    switch (timeRange) {
      case '7days':
        return 'every day';
      case '1month':
        return 'every 4 days';
      case '6months':
        return 'every month';
      case 'all':
        monthsDiff =
          (endDate.getFullYear() - startDate.getFullYear()) * 12 +
          (endDate.getMonth() - startDate.getMonth());
        // Calculate how many months to skip to get ~6-7 ticks
        monthsInterval = Math.max(Math.ceil(monthsDiff / 6), 1);
        return `every ${monthsInterval} months`;
      default:
        return 'every day';
    }
  };

  return (
    <ResponsiveLine
      key={timeRange}
      data={getTimeRangeData()}
      margin={{ top: 20, right: 40, bottom: 50, left: 65 }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        precision: precision,
      }}
      xFormat={`time:${precision === 'month' ? '%b %Y' : '%b %d'}`}
      tooltip={({ point }) => (
        <div
          style={{
            background: 'white',
            padding: '9px 12px',
            borderRadius: '5px',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            fontSize: '14px',
          }}
        >
          <strong>{point.data.xFormatted}</strong>
          <div>{point.data.y}</div>
        </div>
      )}
      axisBottom={{
        format: precision === 'month' ? '%b %Y' : '%b %d',
        tickValues: getTickValues(),
        tickRotation: tickRotation,
      }}
      yScale={{
        type: 'linear',
        min: '0',
        max: 'auto',
        round: true,
        stacked: false,
      }}
      yFormat={(value) => formatNumber(value)}
      axisLeft={{
        tickPadding: 0,
        tickRotation: 0,
        legend: label,
        legendOffset: -50,
        legendPosition: 'middle',
        legendFontSize: 20,
        format: formatNumber,
      }}
      theme={{
        axis: {
          legend: {
            text: {
              fontSize: 18,
              fontWeight: 600,
            },
          },
        },
      }}
      pointSize={8}
      pointColor={{ theme: 'background' }}
      colors={['#ec685a']}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      enablePointLabel={true}
      enableArea={true}
      areaOpacity={0.1}
      curve="monotoneX"
      useMesh={true}
      enableGridX={false}
      enableGridY={false}
    />
  );
};

export default LineChart;
