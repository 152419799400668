import appsignal from '../../../appsignal';
import {
  useMemo,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import ReadersListTable from './ReadersListTable';
import ReadersListContext from '../../../store/client/ReadersListContext';
import CustomToast from '../../../common/CustomToast';
import { useToast } from '@chakra-ui/react';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';

const ReadersList = () => {
  const { authAxios } = useContext(HttpContext);
  const readerListCtx = useContext(ReadersListContext);
  const { readerState, updateReaderState } = useContext(ReadersListContext);
  const [query, setQuery] = useState('');
  const toast = useToast();
  const fetchIdRef = useRef(0);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const { vtapAutomaticSetupEnabled } = meCtx.state;

  const { items, total, pageCount, loading } = readerState;

  useEffect(() => {
    const queryName =
      readerListCtx.filterValues.name !== ''
        ? '&name=' + readerListCtx.filterValues.name
        : '';
    const queryUuid =
      readerListCtx.filterValues.uuid !== ''
        ? '&uuid=' + readerListCtx.filterValues.uuid
        : '';
    const queryType =
      readerListCtx.filterValues.type !== ''
        ? '&type=' + readerListCtx.filterValues.type
        : '';
    const query = `${queryName}${queryUuid}${queryType}`;
    setQuery(query);
  }, [readerListCtx.filterValues]);

  const columns = useMemo(
    () => [
      {
        Header: 'Reader name',
        accessor: 'name',
        className: 'modeTdColor',
        style: {
          fontFamily: 'Inter Bold, sans-serif',
          fontSize: '16px',
          wordBreak: 'break-word',
          minWidth: '150px',
          width: '250px',
        },
      },
      {
        Header: 'Reader id',
        accessor: 'uuid',
        style: {
          minWidth: '100px',
          width: '200px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Reader Type',
        accessor: 'type',
        style: {
          minWidth: '100px',
          width: '200px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: '',
        accessor: 'actionFirst',
        style: {
          width: '50px',
        },
      },
      {
        Header: '',
        accessor: 'moreInfo',
        style: {
          width: '24px',
          padding: 0,
        },
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      updateReaderState({
        ...readerState,
        loading: true,
        status: 'pending',
      });

      setTimeout(async () => {
        if (fetchId === fetchIdRef.current) {
          try {
            let response;
            if (filterEntity) {
              response = await authAxios.get(
                `api/v1/entities/${filterEntity.uuid}/readers?per_page=${pageSize}&page=${
                  pageIndex + 1
                }${query}`
              );
            } else {
              response = await authAxios.get(
                `api/v1/readers?per_page=${pageSize}&page=${pageIndex + 1}${query}`
              );
            }
            const readers = response?.data?.data || [];
            const total = response?.data?.meta?.totalCount ?? 0;

            updateReaderState({
              ...readerState,
              items: readers,
              total,
              pageCount: Math.ceil(total / pageSize),
              loading: false,
              status: 'resolved',
            });
          } catch (error) {
            appsignal.sendError(error);
            console.log(error);
            updateReaderState({
              ...readerState,
              items: [],
              total: 0,
              pageCount: 0,
              loading: false,
              error: 'Something went wrong',
              status: 'rejected',
            });
            const { message, code } = getErrorResponsePayload(error);
            code !== 401 &&
              toast({
                render: (props) => (
                  <CustomToast
                    status="error"
                    title={message ? message : `Something went wrong`}
                    description={!message && 'Please try again later'}
                    onClose={props.onClose}
                  />
                ),
              });
          }
        }
      }, 0);
    },

    [authAxios, query, filterEntity]
  );

  const normalizedData = (data) => {
    if (data?.length > 0) {
      return data.map((item) => {
        return {
          ...item,
          uuid: item.id,
          name: item.attributes.name,
          lastSeenAt: item.attributes.lastSeenAt,
          type: item.attributes.readerType,
          setupStatus: item.attributes.vtapReaderSetup,
          location: item.attributes.vtapLocation,
          wifiSSID: item.attributes.vtapSSID,
          wifiPassword: item.attributes.vtapPassword,
          exampleForNestedObject: {
            readerSinglePageUrl: '/readers/' + item.id,
          },
        };
      });
    }
    return [];
  };

  const formattedData = useMemo(() => normalizedData(items), [items]);

  return (
    <ReadersListTable
      columns={columns}
      data={formattedData}
      query={query}
      fetchData={fetchData}
      isLoading={loading}
      pageCount={pageCount}
      total={total}
      filterEntity={filterEntity}
      vtapAutomaticSetupEnabled={vtapAutomaticSetupEnabled}
    />
  );
};

export default ReadersList;
