import { Box, Text, Image, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const NoDataBox = ({
  icon,
  buttonText,
  buttonLink,
  opacity = 1,
  buttonType = 'gradient',
  title = "Whoops! Looks like there's no data.",
  description = 'Try selecting a different date range or check back later.',
}) => {
  return (
    <Box
      px={10}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      opacity={opacity}
      pb={4}
    >
      {icon}
      <Text fontSize="16px" fontWeight="bold" mt={4}>
        {title}
      </Text>
      <Text fontSize="14px" mt={1}>
        {description}
      </Text>
      <Button
        as={RouterLink}
        to={buttonLink}
        mt={6}
        size="sm"
        variant={buttonType}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default NoDataBox;
