import {
  Box,
  Text,
  HStack,
  useColorModeValue,
  Flex,
  Circle,
  Center,
  Image,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { useContext, useRef } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import { ICON_BASE64 } from '../../../utils/base64';
import CopyIcon from '../../../theme/icons/copy.svg';
import CustomToast from '../../../common/CustomToast';
import { ChevronDownIcon } from '@chakra-ui/icons';

const BrowserSimulator = ({ url, children, isMobile }) => {
  const headerBg = useColorModeValue('gray.200', 'gray.700');
  const simulatorBg = useColorModeValue('gray.100', 'gray.800');
  const addressBarBg = useColorModeValue('white', 'gray.600');
  const toast = useToast();

  const passTemplateCtx = useContext(PassTemplateContext);
  const {
    formFields,
    backgroundColor,
    componentFillColor,
    componentFontColor,
    buttonText,
    buttonTextColor,
    buttonColor,
  } = passTemplateCtx;

  const iconButtonRef = useRef();

  return (
    <Box
      w={isMobile ? '300px' : '100%'}
      borderRadius="lg"
      boxShadow="lg"
      overflow="hidden"
      bg={simulatorBg}
      transition="width 0.2s ease-in-out"
    >
      <Flex
        margin={isMobile && '0px'}
        align="center"
        justify="space-between"
        p={3}
        bg={headerBg}
      >
        <HStack pr={2} spacing={2} display={isMobile ? 'none' : 'flex'}>
          <Circle size="12px" bg="#ff5f56" />
          <Circle size="12px" bg="#ffbd2e" />
          <Circle size="12px" bg="#27c93f" />
        </HStack>
        <Box
          flex={1}
          bg={addressBarBg}
          borderRadius="md"
          fontSize="sm"
          border="1px solid"
          borderColor="gray.300"
          mx={isMobile ? 0 : 1}
          maxWidth={'100%'}
        >
          <Flex align="center" justify="center" p={1}>
            <Image
              h="15px"
              src={passTemplateCtx.formFaviconImage || ICON_BASE64}
              alt="favicon-image"
              mr={1}
              borderRadius="4px"
            />
            <Text maxWidth={'80%'}>{url}</Text>
            <IconButton
              ref={iconButtonRef}
              icon={<Image src={CopyIcon} alt="copy-icon" h="16px" />}
              size="xs"
              aria-label="Copy URL"
              onClick={() => {
                navigator.clipboard.writeText(url);
                toast({
                  render: (props) => (
                    <CustomToast
                      status="success"
                      title="URL copied to clipboard"
                      description=""
                      onClose={props.onClose}
                      position="top"
                      duration={2000}
                      id={props.id}
                    />
                  ),
                });
              }}
              ml={2}
              variant="ghost"
              color={headerBg}
            />
          </Flex>
        </Box>
      </Flex>
      <Box bg={backgroundColor} p={4}>
        {children || (
          <Center height="100%">
            <Flex
              bg={componentFillColor}
              w={'265px'}
              p={4}
              borderRadius={'10px'}
              flexDirection={'column'}
              gap={4}
              margin="48px 0 48px 0"
              boxShadow="lg"
            >
              <Image
                src={passTemplateCtx.formLogoImage}
                alt="form-logo"
                borderRadius={'4px'}
              />
              {formFields?.length > 0 &&
                formFields.map((field, index) => (
                  <Flex key={index} flexDirection={'column'} gap={2}>
                    <Flex gap={'2px'}>
                      <Text
                        color={componentFontColor}
                        textAlign="left"
                        fontSize={'12px'}
                      >
                        {field.label}
                      </Text>
                      {field.required && (
                        <Text color={'red'} textAlign="left" fontSize={'12px'}>
                          *
                        </Text>
                      )}
                    </Flex>
                    <Flex
                      bg="white"
                      height="30px"
                      borderRadius={'8px'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      border="1px solid"
                      borderColor="gray.300"
                    >
                      {field.type === 'dropdown' && (
                        <>
                          <Text pl={'8px'} fontSize={'12px'}>
                            {field.placeholder || 'Select an option...'}
                          </Text>
                          <Box
                            p={'0 8px'}
                            borderLeft="1px solid"
                            borderColor="gray.300"
                          >
                            <ChevronDownIcon color="black" />
                          </Box>
                        </>
                      )}
                    </Flex>
                  </Flex>
                ))}
              <Box display="flex" justifyContent="center">
                <Box
                  bg={buttonColor}
                  p={'4px 16px'}
                  borderRadius={'8px'}
                  textAlign="center"
                  display="inline-block"
                >
                  <Text
                    color={buttonTextColor}
                    textAlign="center"
                    fontSize={'12px'}
                    whiteSpace="nowrap"
                  >
                    {buttonText}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export default function PreviewDynamicPassForm({ isMobile }) {
  const passTemplateCtx = useContext(PassTemplateContext);
  const { slug } = passTemplateCtx;

  return (
    <Box>
      <BrowserSimulator
        url={`https://form.passentry.com/${slug || ''}`}
        // url={`${process.env.REACT_APP_FORMS_BASE_URL || window.env.REACT_APP_FORMS_BASE_URL}/${slug || ''}`}
        isMobile={isMobile}
      />
    </Box>
  );
}
