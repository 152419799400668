import React, { useContext, useState } from 'react';
import PassTemplateContext from '../../store/client/PassTemplateContext';
import CropModal from '../modals/CropModal';
import CustomDropzone from '../common/CustomDropzone';
import { useDisclosure } from '@chakra-ui/react';

function LogoImage({
  image,
  setImage,
  setLogo,
  setEditedImages = null,
  editedImages = null,
  parent = null,
}) {
  const [droppedImage, setDroppedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
    setImage({
      ...image,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 360 / 110,
    });
  }

  function handleSaveDroppedImage(base64DroppedFile) {
    setLogo(base64DroppedFile);
    setImage({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
    if (setEditedImages) {
      setEditedImages({ ...editedImages, logo_image: true });
    }
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    setImage(image);
  }

  return (
    <>
      <CropModal
        key={`${parent}_logo_image_crop_modal`}
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        image={image}
        updateImage={updateImage}
        parent={parent ? `${parent}_logo_image` : 'logo_image'}
        title="Crop logo image"
        droppedImage={droppedImage}
        saveDroppedImage={handleSaveDroppedImage}
      />
      <CustomDropzone
        key="logo_image_drop_zone"
        image={image}
        isImageAdded={isImageAdded}
        parent={parent ? `${parent}_logo_image` : 'logo_image'}
        explanation="Recommended size: 360x110 pixels. Recommended file type is png with transparent background."
        accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
        droppedImage={droppedImage}
        updateDroppedImage={handleUpdateDroppedImage}
      />
    </>
  );
}

export default LogoImage;
