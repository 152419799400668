import React from 'react';
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Flex,
  Skeleton,
  useMediaQuery,
} from '@chakra-ui/react';
import DonutChart from './DonutChart';
import LineChart from './LineChart';
import { useContext } from 'react';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import TimeRangeSelector from './TimeRangeSelector';
import NoDataBox from './NoDataBox';
import EmptyDefaultSvgAnimated from '../../../theme/illustrations-animated/EmptyDefaultSvgAnimated';

export default function PassesStats({ getData }) {
  const [isLargerThanMd] = useMediaQuery('(min-width: 768px)');
  const dashboardCtx = useContext(ClientDashboardStatsContext);
  const { dashboardState, updateSectionTimeRange, loadingStates } =
    dashboardCtx;
  const timeRange = dashboardState.passStats.timeRange;
  const isLoading = loadingStates.pass || loadingStates.global;

  const PASS_STATUS_COLORS = {
    issued: '#8E9BA3',
    active: '#ec685a',
    deactivated: '#2f3941',
    removed: '#fbafa7',
  };

  const PASS_DEVICE_COLORS = {
    apple: '#2f3941',
    google: '#ec685a',
  };

  const formatDonutDataStatus = (passesByStatus) => {
    return Object.entries(passesByStatus).map(([status, value]) => ({
      id: status.charAt(0).toUpperCase() + status.slice(1),
      label: status.charAt(0).toUpperCase() + status.slice(1),
      value,
      color: PASS_STATUS_COLORS[status],
    }));
  };

  const formatDonutDataDevice = (passesByDevice) => {
    return Object.entries(passesByDevice).map(([device, value]) => ({
      id: device.charAt(0).toUpperCase() + device.slice(1),
      label: device.charAt(0).toUpperCase() + device.slice(1),
      value,
      color: PASS_DEVICE_COLORS[device],
    }));
  };

  const handleRangeChange = (newRange) => {
    updateSectionTimeRange('pass', newRange);
    getData('pass', newRange);
  };

  return (
    <Box
      bg="white"
      borderRadius="15px"
      px={8}
      pt={5}
      pb={10}
      maxW="100%"
      w="100%"
    >
      <Flex
        justify="space-between"
        align={{ base: 'flex-start', md: 'center' }}
        mb={8}
        flexDir={{ base: 'column', md: 'row' }}
        gap={3}
        w="100%"
      >
        <Heading>Passes</Heading>
        <TimeRangeSelector
          selectedRange={timeRange}
          onRangeChange={handleRangeChange}
        />
      </Flex>
      {dashboardState.passStats.totalPasses === 0 && !isLoading ? (
        <NoDataBox
          icon={<EmptyDefaultSvgAnimated width="102px" height="92px" />}
          buttonText="Issue Passes"
          buttonLink="/passes"
        />
      ) : (
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            xl: 'repeat(4, 1fr)',
          }}
          gap={8}
        >
          <Skeleton isLoaded={!isLoading} fadeDuration={1}>
            <Box height="300px">
              <DonutChart
                data={formatDonutDataStatus(
                  dashboardState.passStats.passesByStatus
                )}
                label="Total Passes"
              />
            </Box>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} fadeDuration={1}>
            <Box height="300px">
              <DonutChart
                data={formatDonutDataDevice(
                  dashboardState.passStats.passesByDevice
                )}
                label="Active Passes"
              />
            </Box>
          </Skeleton>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Skeleton isLoaded={!isLoading} fadeDuration={1}>
              <Box height="300px">
                <LineChart
                  data={dashboardState.passStats.issuedOverTime}
                  label="Passes Issued"
                  timeRange={timeRange}
                  tickRotation={isLargerThanMd ? 0 : 45}
                />
              </Box>
            </Skeleton>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
}
