import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Hide,
  Show,
  Text,
  useColorModeValue,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import moment from 'moment';
import { useContext, useState } from 'react';
import ReadersContext from '../../../store/client/ReadersContext';
import ReadersRenderStatus from './ReadersRenderStatus';
import ReaderField from './ReaderField';
import MessageStatus from '../messages/MessageStatus';
import LogoutReaderModal from '../../modals/LogoutReaderModal';
import { CustomVisibilityOffIcon } from '../../../theme/multicolor-icons/CustomVisibilityOffIcon';
import { CustomVisibilityIcon } from '../../../theme/multicolor-icons/CustomVisibilityIcon';
import { MeContext } from '../../../context/MeContext';

const ReadersFieldsView = () => {
  const readersCtx = useContext(ReadersContext);
  const meCtx = useContext(MeContext);
  const { vtapAutomaticSetupEnabled } = meCtx.state;
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('#fff', 'secondaryDark');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const [showApiKey, setShowApiKey] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    isOpen: isLogoutReaderOpen,
    onOpen: onLogoutReaderOpen,
    onClose: onLogoutReaderClose,
  } = useDisclosure();

  let readerFields = [
    { name: 'Reader name', value: readersCtx.readerState?.name },
    { name: 'Reader ID', value: readersCtx.readerState?.uuid },
    { name: 'Reader type', value: readersCtx.readerState?.type },
  ];

  let passentryReaderFields = [
    {
      name: 'Scanning',
      value: readersCtx.readerState?.scanEnabled ? 'Enabled' : 'Disabled',
    },
    {
      name: 'Redeeming',
      value: readersCtx.readerState?.redeemEnabled ? 'Enabled' : 'Disabled',
    },
    {
      name: 'Adding Points',
      value: readersCtx.readerState?.addPointsEnabled ? 'Enabled' : 'Disabled',
    },
    {
      name: 'QR scanning',
      value: readersCtx.readerState?.qrEnabled ? 'Enabled' : 'Disabled',
    },
    // {
    //   name: 'Mifare Classic NFC',
    //   value: readersCtx.readerState?.mifareClassicNfcEnabled
    //     ? 'Enabled'
    //     : 'Disabled',
    // },
    {
      name: 'NDEF Tag Scanning',
      value: readersCtx.readerState?.ndefTagScanningEnabled
        ? 'Enabled'
        : 'Disabled',
    },
  ];

  const apiKey = showApiKey ? readersCtx.readerState?.vtapApiKey : '••••••••';
  const password = showPassword
    ? readersCtx.readerState?.vtapPassword
    : '••••••••';

  const setupStatus = readersCtx.readerState?.setupStatus;

  let vtapReaderFields = [
    {
      name: 'Vtap Reader Setup',
      value: <MessageStatus status={setupStatus} type="reader_setup" />,
    },
    {
      name: 'Vtap Automatic Setup',
      value: readersCtx.readerState?.vtapAutomaticSetup
        ? 'Enabled'
        : 'Disabled',
    },
    ...(readersCtx.readerState?.vtapAutomaticSetup
      ? [
          {
            name: 'Vtap Location',
            value: readersCtx.readerState?.vtapLocation,
          },
          {
            name: 'Wifi SSID',
            value: readersCtx.readerState?.vtapSSID,
          },
          {
            name: 'Wifi Password',
            value: (
              <Flex alignItems="center">
                {showPassword ? (
                  <CustomVisibilityOffIcon
                    w="16px"
                    h="16px"
                    mr="10px"
                    cursor="pointer"
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <CustomVisibilityIcon
                    w="16px"
                    h="16px"
                    mr="10px"
                    cursor="pointer"
                    onClick={() => setShowPassword(true)}
                  />
                )}
                <Text>{password}</Text>
              </Flex>
            ),
          },
          {
            name: 'VTAP Account Type',
            value: readersCtx.readerState?.vtapApiKey
              ? 'External VTAP Account'
              : 'Passentry VTAP Account',
          },
          ...(readersCtx.readerState?.vtapApiKey
            ? [
                {
                  name: 'Vtap API Key',
                  value: (
                    <Flex alignItems="center">
                      {showApiKey ? (
                        <CustomVisibilityOffIcon
                          w="16px"
                          h="16px"
                          mr="10px"
                          cursor="pointer"
                          onClick={() => setShowApiKey(false)}
                        />
                      ) : (
                        <CustomVisibilityIcon
                          w="16px"
                          h="16px"
                          mr="10px"
                          cursor="pointer"
                          onClick={() => setShowApiKey(true)}
                        />
                      )}
                      <Text>{apiKey}</Text>
                    </Flex>
                  ),
                },
              ]
            : []),
        ]
      : []),
  ];

  return (
    <Box
      className="autofillForDarkBg"
      w="full"
      borderRadius="15px"
      overflow="hidden"
    >
      <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
        <Text
          fontSize="18px"
          textStyle="headingFamilyMedium"
          p="14px 14px 14px 24px"
          bg={modeTextBg}
          borderRadius="15px 15px 0 0"
        >
          Reader info
        </Text>
        <Divider borderColor={modeBorderColor} />

        {readersCtx.readerState.type === 'PassentryReader' && (
          <>
            <FormControl
              borderTopLeftRadius="15px"
              borderTopRightRadius="15px"
              bg={modeFormControlBg}
            >
              <Flex alignItems="center">
                <Hide below="md">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Reader status</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" pl="24px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel>Reader status</FormLabel>
                  </Show>
                  <Flex>
                    <ReadersRenderStatus
                      loggedIn={readersCtx.readerState.loggedIn}
                    />
                    {readersCtx.readerState.loggedIn && (
                      <Button
                        alt="Delete reader"
                        size="xs"
                        px="30px"
                        variant="link"
                        color="brand"
                        onClick={onLogoutReaderOpen}
                      >
                        Log out reader
                      </Button>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
            {readersCtx.readerState.loggedIn &&
              readersCtx.readerState.lastSeenAt && (
                <>
                  <FormControl
                    borderTopLeftRadius="15px"
                    borderTopRightRadius="15px"
                    bg={modeFormControlBg}
                  >
                    <Flex alignItems="center">
                      <Hide below="md">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>Last seen at</FormLabel>
                        </Box>
                      </Hide>
                      <Box p="14px" pl="24px" w="full" bg={modeLabelBg}>
                        <Show below="md">
                          <FormLabel>Last seen at</FormLabel>
                        </Show>
                        <Text>
                          {readersCtx.readerState.lastSeenAt
                            ? moment(
                                readersCtx.readerState.lastSeenAt
                              ).fromNow()
                            : ''}
                        </Text>
                      </Box>
                    </Flex>
                  </FormControl>
                  <Divider borderColor={modeBorderColor} />
                </>
              )}
          </>
        )}

        {readerFields.map((field, index) => {
          return (
            <ReaderField
              key={index}
              field={field}
              index={index}
              readerFields={readerFields}
            />
          );
        })}
        {readersCtx.readerState.type === 'PassentryReader' &&
          passentryReaderFields.map((field, index) => {
            return (
              <ReaderField
                key={index}
                field={field}
                index={index}
                readerFields={passentryReaderFields}
              />
            );
          })}
        {readersCtx.readerState.type === 'VtapReader' &&
          vtapAutomaticSetupEnabled &&
          vtapReaderFields.map((field, index) => {
            return (
              <ReaderField
                key={index}
                field={field}
                index={index}
                readerFields={vtapReaderFields}
              />
            );
          })}
      </Box>
      <LogoutReaderModal
        isOpen={isLogoutReaderOpen}
        onClose={onLogoutReaderClose}
        readerId={readersCtx.readerState.uuid}
      />
    </Box>
  );
};
export default ReadersFieldsView;
