import React from 'react';
import {
  Box,
  Heading,
  Grid,
  GridItem,
  VStack,
  Text,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import DonutChart from './DonutChart';
import BatchItem from './BatchItem';
import { useContext } from 'react';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import TimeRangeSelector from './TimeRangeSelector';
import NoDataBox from './NoDataBox';
import EmptyArtistAnimated from '../../../theme/illustrations-animated/EmptyArtistAnimated';
import NoDataBatchItem from './NoDataBatchItem';

export default function MessagingStats({ getData }) {
  const { dashboardState, updateSectionTimeRange, loadingStates } = useContext(
    ClientDashboardStatsContext
  );
  const { notificationsByStatus, recentBatches, upcomingBatches } =
    dashboardState.messagingStats;
  const timeRange = dashboardState.messagingStats.timeRange;

  const isLoading = loadingStates.messaging || loadingStates.global;

  const MESSAGING_STATUS_COLORS = {
    failed: '#8E9BA3',
    successful: '#ec685a',
    cancelled: '#2f3941',
    pending: '#fbafa7',
  };

  const formatDonutDataStatus = (notificationsByStatus) => {
    return Object.entries(notificationsByStatus).map(([status, value]) => ({
      id: status.charAt(0).toUpperCase() + status.slice(1),
      label: status.charAt(0).toUpperCase() + status.slice(1),
      value,
      color: MESSAGING_STATUS_COLORS[status],
    }));
  };

  const handleRangeChange = (newRange) => {
    updateSectionTimeRange('messaging', newRange);
    getData('messaging', newRange);
  };

  return (
    <Box
      bg="white"
      borderRadius="15px"
      px={8}
      py={5}
      pb={8}
      maxW="100%"
      w="100%"
    >
      <Flex
        justify="space-between"
        align={{ base: 'flex-start', md: 'center' }}
        mb={8}
        flexDir={{ base: 'column', md: 'row' }}
        gap={3}
        w="100%"
      >
        <Heading>Messaging</Heading>
        <TimeRangeSelector
          selectedRange={timeRange}
          onRangeChange={handleRangeChange}
        />
      </Flex>
      {dashboardState.messagingStats.totalNotifications === 0 && !isLoading ? (
        <NoDataBox
          icon={<EmptyArtistAnimated width="100px" height="92px" />}
          buttonText="Start Messaging"
          buttonLink="/messaging"
        />
      ) : (
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            xl: 'repeat(3, 1fr)',
          }}
          gap={8}
        >
          <GridItem colSpan={{ base: 1, md: 2, xl: 1 }}>
            <Skeleton isLoaded={!isLoading} fadeDuration={1}>
              <Box
                height="320px"
                width="100%"
                display="flex"
                alignItems="flex-end"
                justifyContent="center"
              >
                <DonutChart
                  data={formatDonutDataStatus(notificationsByStatus)}
                  label="Total Notifications"
                  small={false}
                  includeButton={true}
                  buttonText="Start Messaging"
                  buttonLink="/messaging"
                />
              </Box>
            </Skeleton>
          </GridItem>
          <GridItem>
            <Skeleton isLoaded={!isLoading} fadeDuration={1}>
              <VStack align="stretch" spacing={4}>
                <Text fontWeight="semibold" color="gray.600">
                  Upcoming Batches
                </Text>
                {upcomingBatches.map((batch) => (
                  <BatchItem key={batch.id} batch={batch} scheduled={true} />
                ))}
                {upcomingBatches.length === 0 && (
                  <NoDataBatchItem
                    text="No upcoming batches"
                    subtext="Create a new batch to get started"
                  />
                )}
              </VStack>
            </Skeleton>
          </GridItem>

          {/* Recent Batches */}
          <GridItem>
            <Skeleton isLoaded={!isLoading} fadeDuration={1}>
              <VStack align="stretch" spacing={4}>
                <Text fontWeight="semibold" color="gray.600">
                  Recent Batches
                </Text>
                {recentBatches.map((batch) => (
                  <BatchItem key={batch.id} batch={batch} scheduled={false} />
                ))}
                {recentBatches.length === 0 && (
                  <NoDataBatchItem
                    text="No recent batches"
                    subtext="Send messages to your audience to see activity here."
                    showButton={false}
                  />
                )}
              </VStack>
            </Skeleton>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
}
