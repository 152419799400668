import {
  Box,
  Flex,
  Hide,
  Show,
  FormLabel,
  useColorModeValue,
  FormErrorMessage,
  FormControl,
  Input,
  Divider,
  Text,
  Button,
  Heading,
} from '@chakra-ui/react';
import { CustomEditIcon } from '../../../../theme/icons/CustomEditIcon';
import { useContext, useState, useEffect, useCallback } from 'react';
import PassTemplateContext from '../../../../store/client/PassTemplateContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomColorPicker from '../CustomColorPicker';
import FaviconImage from '../../FaviconImage';
import LogoImage from '../../LogoImage';
import { CustomPlusIcon } from '../../../../theme/icons/CustomPlusIcon';
import FormFields from './FormFields';

const formErrorMessageStyles = {
  fontSize: '12px',
  paddingLeft: '22px',
  paddingTop: '10px',
  color: '#E53E3E',
};

const CustomDownloadFormFields = ({ formNameError }) => {
  const passTemplateCtx = useContext(PassTemplateContext);
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const [fieldErrors, setFieldErrors] = useState({
    inputErrors: [],
    slug: null,
    buttonText: null,
    formLogo: null,
    backgroundColorHex: null,
    componentFillColorHex: null,
    fontColorHex: null,
    buttonBackgroundColorHex: null,
    buttonTextColorHex: null,
  });

  const {
    slug,
    updateSlug,
    formFields,
    updateFormFields,
    componentFontColor,
    updateComponentFontColor,
    buttonTextColor,
    updateButtonTextColor,
    buttonColor,
    updateButtonColor,
    buttonText,
    updateButtonText,
    componentFillColor,
    updateComponentFillColor,
    backgroundColor,
    updateBackgroundColor,
    formLogo,
    updateFormLogo,
    updateFormLogoImage,
    updateFormFaviconImage,
    formFaviconLogo,
    updateFormFaviconLogo,
  } = passTemplateCtx;

  const [templateFields, setTemplateFields] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    passType,
    primaryOneId,
    headerOneId,
    secOneId,
    secTwoId,
    secThreeId,
    auxOneId,
    auxTwoId,
    auxThreeId,
    backOneId,
    backTwoId,
    backThreeId,
    backFourId,
    backFiveId,
    primaryOneLabel,
    headerOneLabel,
    secOneLabel,
    secTwoLabel,
    secThreeLabel,
    auxOneLabel,
    auxTwoLabel,
    auxThreeLabel,
    backOneLabel,
    backTwoLabel,
    backThreeLabel,
    backFourLabel,
    backFiveLabel,
  } = passTemplateCtx;

  useEffect(() => {
    const activeFields = [
      {
        name: 'Header field',
        id: headerOneId,
        label: headerOneLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Central field',
        id: primaryOneId,
        label: primaryOneLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Field 1',
        id: secOneId,
        label: secOneLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Field 2',
        id: secTwoId,
        label: secTwoLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Field 3',
        id: secThreeId,
        label: secThreeLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Field 4',
        id: auxOneId,
        label: auxOneLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Field 5',
        id: auxTwoId,
        label: auxTwoLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Field 6',
        id: auxThreeId,
        label: auxThreeLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Back field 1',
        id: backOneId,
        label: backOneLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Back field 2',
        id: backTwoId,
        label: backTwoLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Back field 3',
        id: backThreeId,
        label: backThreeLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Back field 4',
        id: backFourId,
        label: backFourLabel,
        required: false,
        extId: false,
      },
      {
        name: 'Back field 5',
        id: backFiveId,
        label: backFiveLabel,
        required: false,
        extId: false,
      },
    ].filter((field) => field.id);

    const fieldsToUpdate = activeFields.filter((field) => {
      const existingField = formFields?.find((f) => f.name === field.name);
      return existingField && existingField.id !== field.id;
    });

    setTemplateFields(
      activeFields.map((activeField, index) => {
        return {
          ...activeField,
          label: activeField.label || activeField.id,
          type: 'input',
          validation: { name: 'none', pattern: null, message: null },
        };
      })
    );

    if (!formFields) {
      updateFormFields(
        activeFields.map((activeField, index) => {
          return {
            ...activeField,
            label: activeField.label || activeField.id,
            type: activeField.type || 'input',
            validation: { name: 'none', pattern: null, message: null },
          };
        })
      );
    } else {
      updateFormFields(
        formFields?.map((field) => {
          const fieldToUpdate = fieldsToUpdate.find(
            (f) => f.name === field.name
          );

          if (fieldToUpdate) {
            return {
              ...field,
              id: fieldToUpdate.id,
              type: fieldToUpdate.type || field.type,
            };
          } else {
            return field;
          }
        })
      );
    }
  }, [
    passType,
    primaryOneId,
    headerOneId,
    secOneId,
    secTwoId,
    secThreeId,
    auxOneId,
    auxTwoId,
    auxThreeId,
    backOneId,
    backTwoId,
    backThreeId,
    backFourId,
    backFiveId,
  ]);

  useEffect(() => {
    const errors = { ...fieldErrors };

    if (!buttonText?.trim()) {
      errors.buttonText = 'Button text is required';
    } else {
      errors.buttonText = null;
    }

    setFieldErrors(errors);
  }, [slug, buttonText]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    updateFormFields((prevFields) => {
      const newFields = Array.from(prevFields);
      const [removed] = newFields.splice(source.index, 1);
      newFields.splice(destination.index, 0, removed);
      return newFields;
    });
  }, []);

  const hasFieldErrors = useCallback(() => {
    return formFields.some((field) => {
      if (!field.label?.trim()) return true;
      if (
        !field.options &&
        field?.validation?.name === 'custom' &&
        !field?.validation?.pattern?.trim()
      )
        return true;
      return false;
    });
  }, [formFields]);

  const handleAddField = (fieldType = 'input') => {
    const usedFieldIds = formFields.map((f) => f.id);
    const availableField = templateFields.find(
      (f) => !usedFieldIds.includes(f.id)
    );

    const isDropdown = fieldType === 'dropdown';

    const newField = {
      id:
        isDropdown && !formFields.some((f) => f.id == 'none')
          ? 'none'
          : availableField?.id,
      type: isDropdown ? 'dropdown' : 'input',
      label:
        isDropdown && !formFields.some((f) => f.id == 'none')
          ? 'New dropdown'
          : availableField?.label,
      name:
        isDropdown && !formFields.some((f) => f.id == 'none')
          ? 'New dropdown'
          : availableField?.name,
      required: false,
      extId: false,
      position: formFields.length,
      ...(!isDropdown && {
        validation: {
          name: 'none',
          pattern: null,
          message: null,
        },
      }),
      ...(isDropdown && {
        options: [
          { label: 'Option 1', value: 'option1' },
          { label: 'Option 2', value: 'option2' },
          { label: 'Option 3', value: 'option3' },
        ],
        placeholder: 'Select an option...',
      }),
    };

    updateFormFields([...formFields, newField]);
  };

  return (
    <Box className="autofillForDarkBg" borderRadius="15px" w="full">
      <Box
        className="primaryBoxShadow"
        bg={modeFormBoxBg}
        borderRadius="15px"
        w="full"
      >
        <Divider borderColor={modeBorderColor} />
        <FormControl
          isRequired
          isInvalid={formNameError}
          bg={modeFormControlBg}
          borderRadius="15px 15px 0 0"
        >
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Form name</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Form name</FormLabel>
              </Show>
              <Input
                value={slug}
                variant="filledForDarkBg"
                id="formName"
                type="text"
                placeholder="Type a unique identifier here..."
                onChange={(e) =>
                  updateSlug(
                    e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '-')
                  )
                }
                onBlur={() => {}}
                autoComplete="off"
                maxLength={30}
              />
              {formNameError && (
                <FormErrorMessage style={formErrorMessageStyles}>
                  {formNameError}
                </FormErrorMessage>
              )}
            </Box>
          </Flex>
        </FormControl>

        <Divider borderColor={modeBorderColor} />

        <FormControl isRequired isInvalid={fieldErrors.formLogo}>
          <Flex>
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0} pt="18px">
                  Header image
                </FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Header image</FormLabel>
              </Show>
              <LogoImage
                image={formLogo}
                setImage={(image) => updateFormLogo(image)}
                setLogo={(image) => updateFormLogoImage(image)}
                parent="form"
              />
              <FormErrorMessage style={formErrorMessageStyles}>
                {fieldErrors.formLogo}
              </FormErrorMessage>
            </Box>
          </Flex>
        </FormControl>

        <Divider borderColor={modeBorderColor} />

        <FormControl bg={modeFormControlBg}>
          <Flex>
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0} pt="18px">
                  Favicon
                </FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Favicon</FormLabel>
              </Show>
              <FaviconImage
                faviconImage={formFaviconLogo}
                setFaviconImage={(faviconImage) =>
                  updateFormFaviconImage(faviconImage)
                }
                setFaviconLogo={(faviconLogo) =>
                  updateFormFaviconLogo(faviconLogo)
                }
                parent="form"
              />
            </Box>
          </Flex>
        </FormControl>

        <Divider borderColor={modeBorderColor} />

        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Background colour</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Background colour</FormLabel>
              </Show>
              <CustomColorPicker
                color={backgroundColor}
                onChange={updateBackgroundColor}
                hexError={fieldErrors.backgroundColorHex}
              />
            </Box>
          </Flex>
        </FormControl>

        <Divider borderColor={modeBorderColor} />

        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Component fill colour</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Component fill colour</FormLabel>
              </Show>
              <CustomColorPicker
                color={componentFillColor}
                onChange={updateComponentFillColor}
                hexError={fieldErrors.componentFillColorHex}
              />
            </Box>
          </Flex>
        </FormControl>

        <Divider borderColor={modeBorderColor} />

        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Font colour</FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel>Font colour</FormLabel>
              </Show>
              <CustomColorPicker
                color={componentFontColor}
                onChange={updateComponentFontColor}
                hexError={fieldErrors.fontColorHex}
              />
            </Box>
          </Flex>
        </FormControl>

        <Divider borderColor={modeBorderColor} />

        {formFields && (
          <>
            {!isExpanded && (
              <FormControl bg={modeFormControlBg}>
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>Form Fields</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <Box pb={formFields.length > 0 ? '14px' : '0'}>
                        <FormLabel>Form Fields</FormLabel>
                      </Box>
                    </Show>
                    <Flex direction="column" gap={4}>
                      {formFields.length > 0 ? (
                        formFields
                          .sort((a, b) => a.position - b.position)
                          .map((field, index) => (
                            <Box key={index}>
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                gap={2}
                              >
                                <Box>
                                  <Flex alignItems={'center'} gap={2}>
                                    <FormLabel m={0}>{field.label}</FormLabel>
                                    {field.extId && (
                                      <Text
                                        fontSize={'10px'}
                                        color={modeHelperText}
                                        textStyle={'bodyFamilyMedium'}
                                      >
                                        Ext ID
                                      </Text>
                                    )}
                                  </Flex>
                                  <Text
                                    color={modeHelperText}
                                    textStyle="bodyFamilyRegular"
                                    fontSize="12px"
                                  >
                                    {field.id}
                                  </Text>
                                </Box>
                                <Box textAlign={'right'}>
                                  <Text
                                    color={modeHelperText}
                                    textStyle="bodyFamilyRegular"
                                    fontSize="12px"
                                  >
                                    {field?.validation?.name === 'phone' &&
                                      'Phone number validation'}
                                    {field?.validation?.name === 'email' &&
                                      'Email validation'}
                                    {field?.validation?.name === 'url' &&
                                      'URL validation'}
                                    {field?.validation?.name === 'custom' &&
                                      'Custom validation'}
                                    {field?.validation?.name === 'none' &&
                                      'No validation selected'}
                                    {field?.type === 'dropdown' &&
                                      `Dropdown field (${field.options.length} option${
                                        field.options.length === 1 ? '' : 's'
                                      })`}
                                  </Text>
                                  <Text
                                    color={modeHelperText}
                                    textStyle="bodyFamilyRegular"
                                    fontSize="12px"
                                  >
                                    {field.required
                                      ? 'This is a required field'
                                      : 'Not a required field'}
                                  </Text>
                                </Box>
                              </Flex>
                              {index !== formFields.length - 1 && (
                                <Divider borderColor={modeBorderColor} />
                              )}
                            </Box>
                          ))
                      ) : (
                        <Text
                          color={modeHelperText}
                          textStyle="bodyFamilyRegular"
                          fontSize="12px"
                        >
                          No form fields added
                        </Text>
                      )}
                    </Flex>
                    <Box
                      cursor="pointer"
                      onClick={() => setIsExpanded(true)}
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      mt={8}
                      gap={2}
                      width="100%"
                    >
                      <CustomEditIcon color="brand" boxSize="14px" />
                      <Heading
                        color="secondaryDark"
                        fontSize="14px"
                        top="1px"
                        pos="relative"
                      >
                        Edit
                      </Heading>
                    </Box>
                  </Box>
                </Flex>
              </FormControl>
            )}

            {isExpanded && (
              <>
                <FormControl bg={modeFormControlBg}>
                  <Flex width="100%" maxWidth="100%">
                    <Hide below="2xl">
                      <Box p="14px 0 0 24px" flexShrink={0} w="240px">
                        <FormLabel m={0}>Form Fields</FormLabel>
                        {formFields.length > 0 && (
                          <Text
                            color={modeHelperText}
                            textStyle="bodyFamilyRegular"
                            fontSize="12px"
                          >
                            Drag and drop to reorder.
                          </Text>
                        )}
                      </Box>
                    </Hide>
                    <Box w="full" maxWidth="100%" bg={modeLabelBg}>
                      <Show below="2xl">
                        <Box
                          p={'14px'}
                          pb={formFields.length > 0 ? '14px' : '0'}
                        >
                          <FormLabel>Form Fields</FormLabel>
                          {formFields.length > 0 && (
                            <Text
                              color={modeHelperText}
                              textStyle="bodyFamilyRegular"
                              fontSize="12px"
                            >
                              Drag and drop to reorder.
                            </Text>
                          )}
                        </Box>
                      </Show>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <FormFields
                          formFields={formFields}
                          setFormFields={updateFormFields}
                          templateFields={templateFields}
                        />
                      </DragDropContext>
                      <Flex
                        justify="flex-end"
                        alignItems="center"
                        bg={modeLabelBg}
                        gap={2}
                        flexWrap={'wrap'}
                        mt={8}
                        p={'14px'}
                      >
                        <Flex
                          gap={2}
                          flexWrap={'wrap'}
                          justifyContent={'flex-end'}
                        >
                          <Button
                            size="xs"
                            isDisabled={
                              formFields.filter((f) => f.id !== 'none')
                                .length >= templateFields.length
                            }
                            onClick={handleAddField}
                            variant="secondary"
                          >
                            <CustomPlusIcon />
                            <Text ml={1.5}>Input field</Text>
                          </Button>
                          <Button
                            size="xs"
                            onClick={() => handleAddField('dropdown')}
                            variant="secondary"
                            isDisabled={
                              formFields.some((f) => f.id == 'none') &&
                              formFields.filter((f) => f.id !== 'none')
                                .length >= templateFields.length
                            }
                          >
                            <CustomPlusIcon />
                            <Text ml={1.5}>Dropdown</Text>
                          </Button>
                        </Flex>
                        <Button
                          size="xs"
                          px="5px"
                          onClick={() => {
                            if (!fieldErrors.extId && !hasFieldErrors()) {
                              setIsExpanded(false);
                            }
                          }}
                          isDisabled={hasFieldErrors() || fieldErrors.extId}
                          maxW="85px"
                        >
                          <Text p="20px" fontSize="14px" top="1px">
                            Close
                          </Text>
                        </Button>
                      </Flex>
                      {fieldErrors.extId ||
                        (hasFieldErrors() && (
                          <Text
                            color="#E53E3E"
                            fontSize="12px"
                            top="1px"
                            pos="relative"
                            p="0 14px 14px 0"
                            justifySelf={'flex-end'}
                          >
                            Please complete all required fields
                          </Text>
                        ))}
                    </Box>
                  </Flex>
                </FormControl>
              </>
            )}
          </>
        )}

        <Divider borderColor={modeBorderColor} />

        <FormControl
          isRequired
          isInvalid={fieldErrors.buttonText}
          bg={modeFormControlBg}
          borderRadius={{
            base: '0 0 15px 15px',
          }}
        >
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0}>Button Style</FormLabel>
              </Box>
            </Hide>
            <Box
              p="14px"
              w="full"
              bg={modeLabelBg}
              borderRadius={{
                base: '0 0 15px 15px',
                '2xl': '0 0 15px 0',
              }}
            >
              <Show below="2xl">
                <FormLabel pb="14px">Button Style</FormLabel>
              </Show>
              <Flex gap={4} flexDirection={'column'}>
                <Box>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                    pb="2"
                  >
                    Button background colour
                  </Text>
                  <CustomColorPicker
                    color={buttonColor}
                    onChange={updateButtonColor}
                    hexError={fieldErrors.buttonBackgroundColorHex}
                  />
                </Box>
                <Box>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                    pb="2"
                  >
                    Button text colour
                  </Text>
                  <CustomColorPicker
                    color={buttonTextColor}
                    onChange={updateButtonTextColor}
                    hexError={fieldErrors.buttonTextColorHex}
                  />
                </Box>
                <Box>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                    pb="2"
                  >
                    Button text
                  </Text>
                  <Input
                    value={buttonText}
                    variant="filledForDarkBg"
                    type="text"
                    onBlur={() => {
                      if (buttonText === '') {
                        updateButtonText('Submit');
                      }
                    }}
                    placeholder="Enter your button text..."
                    autoComplete="off"
                    maxLength={30}
                    onChange={(e) => updateButtonText(e.target.value)}
                  />
                  <FormErrorMessage style={formErrorMessageStyles}>
                    {fieldErrors.buttonText}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </FormControl>
      </Box>
    </Box>
  );
};

export default CustomDownloadFormFields;
