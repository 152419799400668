import React, { useState } from 'react';
import CropModal from '../modals/CropModal';
import CustomDropzone from '../common/CustomDropzone';
import { useDisclosure } from '@chakra-ui/react';

function FaviconImage({
  faviconImage,
  setFaviconImage,
  setFaviconLogo,
  parent = 'download',
}) {
  const [droppedImage, setDroppedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
    setFaviconLogo({
      ...image,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
    });
  }

  function handleSaveDroppedImage(base64DroppedFile) {
    setFaviconImage(base64DroppedFile);
    setFaviconLogo({
      ...faviconImage,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    setFaviconLogo(image);
  }

  return (
    <>
      <CropModal
        key="favicon_image_crop_modal"
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        image={faviconImage}
        updateImage={updateImage}
        parent="favicon_image"
        title="Crop favicon image"
        droppedImage={droppedImage}
        saveDroppedImage={handleSaveDroppedImage}
      />
      <CustomDropzone
        key="favicon_image_drop_zone"
        isSquare
        image={faviconImage}
        isImageAdded={isImageAdded}
        parent={`${parent}_favicon_image`}
        explanation="Recommended size: 16x16 pixels. Recommended file type is png with transparent background."
        accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
        droppedImage={droppedImage}
        updateDroppedImage={handleUpdateDroppedImage}
      />
    </>
  );
}

export default FaviconImage;
