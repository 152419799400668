import { Box, Text, HStack, useColorModeValue, Circle } from '@chakra-ui/react';
import { CustomCalendarIcon } from '../../../theme/icons/CustomCalendarIcon';
import { CustomPassesMultiIcon } from '../../../theme/multicolor-icons/CustomPassesMultiIcon';
import { Link } from 'react-router-dom';
import moment from 'moment';

const BatchItem = ({ batch, scheduled }) => {
  const modeSquareBgDark = useColorModeValue('quinaryBackground', 'white');
  const modeBoxBgDark = useColorModeValue('gradientDark', 'gradientBrand');

  const time = scheduled ? batch.scheduled_at : batch.completed_at;
  const recipients = batch.total_recipients;

  const getStatusColor = (status) => {
    switch (status) {
      case 'validating':
      case 'processing':
      case 'processed':
      case 'sending':
        return 'orange.500';
      case 'scheduled':
        return 'blue.500';
      case 'completed':
      case 'successful':
        return 'green.500';
      case 'failed':
      case 'partially_cancelled':
      case 'cancelled':
      case 'cancellation_failed':
      case 'completed_with_errors':
        return 'red.500';
      default:
        return 'gray.500';
    }
  };

  return (
    <Link to="/messaging">
      <Box
        py={4}
        px={6}
        bg={modeBoxBgDark}
        borderRadius="10px"
        color="white"
        transition="transform 0.2s ease-in-out"
        _hover={{ transform: 'scale(1.02)' }}
      >
        <HStack justify="space-between">
          <Box>
            <Text fontWeight="600">{batch.name}</Text>
            <HStack justify="flex-start" gap={1}>
              <CustomCalendarIcon />
              <Text fontSize="sm">
                {scheduled ? 'Scheduled: ' : 'Sent: '}{' '}
                {moment(time).format('MM/DD/YYYY hh:mm A')}
              </Text>
            </HStack>
            <HStack justify="flex-start" gap={1}>
              <CustomPassesMultiIcon brand={true} boxSize="18px" />
              <Text fontSize="sm">Recipients: {recipients}</Text>
            </HStack>
          </Box>
          {scheduled === false && (
            <Circle size="20px" bg={getStatusColor(batch.status)} />
          )}
        </HStack>
      </Box>
    </Link>
  );
};

export default BatchItem;
