import {
  Box,
  HStack,
  VStack,
  Heading,
  Text,
  Button,
  Stack,
  useMediaQuery,
  Skeleton,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import EmptyDefaultSvgAnimated from '../../../theme/illustrations-animated/EmptyDefaultSvgAnimated';
import EmptyDefaultDarkSvgAnimated from '../../../theme/illustrations-animated/EmptyDefaultDarkSvgAnimated';
import NoDataBox from './NoDataBox';
const DashboardNoPassTemplates = ({ isLoading }) => {
  const [isSmallerThan770] = useMediaQuery('(max-width: 770px)');
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#EFEFEF', '#515d66');

  return (
    <Box
      w="full"
      bg="linear-gradient(81.62deg, #FF8477 2.25%, #EC6759 79.87%)"
      borderRadius="15px"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      p={7}
      color="white"
    >
      <VStack align="start" gap={'18px'} mb={isSmallerThan770 ? 0 : 3}>
        <Heading size="xl">
          <Skeleton
            isLoaded={!isLoading}
            startColor={modeSkeletonStartColor}
            endColor={modeSkeletonEndColor}
          >
            Pass Templates
          </Skeleton>
        </Heading>
        <Flex justify="center" w="full">
          <NoDataBox
            icon={<EmptyDefaultSvgAnimated width="102px" height="92px" />}
            buttonText="Create Template"
            buttonLink="/pass-templates/create"
            opacity={1}
            buttonType="white"
            title="You haven't created a Pass Template"
            description="Before creating passes, you'll need to build a pass template."
          />
        </Flex>
      </VStack>
    </Box>
  );
};

export default DashboardNoPassTemplates;
