import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const ReaderIconAnimated = ({ width = '130px', height = '106px' }) => {
  return (
    <Icon viewBox="0 0 130 106" width={width} height={height}>
      <circle cx="65.0002" cy="53" r="53" fill="#D1D1D1" />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99093 64.1997C8.40526 64.1997 8.74115 63.8639 8.74115 63.4497C8.74115 63.0355 8.40526 62.6997 7.99093 62.6997H5.31377V60.0234C5.31377 59.6091 4.97788 59.2734 4.56354 59.2734C4.14921 59.2734 3.81332 59.6091 3.81332 60.0234L3.81332 62.6997L1.13619 62.6997C0.72185 62.6997 0.385963 63.0355 0.385963 63.4497C0.385963 63.8639 0.72185 64.1997 1.13619 64.1997L3.81332 64.1997L3.81332 66.8761C3.81332 67.2903 4.14921 67.6261 4.56354 67.6261C4.97788 67.6261 5.31377 67.2903 5.31377 66.8761L5.31377 64.1997H7.99093Z"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.166 42.1167C126.166 42.414 126.407 42.655 126.704 42.655C127.001 42.655 127.242 42.414 127.242 42.1167L127.242 40.196L129.164 40.196C129.461 40.196 129.702 39.955 129.702 39.6577C129.702 39.3605 129.461 39.1195 129.164 39.1195L127.242 39.1195L127.242 37.1986C127.242 36.9013 127.001 36.6603 126.704 36.6603C126.407 36.6603 126.166 36.9013 126.166 37.1986V39.1195L124.244 39.1195C123.947 39.1195 123.706 39.3605 123.706 39.6577C123.706 39.955 123.947 40.196 124.244 40.196L126.166 40.196L126.166 42.1167Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-20, 0],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      {/* <motion.rect
        width="7.50637"
        height="1.39794"
        rx="0.698968"
        transform="matrix(0.707212 -0.707001 0.707212 0.707001 105.413 23.1943)"
        fill="#3B4851"
        fillOpacity="0.6"
      /> */}
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103 20C103.552 20 104 19.5523 104 19C104 18.4477 103.552 18 103 18C102.447 18 102 18.4477 102 19C102 19.5523 102.447 20 103 20ZM103 22C104.657 22 106 20.6569 106 19C106 17.3431 104.657 16 103 16C101.343 16 99.9998 17.3431 99.9998 19C99.9998 20.6569 101.343 22 103 22Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="7.9164"
        height="1.68802"
        rx="0.844009"
        //transform="matrix(-0.707212 -0.707001 0.707212 -0.707001 19.2876 12.6963)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [20, 10],
          y: [20, 10],
          scale: [0.5, 1],
          rotate: [45, 45],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.89186 31.9374C10.4996 31.9374 10.9922 31.4449 10.9922 30.8374C10.9922 30.2299 10.4996 29.7374 9.89186 29.7374C9.28416 29.7374 8.79153 30.2299 8.79153 30.8374C8.79153 31.4449 9.28416 31.9374 9.89186 31.9374ZM9.89186 33.3374C11.273 33.3374 12.3926 32.2181 12.3926 30.8374C12.3926 29.4567 11.273 28.3374 9.89186 28.3374C8.51073 28.3374 7.39111 29.4567 7.39111 30.8374C7.39111 32.2181 8.51073 33.3374 9.89186 33.3374Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          y: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2885 84.825C22.8409 84.825 23.2888 84.3772 23.2888 83.825C23.2888 83.2727 22.8409 82.825 22.2885 82.825C21.736 82.825 21.2882 83.2727 21.2882 83.825C21.2882 84.3772 21.736 84.825 22.2885 84.825ZM22.2885 86.825C23.9458 86.825 25.2894 85.4818 25.2894 83.825C25.2894 82.1681 23.9458 80.825 22.2885 80.825C20.6311 80.825 19.2876 82.1681 19.2876 83.825C19.2876 85.4818 20.6311 86.825 22.2885 86.825Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.926 86.4875C107.755 86.4875 108.427 85.816 108.427 84.9875C108.427 84.1591 107.755 83.4875 106.926 83.4875C106.097 83.4875 105.426 84.1591 105.426 84.9875C105.426 85.816 106.097 86.4875 106.926 86.4875ZM106.926 88.4875C108.86 88.4875 110.427 86.9205 110.427 84.9875C110.427 83.0546 108.86 81.4875 106.926 81.4875C104.993 81.4875 103.425 83.0546 103.425 84.9875C103.425 86.9205 104.993 88.4875 106.926 88.4875Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="75.1631"
        y="19.6387"
        width="10.2877"
        height="1.87049"
        rx="0.935246"
        transform="rotate(-45 75.1631 19.6387)"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="65.9048"
        y="20.9612"
        width="10.2877"
        height="1.87049"
        rx="0.935245"
        transform="rotate(-135 65.9048 20.9612)"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="70.2441"
        y="17.7612"
        width="10.6618"
        height="1.77697"
        rx="0.888483"
        transform="rotate(-90 70.2441 17.7612)"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M96.0132 79.7781C96.0132 82.5395 93.7746 84.7781 91.0132 84.7781L51.9882 84.7781C49.2268 84.7781 46.9882 82.5395 46.9882 79.7781L46.9882 30.88C46.9882 28.1186 49.2268 25.88 51.9882 25.88L91.0132 25.88C93.7746 25.88 96.0132 28.1186 96.0132 30.88L96.0132 79.7781Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M52.6545 35.4485C52.6545 34.3439 53.55 33.4485 54.6545 33.4485H88.347C89.4515 33.4485 90.347 34.3439 90.347 35.4485V44.6985C90.347 45.8031 89.4516 46.6985 88.347 46.6985H54.6545C53.55 46.6985 52.6545 45.8031 52.6545 44.6985V35.4485Z"
        fill="#899197"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.6011 62.1732C70.9011 61.9732 72.2011 61.9732 73.5011 62.1732C74.8011 62.3732 76.0011 62.6732 77.1011 63.2732C77.9011 63.6732 78.6011 64.1732 79.3011 64.7732C80.2011 65.5732 80.2011 66.9732 79.4011 67.8732C78.6011 68.7732 77.2011 68.7732 76.3011 67.9732C76.1011 67.7732 75.7011 67.4732 75.2011 67.2732C74.6011 66.9732 73.8011 66.7732 72.9011 66.5732C72.0011 66.4732 71.1011 66.4732 70.2011 66.5732C69.3011 66.6732 68.5011 66.9732 67.9011 67.2732C67.4011 67.4732 67.1011 67.7732 66.8011 67.9732C65.9011 68.7732 64.6011 68.7732 63.7011 67.8732C62.9011 66.9732 62.9011 65.6732 63.8011 64.7732C64.4011 64.1732 65.2011 63.6732 66.0011 63.2732C67.1011 62.6732 68.3011 62.3732 69.6011 62.1732Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M71.5006 75.8731C73.2127 75.8731 74.6006 74.4852 74.6006 72.7731C74.6006 71.061 73.2127 69.6731 71.5006 69.6731C69.7886 69.6731 68.4006 71.061 68.4006 72.7731C68.4006 74.4852 69.7886 75.8731 71.5006 75.8731Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0009 59.1733C69.797 59.0737 70.6427 59.0237 71.5009 59.0232C72.359 59.0237 73.2047 59.0735 74.0009 59.1731C75.7008 59.3733 77.2008 59.8733 78.5009 60.4731C79.2008 60.7732 79.8009 61.1731 80.3009 61.5732C81.2008 62.2732 82.601 62.1731 83.3009 61.2732C84.0009 60.3733 83.9008 58.9731 83.0009 58.2732C82.6566 58.0149 82.2939 57.7568 81.9206 57.5066C81.4264 57.1755 80.9137 56.8582 80.4008 56.5732C78.7008 55.6731 76.7008 55.1731 74.601 54.8733C73.5797 54.7273 72.5348 54.6523 71.5009 54.6484C70.467 54.6523 69.422 54.7273 68.4008 54.8733C66.3007 55.1733 64.3007 55.6733 62.6007 56.5732C61.998 56.9082 61.3949 57.2881 60.8219 57.6826C60.5397 57.8772 60.2648 58.0752 60.0009 58.2732C59.1007 58.9734 59.0009 60.3733 59.7008 61.2732C60.4008 62.1733 61.8007 62.2732 62.7008 61.5732C63.2008 61.1733 63.8007 60.7732 64.5009 60.4734C65.8007 59.8733 67.3007 59.3733 69.0009 59.1733Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M33.9875 45.7593C33.9875 44.6547 34.883 43.7593 35.9875 43.7593H54.273C55.3776 43.7593 56.273 44.6547 56.273 45.7593V84.825C56.273 85.9295 55.3776 86.825 54.273 86.825H35.9875C34.883 86.825 33.9875 85.9295 33.9875 84.825V45.7593Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M38.6013 82.5974C38.6013 81.9827 39.0996 81.4844 39.7144 81.4844H50.5461C51.1608 81.4844 51.6591 81.9827 51.6591 82.5974C51.6591 83.2121 51.1608 83.7104 50.5461 83.7104H39.7144C39.0996 83.7104 38.6013 83.2121 38.6013 82.5974Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
    </Icon>
  );
};

export default ReaderIconAnimated;
