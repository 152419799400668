import { Button, ButtonGroup, Box } from '@chakra-ui/react';

const TimeRangeSelector = ({ selectedRange, onRangeChange }) => {
  const ranges = [
    { label: '7D', value: '7days' },
    { label: '1M', value: '1month' },
    { label: '6M', value: '6months' },
    { label: 'All', value: 'all' },
  ];

  return (
    <ButtonGroup size="sm" isAttached variant="outline">
      {ranges.map((range) => (
        <Button
          key={range.value}
          onClick={() => onRangeChange(range.value)}
          colorScheme={selectedRange === range.value ? 'brandDark' : 'gray'}
          bg={selectedRange === range.value ? 'brandDark' : 'white'}
          borderColor={selectedRange === range.value ? 'brandDark' : 'gray.200'}
          color={selectedRange === range.value ? 'white' : 'gray.600'}
          _hover={{
            bg: selectedRange === range.value ? 'brandDark' : 'gray.100',
          }}
        >
          {range.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default TimeRangeSelector;
