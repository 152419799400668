import { useToast } from '@chakra-ui/react';
import { useContext } from 'react';
import { createContext, useState } from 'react';
import CustomToast from '../../common/CustomToast';
import { HttpContext } from '../../context/HttpContext';
import { getErrorResponsePayload } from '../../utils/ajax';

const ReadersContext = createContext({
  readerState: {
    name: '',
    uuid: '',
    type: '',
    loggedIn: null,
    lastSeenAt: null,
    scanEnabled: null,
    redeemEnabled: null,
    addPointsEnabled: null,
    qrEnabled: null,
    mifareClassicNfcEnabled: null,
    ndefTagScanningEnabled: null,
    vtapAutomaticSetup: false,
    vtapAccountType: '',
    vtapApiKey: '',
    vtapLocation: '',
    vtapSSID: '',
    vtapPassword: '',
    setupStatus: '',
  },
  // updateEnabledState: () => {},
  updateAllData: () => {},
});

export const ReadersContextProvider = ({ children }) => {
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();

  const [readerState, setReaderState] = useState({
    name: '',
    uuid: '',
    type: '',
    loggedIn: null,
    lastSeenAt: null,
    scanEnabled: true,
    redeemEnabled: false,
    addPointsEnabled: false,
    qrEnabled: false,
    mifareClassicNfcEnabled: false,
    ndefTagScanningEnabled: false,
    password: '',
    vtapAutomaticSetup: false,
    vtapAccountType: '',
    vtapLocation: '',
    vtapSSID: '',
    vtapPassword: '',
    vtapApiKey: '',
    setupStatus: '',
  });

  const updateAllReaderDataHandler = (data) => {
    const {
      attributes: {
        name,
        loggedIn,
        lastSeenAt,
        readerType,
        qrEnabled,
        mifareClassicNfcEnabled,
        ndefTagScanningEnabled,
        scanEnabled,
        redeemEnabled,
        addPointsEnabled,
        vtapReaderSetup,
        wifiSsid,
        wifiPassword,
        location,
        vtapApiKey,
        vtapAutomaticSetup,
      },
      id,
    } = data;

    setReaderState({
      name,
      uuid: id,
      type: readerType,
      loggedIn,
      lastSeenAt,
      qrEnabled,
      scanEnabled,
      redeemEnabled,
      addPointsEnabled,
      mifareClassicNfcEnabled,
      ndefTagScanningEnabled,
      vtapLocation: location,
      vtapSSID: wifiSsid,
      vtapPassword: wifiPassword,
      vtapApiKey,
      vtapAutomaticSetup,
      setupStatus: vtapReaderSetup,
    });
  };

  // async function handleEnabledStatus(param) {
  //   let submittedData = {
  //     enabled: param,
  //   };

  //   try {
  //     const { data } = await authAxios.patch(
  //       `readers/${readerState.uuid}`,
  //       submittedData
  //     );

  //     setReaderState({
  //       ...data.data,
  //       udi: data.data.userDefinedIdentifier,
  //     });
  //   } catch (error) {
  //     appsignal.sendError(error);
  //     console.log(error);
  //     const { message, code } = getErrorResponsePayload(error);
  //     code !== 401 &&
  //       toast({
  //         render: (props) => (
  //           <CustomToast
  //             status="error"
  //             title={message ? message : `Something went wrong`}
  //             description={!message && 'Please try again later'}
  //             onClose={props.onClose}
  //           />
  //         ),
  //       });
  //   }
  // }

  // const updateEnabledStateHandler = async (param) => {
  //   await handleEnabledStatus(param);
  // };

  const context = {
    readerState,
    // updateEnabledState: updateEnabledStateHandler,
    updateAllData: updateAllReaderDataHandler,
  };

  return (
    <ReadersContext.Provider value={context}>
      {children}
    </ReadersContext.Provider>
  );
};

export default ReadersContext;
