import appsignal from '../../appsignal';
import {
  Container,
  Heading,
  Divider,
  Flex,
  Spacer,
  useMediaQuery,
  Grid,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import { useContext, useEffect, useRef } from 'react';
import { MeContext } from '../../context/MeContext';
import DashboardNoPassTemplates from '../../components/client/dashboard/DashboardNoPassTemplates';
import { HttpContext } from '../../context/HttpContext';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';
import KeyStats from '../../components/client/dashboard/KeyStats';
import PassesStats from '../../components/client/dashboard/PassesStats';
import ScansStats from '../../components/client/dashboard/ScansStats';
import MessagingStats from '../../components/client/dashboard/MessagingStats';
import CustomToast from '../../common/CustomToast';
import { ClientDashboardStatsContext } from '../../store/client/ClientDashboardStatsContext';
import { getErrorResponsePayload } from '../../utils/ajax';
import { useToast } from '@chakra-ui/react';
import moment from 'moment';

function ClientDashboardPage() {
  const meCtx = useContext(MeContext);
  const authAxios = useContext(HttpContext).authAxios;
  const { shouldRender, components } = useOrganizationStatus();
  const {
    updateDashboardData,
    dashboardState,
    updateSectionTimeRange,
    loadingStates,
    setLoading,
  } = useContext(ClientDashboardStatsContext);
  const toast = useToast();
  const templatesCount = dashboardState.totalPassTemplates;
  const { filterEntity } = meCtx.state;

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Dashboard',
      linkUrl: null,
    },
  ];

  const prepareTimeQuery = (section, timeRange) => {
    if (!section && !timeRange) return '';

    if (timeRange === 'all' && section) {
      return `&${section}From=all`;
    }

    let fromDate;
    if (timeRange === '7days') {
      fromDate = moment().subtract(7, 'days');
    } else if (timeRange === '1month') {
      fromDate = moment().subtract(1, 'months');
    } else {
      fromDate = moment().subtract(6, 'months').startOf('month');
    }
    return `&${section}From=${encodeURIComponent(fromDate)}`;
  };

  const getData = async (section = null, timeRange = null) => {
    if (!shouldRender) {
      console.log(
        'Setup not confirmed or organisation is not active, skipping data fetch'
      );
      return;
    }

    if (section) {
      setLoading(section, true);
    } else {
      setLoading('global', true);
    }
    const timeQuery = prepareTimeQuery(section, timeRange);

    try {
      let response;
      const baseUrl = filterEntity?.uuid
        ? `api/v1/entities/${filterEntity.uuid}/dashboard`
        : `api/v1/dashboard`;

      const url = `${baseUrl}?${timeQuery}`;

      response = await Promise.all([authAxios.get(`${url}`)]);

      const data = response[0].data;
      updateDashboardData(data);

      if (section) {
        setLoading(section, false);
      } else {
        setLoading('global', false);
      }
    } catch (error) {
      if (section) {
        setLoading(section, false);
      } else {
        setLoading('global', false);
      }
      console.log(error);
      const { message, code } = getErrorResponsePayload(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  useEffect(() => {
    updateSectionTimeRange('pass', 'all');
    updateSectionTimeRange('scan', 'all');
    updateSectionTimeRange('messaging', 'all');
    getData();
  }, [authAxios, shouldRender, filterEntity]);

  return (
    <Container pb={6} overflow="visible">
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <Flex
        alignItems="center"
        mt="15px"
        py="9px"
        minH="54px"
        flexWrap="wrap"
        gap="10px"
      >
        <Heading>Dashboard</Heading>
        <Spacer />
      </Flex>
      <Divider borderColor="primaryBackground" mb={6} />

      {components[0]}

      <KeyStats isLoading={loadingStates.global} />
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          '2xl':
            templatesCount > 0 || loadingStates.global
              ? 'repeat(1, 1fr)'
              : 'repeat(2, 1fr)',
        }}
        gap={8}
      >
        {templatesCount === 0 && !loadingStates.global && (
          <DashboardNoPassTemplates />
        )}
        <PassesStats getData={getData} />
        <ScansStats getData={getData} />
        <MessagingStats getData={getData} />
      </Grid>
    </Container>
  );
}

export default ClientDashboardPage;
